<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent max-width="800px">
			<v-card>
				<v-card-title>
					<span class="text-h5">Tarefas</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12" md="12">
								<v-text-field
									label="Título"
									v-model="form.TARTIT"
									class="required"
								></v-text-field>
							</v-col>

							<v-col cols="12" md="12">
								<v-textarea
									label="Descrição"
									rows="3"
									v-model="form.TARDES"
								></v-textarea>
							</v-col>

							<v-col cols="12" md="12" v-show="false">
								<v-select
									:items="[
										{
											value: 'A',
											text: 'Aberta',
										},
										{
											value: 'F',
											text: 'Fechada',
										},
									]"
									v-model="form.TARSIT"
									label="Situação"
									class="required"
								></v-select>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12">
								<v-data-table
									:headers="[
										{ text: '#', value: 'TARSEQ', width: '5%' },
										{ text: 'Título', value: 'TARTIT', width: '30%' },
										{ text: 'Descrição', value: 'TARDES', width: '50%' },
										{ text: '', value: 'action',  width: '20%' }
									]"
									:items="tasks"
									:items-per-page="5"
									class="elevation-1"
									item-key="TARSEQ"
									v-if="dialog"
									no-data-text="Nenhuma tarefa!"
								>
									<template v-slot:item.action="{ item }">
										<v-btn
											icon
											small
											@click="handleClickEditTask(item)"
										>
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
										<v-btn
											icon
											small
											@click="handleDeleteModalTask(item.TARSEQ)"
										>
											<v-icon>mdi-trash-can-outline</v-icon>
										</v-btn>
									</template>
								</v-data-table>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="red darken-1"
						text
						@click="handleDeleteModalTask(form.TARSEQ)"
						v-if="form.update"
					>
						Excluir
					</v-btn>
					<v-btn
						color="blue darken-1"
						text
						@click="handleCloseModalTask"
					>
						Fechar
					</v-btn>
					<v-btn color="blue darken-1" text @click="handleSaveFormModalTask">
						Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	props: {
		dialog: Boolean,
		form: Object,
		tasks: Array,
		handleCloseModalTask: Function,
		handleSaveFormModalTask: Function,
		handleDeleteModalTask: Function,
		handleClickEditTask: Function
	},
};
</script>
