import Rest from '../../app/Base/Rest';

/**
 * @typedef {IndicatorRegistrationService}
 */
export default class IndicatorRegistrationService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/indicatorregistration';
}
