<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ module.CODMOD ? 'Editar módulo' : 'Cadastrar módulo' }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="7">
								<v-text-field v-model="module.NAME" label="Nome" class="required"></v-text-field>
							</v-col>
							<v-col cols="12" md="2">
								<v-select
									class="required"
									v-model="module.STATUS"
									:items="moduleStatus"
									item-value="COD"
									item-text="DESC"
									label="Status"
									single-line
								></v-select>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn color="primary" depressed tile class="mr-4" @click="save" :disabled="loading">Salvar</v-btn>
								<v-btn color="primary" text class="mr-4" @click="$router.push('/module/list')" :disabled="loading">Voltar</v-btn>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ModuleService from "../../app/Services/ModuleService";

const moduleService = ModuleService.build();

export default {
	name: "ModuleForm",
	data: () => ({
		module: {
			codmod: 0,
			name: '',
			status: 1
		},
		moduleStatus: [{ COD: 1, DESC: 'Ativo' }, { COD: 2, DESC: 'Inativo' }],
		loading: false,
		loadingModule: false
	}),
	components: {},
	computed: {},
	mounted() {
		const { id } = this.$route.params;

		if (id) {
			this.module.CODMOD = id;
			this.getModules();
		}
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		save() {
			if (!this.module.NAME) {
				this.toast(
					"warning",
					"Informe o nome do módulo para continuar!"
				);
				return;
			}

			this.loading = true;

			const rest = this.module.CODMOD > 0 ? moduleService.update({ id: this.module.CODMOD, ...this.module }) : moduleService.create(this.module);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast('success', this.module.CODMOD ? 'Módulo atualizado com sucesso!' : 'Módulo adicionado com sucesso!');

				this.loading = false;

				this.$router.push('/module/list');
			});
		},
		getModules() {
			this.loading = true;

			return moduleService.read({ id: this.module.CODMOD }).then((response) => {
				this.module = response;
				this.loading = false;
			});
		}
	}
};
</script>
<style>
.alignColor {
	vertical-align: bottom;
}
</style>
