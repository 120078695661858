<template>
	<div class="noPrint">
		<v-navigation-drawer
			app
			left
			:permanent="permanent"
			:value="value"
			@input="$emit('input', $event)"
			:style="{
				'z-index': 10,
				'margin-top': isMobile ? '0rem' : '6rem',
				height: isMobile ? '100vh' : 'calc(100vh - 6rem)',
			}"
		>
			<v-list dense>
				<v-list-item
					v-for="item in items"
					:key="item.DESC"
					:to="item.URL"
					:tooltip="item.DESC"
				>
					<v-list-item-action>
						<v-icon>{{ item.ICONE }}</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ item.DESC }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
export default {
	name: "Drawer",
	components: {},
	props: {
		items: { type: Array, required: true },
		value: { type: Boolean, default: false },
		permanent: { type: Boolean, default: false },
		isMobile: { type: Boolean, default: false },
	},
	mounted() {},
	data() {
		return {
			mini: true,
			helpVisible: false,
			roles: [],
		};
	},
	methods: {
		openHelp() {
			this.$router.push({ name: "MovementTypes" });
		},
		actionCloseDialog(value) {
			this.helpVisible = value;
		},
	},
};
</script>

<style>
.helpMenu {
	bottom: 0;
	position: absolute;
	margin-bottom: 5px;
	width: 100%;
}
</style>
