<template>
	<v-app id="inspire">
		<SnackBar ref="SnackBar" />
		<LoadingBar ref="LoadingBar" />

		<Navigation v-if="loading"></Navigation>
	</v-app>
</template>

<script>
import Navigation from "./components/navigation/AppNavigation";
import SnackBar from "./components/misc/SnackBar";
import LoadingBar from "./components/misc/Loading";

export default {
	name: "DashView",
	components: {
		Navigation: Navigation,
		SnackBar: SnackBar,
		LoadingBar: LoadingBar
	},
	data() {
		return {
			loading: false
		};
	},
	mounted() {
		const userData = JSON.parse(localStorage.getItem("user"));

		this.$root.$SnackBar = this.$refs.SnackBar;
		this.$root.$LoadingBar = this.$refs.LoadingBar;
		this.loading = true;

		if (userData && "user" in userData) {
			this.$root.$User = userData["user"];
		}

		if (userData && "candidate" in userData) {
			this.$root.$Candidate = userData["candidate"];
		}
	}
};
</script>
