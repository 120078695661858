<template>
	<div style="background-color: white; height: 100%">
    <v-container class="fill-height" grid-list-md>
      <v-row justify="center">
        <v-col cols="12" md="6" sm="12">
          <v-card class="pa-5" flat>
            <v-btn @click="goTo('/login')" text>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Início
            </v-btn>
            <v-card-text>
              <v-col cols="12">
                <v-row xs10>
                  <p>Para recuperar sua senha, é necesário informar o seu e-mail válido.</p>
                </v-row>
              </v-col>
              <v-form ref="form">
                <v-col cols="12">
                  <v-row class="pa-2 text-left">
                    <v-text-field ref="email" type="email" v-model="email" label="E-mail" required clearable :rules="[validEmailRule]"></v-text-field>
                  </v-row>
                </v-col>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                @click="send"
                block
                color="#3F48CC"
                dark
                tile
                depressed
              >Enviar</v-btn>
            </v-card-actions>
          </v-card>
      </v-col>
    </v-row>
		</v-container>
  </div>
</template>

<script>
import PasswordResetService from '../app/Services/PasswordResetService';

const passwordResetService = PasswordResetService.build();

export default {
	name: "PasswordReset",
	computed: {
		validEmailRule() {
			return this.isEmailValid() || "Informe um e-mail válido";
		}
	},
	data() {
		return {
			valid: false,
			e1: true,
			email: "",
			loading: false,
			userRules: [v => !!v || "Obrigatório informar o e-mail"],
			snackbar: {
				message: "",
				visible: false,
				color: "success",
				timeout: 6000,
				x: null,
				y: "bottom",
				multiLine: false
			}
		};
	},
	mounted() {
		this.$nextTick(() => this.$refs.email.$refs.input.focus());
	},
	methods: {
		isEmailValid() {
			/*eslint no-useless-escape: 0 */
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(this.email).toLowerCase());
		},
		goTo(url) {
			this.$router.push(url);
		},
		send() {

			if (!this.email) {
				this.$toasted.info('É necessário informar o e-mail para continuar', { icon: 'mdi-information' });
				return;
			}

			this.loading = true;

			return passwordResetService.validate(this.email).then(response => {
				if (response.status == 'user_does_not_exists') {
					this.$toasted.error('E-mail inválido', { icon: 'mdi-account-remove' });
					return;
				}

				if (response.status == 'token_already_exists') {
					this.$toasted.info('Já existe um token de redefinição ativo para seu e-mail. Cheque sua caixa de entrada!', { icon: 'mdi-information', duration: 5000 });
					return;
				}

				this.$toasted.success('E-mail de redefinição de senha enviado com sucesso!', { icon: 'mdi-check' });

				this.$router.push('/login');
			}).finally(() => {
				this.loading = false;
			});
		},
		loginFocus() {
			this.$refs.email.focus();
		}
	}
};
</script>

<style>
.mt50 {
	margin-top: 50px;
}
.mb50 {
	margin-bottom: 50px;
}
#btnLogin {
	margin-top: 25px;
}
.alignCenter {
	margin: 0 auto;
}
</style>
