import Rest from "../Base/Rest";

/**
 * @typedef {OrganizationChartLevelAreaService}
 */
export default class OrganizationChartLevelAreaService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = "/organization-chart-area";
}
