<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="mt-2 pb-8 mb-5" flat>
					<v-card-title>
						<v-subheader>
							<span class="title font-weight-normal"
								>Filtrar objetivos</span
							>
						</v-subheader>
						<v-spacer></v-spacer>
						<v-btn
							class="white--text mr-2"
							depressed
							color="#34bfa3"
							@click="$router.push('/objective/new')"
							>+ Novo</v-btn
						>
						<v-btn
							class="white--text"
							depressed
							color="info"
							@click="filter()"
							>Buscar</v-btn
						>
						<search-filter-default
							v-model="filters"
						/>
					</v-card-title>
				</v-card>
				<v-card flat>
					<v-data-table
						:headers="headers"
						:items="items"
						:items-per-page="pagination.itemsPerPage"
						v-bind:loading="loading"
						loading-text="Aguarde...carregando"
						:hide-default-footer="true"
						:footer-props="{
							'items-per-page-text': 'Itens por página',
							'items-per-page-all-text': 'Todos'
						}"
					>
						<template v-slot:body="{ items }">
							<tr v-for="item in items" :key="item.CODOBJ">
								<td>
									<v-btn @click="edit(item.CODOBJ)" icon>
										<v-icon style="color: orange"
											>mdi-pencil</v-icon
										>
									</v-btn>
								</td>
								<td>{{ item.CODOBJ }}</td>
								<td>{{ item.strategicplanning.DESABR }}</td>
								<td>{{ item.company.DESABR }}</td>
								<td>{{ item.unit.DESABR }}</td>
								<td>
									{{
										item.perspective
											? item.perspective.DESPER
											: ""
									}}
								</td>
								<td>{{ item.DESOBJ }}</td>
								<td>
									<v-btn @click="remove(item.CODOBJ)" icon>
										<v-icon style="color: red"
											>mdi-delete</v-icon
										>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>

					<pagination
						v-model="pagination"
						@change="loadData"
						:loading="loading"
					/>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ObjectiveService from "../../app/Services/ObjectiveService";
import Pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import SortableMixin from "@/components/pagination/sort/SortableMixin";
import searchFilterDefault from "@/components/searchFilterDefault/searchFilterDefault.vue";

export default {
	name: "ObjetiveList",
	computed: {},
	components: { searchFilterDefault, Pagination },
	mixins: [PaginationMixin, SortableMixin],
	async mounted() {
		await this.loadData();
	},
	data: () => ({
		loading: false,
		filters: {
			description: ""
		},
		headers: [
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%"
			},
			{
				text: "Código",
				align: "left",
				sortable: true,
				value: "CODOBJ",
				width: "10%"
			},
			{
				text: "Planej.",
				align: "left",
				sortable: true,
				value: "strategicplanning.DESABR",
				width: "10%"
			},
			{
				text: "Empresa",
				align: "left",
				sortable: true,
				value: "company.DESABR",
				width: "10%"
			},
			{
				text: "Unidade",
				align: "left",
				sortable: true,
				value: "unit.DESABR",
				width: "10%"
			},
			{
				text: "Perspectiva",
				align: "left",
				sortable: true,
				value: "perspective.DESPER",
				width: "20%"
			},
			{
				text: "Nome",
				align: "left",
				sortable: true,
				value: "DESOBJ"
			},
			{
				text: "",
				align: "left",
				sortable: false,
				value: "",
				width: "3%"
			}
		],
		items: []
	}),
	methods: {
		sortTable() {
			// eslint-disable-next-line no-console
			// console.log($event);
			// this.loadData();
		},
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		async loadData() {
			try {
				this.loading = true;

				const filter = this.filters;

				const query = {
					params: {
						filter,
						...this.pagination
					}
				};

				const response = await ObjectiveService.build().index({
					query
				});

				this.items = response.data;
				this.loading = false;

				this.paginate(response);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e);
				this.defaultCatchError(e);
			} finally {
				this.loading = false;
			}
		},
		filter() {
			this.searching = true;
			this.items = [];
			this.pagination.page = 1;

			this.loadData();
		},
		edit(id) {
			this.$router.push({
				name: "ObjectiveEdit",
				params: {
					id: id
				}
			});
		},
		remove(id) {
			ObjectiveService.build()
				.destroy({
					id: id
				})
				.then(() => {
					this.toast("success", "Objetivo removido com sucesso!");

					this.loadData();
				}, (error) => {
					switch (error.response.status) {
						case 422:
							this.$toasted.info("Objetivo vinculado com Plano de Ação, não pode excluir!", { icon: 'mdi-information' });
							break;

						default:
							this.$toasted.info(error.response.data, { icon: 'mdi-information' });
							break;
					}
				});
		}
	}
};
</script>
<style scoped>
.active {
	background: transparent !important;
}
</style>
