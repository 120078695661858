var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Tarefas")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"label":"Título"},model:{value:(_vm.form.TARTIT),callback:function ($$v) {_vm.$set(_vm.form, "TARTIT", $$v)},expression:"form.TARTIT"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":"Descrição","rows":"3"},model:{value:(_vm.form.TARDES),callback:function ($$v) {_vm.$set(_vm.form, "TARDES", $$v)},expression:"form.TARDES"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"cols":"12","md":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":[
									{
										value: 'A',
										text: 'Aberta',
									},
									{
										value: 'F',
										text: 'Fechada',
									},
								],"label":"Situação"},model:{value:(_vm.form.TARSIT),callback:function ($$v) {_vm.$set(_vm.form, "TARSIT", $$v)},expression:"form.TARSIT"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.dialog)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
									{ text: '#', value: 'TARSEQ', width: '5%' },
									{ text: 'Título', value: 'TARTIT', width: '30%' },
									{ text: 'Descrição', value: 'TARDES', width: '50%' },
									{ text: '', value: 'action',  width: '20%' }
								],"items":_vm.tasks,"items-per-page":5,"item-key":"TARSEQ","no-data-text":"Nenhuma tarefa!"},scopedSlots:_vm._u([{key:"item.action",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleClickEditTask(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleDeleteModalTask(item.TARSEQ)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,false,2237067648)}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.form.update)?_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.handleDeleteModalTask(_vm.form.TARSEQ)}}},[_vm._v(" Excluir ")]):_vm._e(),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.handleCloseModalTask}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.handleSaveFormModalTask}},[_vm._v(" Salvar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }