<template>
	<div></div>
</template>

<script>
export default {
	name: "Logoff",
	mounted() {
		this.logoff();
	},
	methods: {
		logoff() {
			localStorage.removeItem("user");
			localStorage.removeItem("permissions");

			this.$router.push({ path: "/login", params: { l: true } });
		}
	}
};
</script>
