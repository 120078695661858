<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ view ? 'Visualizar analise' : (id ? 'Editar analise' : 'Cadastrar analise') }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="analyze.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									class="required"
									:loading="loadingCompany"
									@change="getUnits(true)"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="analyze.CODUNI"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									class="required"
									:loading="loadingUnit"
									@change="getObjectiveAndArea(true)"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="analyze.CODPLE"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									class="required"
									label="Planejamento estratégico"
									:loading="loadingPlanning"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="analyze.CODARE"
									:items="areas"
									item-text="DESARE"
									item-value="CODARE"
									label="Area"
									class="required"
									:loading="loadingArea"
									@change="getIndicator(true)"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="analyze.CODIND"
									:items="indicators"
									item-text="DESIND"
									item-value="CODIND"
									label="Indicador"
									class="required"
									:loading="loadingIndicator"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="analyze.PERIOD"
									:items="periods"
									item-text="DESPER"
									item-value="CODPER"
									label="Periodo"
									class="required"
									:loading="loadingPeriod"
									:disabled="view"
								></v-select>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<v-textarea v-model="analyze.DESANA" label="Descrição" class="required" :disabled="view"></v-textarea>
							</v-col>
						</v-row>

						<v-row class="mt-5">
							<v-container>
								<v-btn color="primary" depressed tile class="mr-4" @click="save" :disabled="loading" v-show="!view">Salvar</v-btn>
								<v-btn color="primary" text class="mr-4" @click="$router.push('/analyze/list')" :disabled="loading">Voltar</v-btn>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import AnalyzeRegistrationService from "../../app/Services/AnalyzeRegistrationService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import CompanyService from "../../app/Services/CompanyService";
import AreaService from "../../app/Services/AreaService";
import UnitService from "../../app/Services/UnitService";
import ObjectiveService from "../../app/Services/ObjectiveService";
import UserService from "../../app/Services/UserService";
import IndicatorService from "../../app/Services/IndicatorService";

const analyzeRegistrationService = AnalyzeRegistrationService.build();
const strategicPlanningService = StrategicPlanningService.build();
const companyService = CompanyService.build();
const areaService = AreaService.build();
const unitService = UnitService.build();
const objectiveService = ObjectiveService.build();
const userService = UserService.build();
const indicatorService = IndicatorService.build();

export default {
	name: "AnalyzeForm",
	data: () => ({
		id: null,
		view: false,
		menuDtPicker: false,
		menuDtPicker2: false,
		strategicPlannings: [],
		companies: [],
		areas: [],
		units: [],
		objectives: [],
		users: [],
		indicators: [],
		periods: [],
		status: [
			{ id: 'A', description: 'Aberto' },
			{ id: 'F', description: 'Fechado' }
		],
		analyze: {},
		loading: false,
		loadingPlanning: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingArea: false,
		loadingObjective: false,
		loadingIndicator: false,
		loadingPeriod: false,
		loadingUser: false
	}),
	mounted() {
		const { id, view } = this.$route.params;

		if (id) {
			this.id = id;
		}

		if (view) {
			this.view = view;
		}

		this.fetch();
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		save() {
			if (!this.analyze.DESANA) {
				this.toast(
					"warning",
					"Informe a descrição da analise para continuar"
				);
				return;
			}

			if (!this.analyze.CODEMP) {
				this.toast(
					"warning",
					"Informe a empresa da analise para continuar"
				);
				return;
			}

			if (!this.analyze.CODUNI) {
				this.toast(
					"warning",
					"Informe a unidade da analise para continuar"
				);
				return;
			}

			if (!this.analyze.CODPLE) {
				this.toast(
					"warning",
					"Informe o planejamento estrategico da analise para continuar"
				);
				return;
			}

			if (!this.analyze.CODARE) {
				this.toast(
					"warning",
					"Informe a area da analise para continuar"
				);
				return;
			}

			if (!this.analyze.CODIND) {
				this.toast(
					"warning",
					"Informe o indicador da analise para continuar"
				);
				return;
			}

			this.loading = true;

			const rest = this.id ? analyzeRegistrationService.update({ id: this.id, ...this.analyze }) : analyzeRegistrationService.create(this.analyze);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast('success', this.id ? 'Analise atualizada com sucesso!' : 'Analise adicionada com sucesso!');
				this.loading = false;

				this.$router.push('/analyze/list');
			});
		},
		getAnalyze () {
			this.loading = true;

			return analyzeRegistrationService.read({ id: this.id }).then((response) => {
				this.analyze = response;

				this.loading = false;
			});
		},
		getStrategicPlannings() {
			this.loadingPlanning = true;

			const userData = JSON.parse(localStorage.getItem("user"));

			return strategicPlanningService.getPlanning({ codusu: userData.user.CODUSU }).then(response => {			
				this.strategicPlannings = response;
				this.loadingPlanning = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;
			});
		},
		getUnits(change) {
			const search = {};

			this.units = [];
			this.areas = [];
			this.objectives = [];
			this.loadingUnit = true;

			if (change) {
				this.analyze.CODUNI = '';
				this.analyze.CODARE = '';
				this.analyze.CODOBJ = '';
			}

			if (this.analyze.CODEMP) {
				search.company = this.analyze.CODEMP;
			}

			return unitService.search(search).then((response) => {
				this.units = response;
				this.loadingUnit = false;
			});
		},
		getAreas(change) {
			const search = {};

			this.areas = [];
			this.loadingArea = true;

			if (change) {
				this.analyze.CODARE = '';
			}

			if (this.analyze.CODEMP) {
				search.company = this.analyze.CODEMP;
			}

			if (this.analyze.CODUNI) {
				search.unit = this.analyze.CODUNI;
			}

			areaService.search(search).then((response) => {
				this.areas = response;
				this.loadingArea = false;
			});
		},
		getObjectives(change) {
			const search = {};

			this.objectives = [];
			this.loadingObjective = true;

			if (change) {
				this.analyze.CODOBJ = '';
			}

			if (this.analyze.CODEMP) {
				search.company = this.analyze.CODEMP;
			}

			if (this.analyze.CODUNI) {
				search.unit = this.analyze.CODUNI;
			}

			objectiveService.search(search).then((response) => {
				this.objectives = response;
				this.loadingObjective = false;
			});
		},
		getIndicator (change) {
			const search = {};

			this.indicators = [];
			this.loadingIndicator = true;

			if (change) {
				this.analyze.CODOBJ = '';
			}

			if (this.analyze.CODEMP) {
				search.company = this.analyze.CODEMP;
			}

			if (this.analyze.CODUNI) {
				search.unit = this.analyze.CODUNI;
			}

			if (this.analyze.CODPLE) {
				search.strategicPlanning = this.analyze.CODPLE;
			}

			if (this.analyze.CODARE) {
				search.area = this.analyze.CODARE;
			}

			return indicatorService.search(search).then((response) => {
				this.indicators = response;
				this.loadingIndicator = false;
			});
		},
		getPeriods () {
			this.loadingPeriod = true;

			for (let i = 1; i <= 12; i += 1) {
				let value = i;
				value = (i < 10 ? `0${i}` : `${i}`);

				let description = '';
				if (value === '01') {
					description = 'Janeiro';
				}

				if (value === '02') {
					description = 'Fevereiro';
				}

				if (value === '03') {
					description = 'Março';
				}

				if (value === '04') {
					description = 'Abril';
				}

				if (value === '05') {
					description = 'Maio';
				}

				if (value === '06') {
					description = 'Junho';
				}

				if (value === '07') {
					description = 'Julho';
				}

				if (value === '08') {
					description = 'Agosto';
				}

				if (value === '09') {
					description = 'Setembro';
				}

				if (value === '10') {
					description = 'Outubro';
				}

				if (value === '11') {
					description = 'Novembro';
				}

				if (value === '12') {
					description = 'Dezembro';
				}

				this.periods.push({
					CODPER: `${value}`,
					DESPER: `${description}`
				});
			}

			this.loadingPeriod = false;
		},
		getUsers() {
			this.loadingUser = true;
			return userService.usersByKey().then((response) => {
				this.users = response;
				this.loadingUser = false;
			});
		},
		getObjectiveAndArea (change) {
			this.getObjectives(change);
			this.getAreas(change);
		},
		fetch () {
			const funcs = [];

			if (this.id) {
				funcs.push(this.getAnalyze());
			}

			funcs.push(this.getStrategicPlannings());
			funcs.push(this.getCompanies());
			funcs.push(this.getUsers());
			funcs.push(this.getPeriods());

			Promise.all(funcs)
				.then(() => {
					if (this.id) {
						this.getUnits(false);
						this.getAreas();
						this.getObjectives();
						this.getIndicator();
					}
				})
				.finally(() => {
				});
		}
	}
};
</script>
