import Rest from '../Base/Rest';

/**
 * @typedef {ModuleService}
 */
export default class ModuleService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/modules';
}
