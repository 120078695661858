<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="mt-2 pb-8 mb-5" flat>
					<v-card-title>
						<v-subheader>
							<span class="title font-weight-normal"
								>Impressão de processos</span
							>
						</v-subheader>
						<v-spacer></v-spacer>
						<v-btn
							class="white--text"
							depressed
							color="info"
							:loading="loading"
							@click="loadData()"
							>Buscar</v-btn
						>
						<search-filter-default
							v-model="filters"
						/>
						<v-spacer></v-spacer>
						<v-row justify='end'>
							<v-btn
								class="white--text mr-2"
								depressed
								color="info"
								:loading="loading"
								@click="download()"
								:disable="disabledDownload"
								>Download</v-btn
							>
						</v-row>
					</v-card-title>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12">
				<v-card flat>
					<v-data-table
						:headers="headers"
						:items="items"
						:items-per-page="pagination.itemsPerPage"
						v-bind:loading="loading"
						loading-text="Aguarde...carregando"
						:hide-default-footer="true"
					>
						<template v-slot:body="{ items }">
							<tr v-for="item in items" :key="item.GERPRO" @click="openViewProcess(item)">
								<td>{{ item.GERPRO }}</td>
								<td>{{ item.group ? item.group.DESGRP : '' }}</td>
								<td>{{ item.corporate_area ? item.corporate_area.DESAREA : '' }}</td>
								<td>{{ item.company ? item.company.DESABR : '' }}</td>
								<td>{{ item.unit ? item.unit.DESABR : '' }}</td>
								<td>{{ item.area ? item.area.DESARE : '' }}</td>
								<td>{{ item.TITPRO }}</td>
								<td>
									<v-icon v-if="item.FILE">mdi-paperclip</v-icon>
								</td>
							</tr>
						</template>
					</v-data-table>

					<pagination
						v-model="pagination"
						@change="loadData"
						:loading="loading"
					/>
				</v-card>					
			</v-col>
		</v-row>
		<ViewProcessModal
			v-if="viewProcess"
			:process="itemSelected"
			v-on:close="closeViewProcess"
		/>
	</v-container>
</template>

<script>
import axios from "../../config/service/standard";
import ProcessService from "../../app/Services/ProcessService";
import pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import ViewProcessModal from "./ViewProcessModal.vue";
import SearchFilterDefault from "./SearchFilterDefault.vue";

export default {
	name: "ProcessPrint",
	components: { SearchFilterDefault, ViewProcessModal, pagination },
	mixins: [PaginationMixin],
	data: () => ({
		filters: {
			group: "",
			company: "",
			unit: "",
			area: ""
		},
		headers: [
			{
				text: "Código",
				align: "left",
				sortable: true,
				value: "GERPRO",
				width: "10%"
			},
			{
				text: "Grupo",
				align: "left",
				sortable: true,
				value: "group.DESGRP",
				width: "10%"
			},
			{
				text: "Área Corporativa",
				align: "left",
				sortable: true,
				value: "corporateArea.DESABR",
				width: "10%"
			},
			{
				text: "Empresa",
				align: "left",
				sortable: true,
				value: "company.DESABR",
				width: "10%"
			},
			{
				text: "Unidade",
				align: "left",
				sortable: true,
				value: "unit.DESABR",
				width: "10%"
			},
			{
				text: "Area",
				align: "left",
				sortable: true,
				value: "area.DESARE",
				width: "20%"
			},
			{
				text: "Título",
				align: "left",
				sortable: true,
				value: "TITPRO"
			},
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%"
			}
		],
		items: [],
		loading: false,
		view: false,
		disabledDownload: true,
		viewProcess: false
	}),
	mounted() {
		this.loadData();
	},
	methods: {
		async loadData() {
			try {
				this.loading = true;

				const filter = this.filters;

				const query = {
					params: {
						filter,
						...this.pagination
					}
				};

				const response = await ProcessService.build().index({
					query
				});

				this.items = response.data;
				this.loading = false;

				this.paginate(response);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e);
				this.defaultCatchError(e);
			} finally {
				this.loading = false;
			}
		},
		openViewProcess(item) {
			this.itemSelected = item;

			this.viewProcess = true;
		},
		closeViewProcess() {
			this.viewProcess = false;
		},
		downloadItem(item) {
			this.$root.$Loading.show("Fazendo download...");

			axios
				.get("/api/process/download", {
					responseType: "arraybuffer",
					params: {
						id: item.GERPRO
					}
				})
				.then(async response => {
					let blob = new Blob([response.data], {
						type: "application/pdf",
					});

					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = item.TITPRO + ".pdf";
					link.click();	
				})
				.finally(() => {
					this.$root.$Loading.hide();
				});
		},
		download() {
			this.$root.$Loading.show("Gerando PDF...");

			axios
				.get("/api/process/print-download", {
				responseType: "arraybuffer",
				params: {
					filter: { ...this.filters }
				}
				})
				.then((response) => {
					this.$root.$Loading.hide();

					let blob = new Blob([response.data], {
						type: "application/pdf",
					});

					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = "Processos.pdf";
					link.click();					
				})
				.catch((e) => {
					this.defaultCatchError(e);
				})
				.finally(() => {
					this.$root.$Loading.hide();
				});
		},
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		valid() {
			if (!this.filters.group) {
				this.toast(
					"error",
					"Favor selecionar o grupo!"
				);

				return false;
			}

			return true;
		}
	}
};
</script>

<style>
.aggroup {
	background-color: #eaeaea;
}

.aggroup > td {
	font-size: 0.805rem;
	height: 40px;
}

.aggroupLine > td {
	font-size: 0.755rem;
	height: 40px;
}

.borderRight {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}

.colDialog {
	padding-top: 0px;
	padding-bottom: 0px;
}

.divToClick {
	cursor: pointer;
	padding-top: 10px;
	padding-bottom: 10px;
}

.dot {
	float: right;
	margin-top: 2px;
	margin-right: 4px;
	width: 8px;
	height: 8px;
	background-color: white;
	border-radius: 50%;
}
</style>
