<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ id ? 'Editar diagnostico' : 'Cadastrar diagnostico' }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="diagnostic.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									class="required"
									:loading="loadingCompany"
									@change="getUnits"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="diagnostic.CODUNI"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									class="required"
									:loading="loadingUnit"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="diagnostic.CODPLE"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									label="Planejamento estratégico"
									class="required"
									:loading="loadingPlanning"
								></v-select>
							</v-col>
							<v-col cols="12" md="12">
								<v-text-field v-model="diagnostic.DESDIA" label="Descrição" class="required"></v-text-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-select
									v-model="diagnostic.AMBIEN"
									:items="ambients"
									item-text="description"
									item-value="id"
									label="Ambiente"
								></v-select>
							</v-col>
							<v-col cols="12" md="6" v-if="diagnostic.AMBIEN === 'I'">
								<v-select
									v-model="diagnostic.TIPO"
									:items="typesInternal"
									item-text="description"
									item-value="id"
									label="Tipo"
								></v-select>
							</v-col>
							<v-col cols="12" md="6" v-if="diagnostic.AMBIEN === 'E'">
								<v-select
									v-model="diagnostic.TIPO"
									:items="typesExternal"
									item-text="description"
									item-value="id"
									label="Tipo"
								></v-select>
							</v-col>
							<v-col cols="12" md="6">
								<v-textarea v-model="diagnostic.PORCON" label="Porque assim é considerada"></v-textarea>
							</v-col>
							<v-col cols="12" md="6">
								<v-textarea v-model="diagnostic.VANTAG" label="Perdas / Ganhos que gera para a organização"></v-textarea>
							</v-col>
							<v-col cols="12" md="6">
								<v-textarea v-model="diagnostic.DESVAN" label="Vantagens / Desvantagens que gera para a organização"></v-textarea>
							</v-col>
							<v-col cols="12" md="6">
								<v-textarea v-model="diagnostic.PRJACA" label="Projetos/ações"></v-textarea>
							</v-col>
							<header class="mt-10 ml-4">Relevancia</header>
							<v-col cols="12" md="12" class="mt-8">
								<v-slider
									v-model="diagnostic.RELEVA"
									thumb-label="always"
									min="1"
									max="10"
								></v-slider>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn color="primary" depressed tile class="mr-4" @click="save" :disabled="loading">Salvar</v-btn>
								<v-btn color="primary" text class="mr-4" @click="$router.push('/diagnostic/list')" :disabled="loading">Voltar</v-btn>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import DiagnosticService from "../../app/Services/DiagnosticService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";

const diagnosticService = DiagnosticService.build();
const strategicPlanningService = StrategicPlanningService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();

export default {
	name: "DiagnosticForm",
	data: () => ({
		id: null,
		menuDtPicker: false,
		menuDtPicker2: false,
		strategicPlannings: [],
		companies: [],
		units: [],
		diagnostic: {
			RELEVA: 1,
			AMBIEN: 'I'
		},
		loading: false,
		loadingPlanning: false,
		loadingCompany: false,
		loadingUnit: false,
		ambients: [
			{ id: 'I', description: 'Interno' },
			{ id: 'E', description: 'Externo' }
		],
		typesInternal: [
			{ id: 'O', description: 'Força' },
			{ id: 'R', description: 'Fraqueza' }
		],
		typesExternal: [
			{ id: 'P', description: 'Oportunidades' },
			{ id: 'M', description: 'Ameaças' }
		]
	}),
	mounted() {
		const { id } = this.$route.params;

		if (id) {
			this.id = id;
		}

		this.fetch();
	},
	computed: {
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		save() {
			if (!this.diagnostic.DESDIA) {
				this.toast(
					"warning",
					"Informe a descrição para continuar"
				);
				return;
			}

			if (!this.diagnostic.CODPLE) {
				this.toast(
					"warning",
					"Informe a planejamento estratégico para continuar"
				);
				return;
			}

			if (!this.diagnostic.CODEMP) {
				this.toast(
					"warning",
					"Informe a empresa para continuar"
				);
				return;
			}

			if (!this.diagnostic.CODUNI) {
				this.toast(
					"warning",
					"Informe a unidade para continuar"
				);
				return;
			}

			this.loading = true;

			if (this.diagnostic.AMBIEN === 'I') {
				this.diagnostic.OPORTU = '';
				this.diagnostic.AMEACA = '';
			}

			if (this.diagnostic.AMBIEN === 'E') {
				this.diagnostic.FORCAS = '';
				this.diagnostic.FRAQUE = '';
			}

			const rest = this.id ? diagnosticService.update({ id: this.id, ...this.diagnostic }) : diagnosticService.create(this.diagnostic);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast('success', this.id ? 'Diagnostico atualizado com sucesso!' : 'Diagnostico adicionado com sucesso!');
				this.loading = false;

				this.$router.push('/diagnostic/list');
			});
		},
		getDiagnostic () {
			this.loading = true;

			return diagnosticService.read({ id: this.id }).then((response) => {
				this.diagnostic = response;
				this.loading = false;
			});
		},
		getStrategicPlannings() {
			this.loadingPlanning = true;
			const userData = JSON.parse(localStorage.getItem("user"));

			return strategicPlanningService.getPlanning({ codusu: userData.user.CODUSU }).then(response => {
				this.strategicPlannings = response;
				this.loadingPlanning = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.units = [];
			this.loadingUnit = true;

			if (this.diagnostic.CODEMP) {
				search.company = this.diagnostic.CODEMP;
			}

			return unitService.search(search).then((response) => {
				this.units = response;
				this.loadingUnit = false;
			});
		},
		fetch () {
			const funcs = [];

			if (this.id) {
				funcs.push(this.getDiagnostic());
			}

			funcs.push(this.getStrategicPlannings());
			funcs.push(this.getCompanies());

			Promise.all(funcs)
				.then(() => {
					this.getUnits();
				})
				.finally(() => {
				});
		}
	}
};
</script>
