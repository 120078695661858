<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card flat>
					<v-card-title>
						<v-icon color="black">mdi-home</v-icon>
						<label class="pl-4">Início</label>
					</v-card-title>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "Home",
	computed: {},
	data: () => ({}),
	methods: {}
};
</script>
