import Rest from '../../app/Base/Rest';

/**
 * @typedef {DiagnosticService}
 */
export default class DiagnosticService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/diagnostic';

	getSwot(params) {
		const queryString = this.queryString(params);

		return this.get(`/getDiagnosticBySwot?${queryString}`);
	}
}
