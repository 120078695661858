<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="pa-4" flat>
					<v-card-title>Adicionar usuário</v-card-title>
					<v-divider></v-divider>
					<v-col class="pl-10 pb-5" cols="10" md="10">
						<v-skeleton-loader
							:loading="isLoading"
							height="94"
							type="list-item-avatar-three-line, list-item-three-line, list-item-two-line"
						>
							<v-row>
								<v-col cols="12" md="8">
									<v-text-field v-model="user.NOMUSU" label="Nome"></v-text-field>
								</v-col>
								<v-col cols="12" md="2">
									<v-select
										:items="roles"
										item-text="NOMPRF"
										item-value="SIGPRF"
										v-model="user.TIPUSU"
										label="Tipo"
									></v-select>
								</v-col>
								<v-col cols="12" md="2">
									<v-select
										:items="[{text: 'Ativo', value: 'A'}, { text: 'Inativo', value: 'I'}]"
										item-text="text"
										item-value="value"
										v-model="user.SITUSU"
										label="Status"
									></v-select>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="6">
									<v-text-field v-model="user.EMAUSU" label="E-mail"></v-text-field>
								</v-col>
								<v-col cols="12" md="6">
									<v-text-field v-model="user.ENDUSU" label="Endereço"></v-text-field>
								</v-col>
								<v-col cols="12" md="4">
									<v-text-field v-model="user.BAIUSU" label="Bairro"></v-text-field>
								</v-col>
								<v-col cols="12" md="3">
									<v-text-field
										tabindex="7"
										v-model="user.CEPUSU"
										:rules="[validCep]"
										v-mask="cepMask"
										label="CEP"
									></v-text-field>
								</v-col>
								<v-col cols="12" md="1">
									<v-select
										tabindex="5"
										v-model="user.ESTUSU"
										:items="states"
										:item-text="item => item.SIGEST + ' - ' + item.DESEST"
										item-value="CODEST"
										label="UF"
										placeholder="UF"
										@change="setCities"
									>
										<template v-slot:selection="data">{{data.item.SIGEST}}</template>
									</v-select>
								</v-col>

								<v-col cols="12" md="3">
									<v-select
										tabindex="6"
										v-model="user.CIDUSU"
										:items="cities"
										item-value="CODMUN"
										item-text="NOMMUN"
										label="Cidade"
									></v-select>
								</v-col>
							</v-row>
							<v-row class="mt-5">
								<v-container>
									<v-btn color="primary" depressed tile class="mr-4" @click="save">Salvar</v-btn>
									<v-btn color="primary" text class="mr-4" @click="$router.push('/user/list')">Voltar</v-btn>
								</v-container>
							</v-row>
						</v-skeleton-loader>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import UserService from "../../app/Services/UserService";
import RoleService from "../../app/Services/RoleService";
import StateService from "../../app/Services/StateService";

const userService = UserService.build();
const roleService = RoleService.build();
const stateService = StateService.build();

export default {
	name: "UserEdit",
	data: () => ({
		areas: [],
		user: {},
		roles: [],
		states: [],
		cities: [],
		cepMask: "#####-###",
		isLoading: false
	}),
	mounted() {
		this.fetch();
	},
	computed: {
		validCep() {
			if (!this.user.CEPUSU) return true;
			return () => this.user.CEPUSU.length > 8 || "CEP inválido";
		}
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		setCities() {
			const state = this.states.filter(state => {
				return state.CODEST == this.user.ESTUSU;
			});

			this.cities = (state.length > 0 ? state[0].cities : []);
		},
		getUser() {
			const id = this.$route.params.id;
			return userService.read({ id }).then(user => {
				this.user = user;
			});
		},
		getRoles() {
			return roleService.search({}).then(roles => {
				this.roles = roles;
			});
		},
		getStates() {
			return stateService.search({}).then(states => {
				this.states = states;
			});
		},
		fetch() {
			this.isLoading = true;

			Promise.all([this.getUser(), this.getRoles(), this.getStates()])
				.then(() => {
					this.setCities();
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		save() {
			if (!this.user.NOMUSU) {
				this.toast(
					"warning",
					"Informe o nome do usuário para continuar"
				);
				return;
			}

			if (!this.user.TIPUSU) {
				this.toast(
					"warning",
					"Informe o tipo de usuário para continuar"
				);
				return;
			}

			if (!this.user.SITUSU) {
				this.toast(
					"warning",
					"Informe o status do usuário para continuar"
				);
				return;
			}

			if (!this.user.EMAUSU) {
				this.toast(
					"warning",
					"Informe o e-mail do usuário para continuar"
				);
				return;
			}

			this.user.id = this.$route.params.id;

			userService.update(this.user).then(response => {
				if (!response) {
					return;
				}

				this.toast("success", "Usuário atualizado com sucesso!");
				this.$router.push("/user/list");
			});
		}
	}
};
</script>
