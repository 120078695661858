<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ id ? 'Editar faixa de desempenho' : 'Cadastrar faixa de desempenho' }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="rangeIndicator.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									class="required"
									:loading="loadingCompany"
									@change="getUnits"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="rangeIndicator.CODUNI"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									class="required"
									:loading="loadingUnit"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="rangeIndicator.CODPLE"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									class="required"
									label="Planejamento estrategico"
									:loading="loadingPlanning"
								></v-select>
							</v-col>
							<v-col cols="12" md="6">
								<v-currency-field prefix="" v-model="rangeIndicator.VERDE_DE" label="Verde inicial"></v-currency-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-currency-field prefix="" v-model="rangeIndicator.VERDE_ATE" label="Verde final"></v-currency-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-currency-field prefix="" v-model="rangeIndicator.AMARELO_DE" label="Amarelo inicial"></v-currency-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-currency-field prefix="" v-model="rangeIndicator.AMARELO_ATE" label="Amarelo final"></v-currency-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-currency-field prefix="" v-model="rangeIndicator.LARANJA_DE" label="Laranja inicial"></v-currency-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-currency-field prefix="" v-model="rangeIndicator.LARANJA_ATE" label="Laranja final"></v-currency-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-currency-field prefix="" v-model="rangeIndicator.VERMELHO_DE" label="Vermelho inicial"></v-currency-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-currency-field prefix="" v-model="rangeIndicator.VERMELHO_ATE" label="Vermelho final"></v-currency-field>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn color="primary" depressed tile class="mr-4" @click="save" :disabled="loading">Salvar</v-btn>
								<v-btn color="primary" text class="mr-4" @click="$router.push('/range-indicator/list')" :disabled="loading">Voltar</v-btn>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import RangeIndicatorService from "../../app/Services/RangeIndicatorService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";

const rangeIndicatorService = RangeIndicatorService.build();
const strategicPlanningService = StrategicPlanningService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();

export default {
	name: "RangeIndicatorForm",
	data: () => ({
		id: null,
		strategicPlannings: [],
		rangeIndicator: {},
		companies: [],
		units: [],
		loading: false,
		loadingPlanning: false,
		loadingCompany: false,
		loadingUnit: false
	}),
	mounted() {
		const { id } = this.$route.params;

		if (id) {
			this.id = id;
		}

		this.fetch();
	},
	computed: {},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		save() {
			if (!this.rangeIndicator.CODEMP) {
				this.toast(
					"warning",
					"Informe a empresa da faixa de desempenho para continuar"
				);
				return;
			}

			if (!this.rangeIndicator.CODUNI) {
				this.toast(
					"warning",
					"Informe a unidade da faixa de desempenho para continuar"
				);
				return;
			}

			if (!this.rangeIndicator.CODPLE) {
				this.toast(
					"warning",
					"Informe o planejamento estrategico da faixa de desempenho para continuar"
				);
				return;
			}

			if (!this.rangeIndicator.VERDE_ATE) {
				this.toast(
					"warning",
					"Informe o valor final da faixa verde faixa de desempenho para continuar"
				);
				return;
			}

			if (!this.rangeIndicator.AMARELO_DE) {
				this.toast(
					"warning",
					"Informe o valor inicial da faixa amarela faixa de desempenho para continuar"
				);
				return;
			}

			if (!this.rangeIndicator.AMARELO_ATE) {
				this.toast(
					"warning",
					"Informe o valor final da faixa amarela faixa de desempenho para continuar"
				);
				return;
			}

			if (!this.rangeIndicator.LARANJA_DE) {
				this.toast(
					"warning",
					"Informe o valor inicial da faixa laranja faixa de desempenho para continuar"
				);
				return;
			}

			if (!this.rangeIndicator.LARANJA_ATE) {
				this.toast(
					"warning",
					"Informe o valor final da faixa laranja faixa de desempenho para continuar"
				);
				return;
			}

			if (!this.rangeIndicator.VERMELHO_DE) {
				this.toast(
					"warning",
					"Informe o valor inicial da faixa vermelha faixa de desempenho para continuar"
				);
				return;
			}

			if (!this.rangeIndicator.VERMELHO_ATE) {
				this.toast(
					"warning",
					"Informe o valor final da faixa vermelha faixa de desempenho para continuar"
				);
				return;
			}

			this.loading = true;

			const rest = this.id ? rangeIndicatorService.update({ id: this.id, ...this.rangeIndicator }) : rangeIndicatorService.create(this.rangeIndicator);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast('success', this.id ? 'Faixa de desempenho atualizada com sucesso!' : 'Faixa de desempenho adicionada com sucesso!');

				this.loading = false;

				this.$router.push('/range-indicator/list');
			});
		},
		getRangeIndicator () {
			this.loading = true;

			return rangeIndicatorService.read({ id: this.id }).then((response) => {
				this.rangeIndicator = response;
				this.loading = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.units = [];
			this.loadingUnit = true;

			if (this.rangeIndicator.CODEMP) {
				search.company = this.rangeIndicator.CODEMP;
			}

			return unitService.search(search).then((response) => {
				this.units = response;
				this.loadingUnit = false;
			});
		},
		getStrategicPlannings() {
			this.loadingPlanning = true;
			const userData = JSON.parse(localStorage.getItem("user"));

			strategicPlanningService.getPlanning({ codusu: userData.user.CODUSU }).then(response => {
				this.strategicPlannings = response;
				this.loadingPlanning = false;
			});
		},
		fetch () {
			const funcs = [];

			if (this.id) {
				funcs.push(this.getRangeIndicator());
			}

			funcs.push(this.getStrategicPlannings());
			funcs.push(this.getCompanies());

			Promise.all(funcs)
				.then(() => {
					if (this.id) {
						this.getUnits();
					}
				})
				.finally(() => {
				});
		}
	}
};
</script>
