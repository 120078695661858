<template>
	<v-row justify="center">
		<v-dialog v-model="visible" max-width="600px">
			<v-card>
				<v-card-title>
					<span class="text-h5">Seleção do PE</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-select
									class="required"
									v-model="filter.companyGroup"
									:items="companyGroups"
									item-text="DESGRP"
									item-value="CODGRP"
									label="Grupo de empresa"
									:loading="loadingCompanyGroup"
									hide-details
									@change="getCompanies"
								></v-select>
							</v-col>

							<v-col cols="12">
								<v-select
									class="required"
									v-model="filter.company"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									:loading="loadingCompany"
									hide-details
									@change="getUnits"
								></v-select>
							</v-col>

							<v-col cols="12">
								<v-select
									class="required"
									v-model="filter.unit"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									hide-details
									:loading="loadingUnit"
								></v-select>
							</v-col>

							<v-col cols="12">
								<v-select
									class="required"
									v-model="filter.strategicPlanning"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									label="Planejamento estrategico"
									:loading="loadingPlanning"
									hide-details
								></v-select>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						@click="saveGlobalFilters"
						:loading="loading"
						text
					>
						Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { bus } from "../../main";
import CompanyGroupService from "../../app/Services/CompanyGroupService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import UserFilterPreferencesService from "../../app/Services/UserFilterPreferencesService";

const companyGroupService = CompanyGroupService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();
const strategicPlanningService = StrategicPlanningService.build();
const userFilterPreferencesService = UserFilterPreferencesService.build();

export default {
	name: "ModalSelectPE",
	components: {},
	emits: ["input"],
	async mounted() {
		bus.$on("open-modal-select-pe", (shouldOpen) => {
			this.visible = shouldOpen;
		});

		this.getDefaultValues();
	},
	data() {
		return {
			loading: false,
			visible: false,
			loadingCompanyGroup: false,
			loadingCompany: false,
			loadingUnit: false,
			loadingPlanning: false,
			companyGroups: [],
			companies: [],
			units: [],
			strategicPlannings: [],
			defaultValues: {},
			filter: {
				company: "",
				unit: "",
				strategicPlanning: "",
			},
		};
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		getDefaultValues() {
			const user = JSON.parse(localStorage.getItem("user"));

			userFilterPreferencesService
				.read({ id: user.user.CODUSU })
				.then((res) => {
					this.defaultValues = res;

					localStorage.setItem("default_filters", JSON.stringify(res));

					Promise.all([this.getCompanyGroups(), this.getStrategicPlannings()]);
				});
		},
		getCompanyGroups() {
			this.loadingCompanyGroup = true;

			companyGroupService.search({}).then((response) => {
				this.companyGroups = response;

				if (this.companyGroups.length == 1 || this.defaultValues.CODGRP) {
					this.filter.companyGroup =
						this.defaultValues.CODGRP ?? this.companyGroups[0].CODGRP;
					this.getCompanies();
				}

				this.loadingCompanyGroup = false;
			});
		},
		getCompanies() {
			const search = {};

			this.companies = [];
			this.units = [];
			this.loadingCompany = true;

			if (this.filter.companyGroup) {
				search.companyGroup = this.filter.companyGroup;
			}

			return companyService.search(search).then((response) => {
				this.companies = response;

				if (this.companies.length == 1 || this.defaultValues.CODEMP) {
					this.filter.company =
						this.defaultValues.CODEMP ?? this.companies[0].CODEMP;
					this.getUnits();
				}

				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.filter.unit = "";
			this.units = [];
			this.loadingUnit = true;

			if (this.filter.company) {
				search.company = this.filter.company;
			}

			return unitService.search(search).then((response) => {
				this.units = response;

				if (this.units.length == 1 || this.defaultValues.CODUNI) {
					this.filter.unit = this.defaultValues.CODUNI ?? this.units[0].CODUNI;
				}

				this.loadingUnit = false;
			});
		},
		getStrategicPlannings() {
			this.loadingPlanning = true;

			strategicPlanningService.search({}).then((response) => {
				this.strategicPlannings = response;

				if (this.strategicPlannings.length == 1 || this.defaultValues.CODPLE) {
					this.filter.strategicPlanning =
						this.defaultValues.CODPLE ?? this.strategicPlannings[0].CODPLE;
				}

				this.loadingPlanning = false;
			});
		},
		validate() {
			const { companyGroup, company, unit, strategicPlanning } = this.filter;

			if (!companyGroup)
				throw new Error("Informe o Grupo de empresa para continuar");

			if (!company) throw new Error("Informe a Empresa para continuar");

			if (!unit) throw new Error("Informe a Unidade para continuar");

			if (!strategicPlanning)
				throw new Error("Informe o Planejamento estrategico para continuar");
		},
		saveGlobalFilters() {
			try {
				this.validate();
				const data = {
					CODGRP: this.filter.companyGroup,
					CODEMP: this.filter.company,
					CODUNI: this.filter.unit,
					CODPLE: this.filter.strategicPlanning,
				};

				this.loading = true;

				userFilterPreferencesService.create(data).then((res) => {
					localStorage.setItem("default_filters", JSON.stringify(res));
					this.loading = false;
					this.toast("success", "Seleção padrão alterada com sucesso!");
					bus.$emit("open-modal-select-pe", false);

					setTimeout(() => {
						this.$router
							.push({ path: this.$route.path })
							.catch((err) => {
								if (err.name !== "NavigationDuplicated") {
									throw err;
								}
							})
							.then(() => {
								this.$router.go(0);
							});
					}, 1000);
				});
			} catch (e) {
				this.toast("erro", e);
			}
		},
	},
};
</script>
