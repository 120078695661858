<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="mt-2 pb-8 mb-5" flat>
					<v-card-title>
						<v-subheader>
							<span class="title font-weight-normal">Filtrar empresa</span>
						</v-subheader>
						<v-spacer></v-spacer>
						<v-btn class="white--text mr-2" depressed color="#34bfa3" @click="$router.push('/company/new')">+ Novo</v-btn>
						<v-btn class="white--text" depressed color="info" @click="filter()">Buscar</v-btn>
					</v-card-title>
					<v-spacer></v-spacer>
					<v-divider></v-divider>
					<v-card-text>
						<v-row class="pl-6">
							<v-col cols="12" md="4" sm="12" xs="12" lg="3">
								<v-text-field v-model="filters.description" label="Descrição"></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<v-card flat>
					<v-data-table
						:headers="headers"
						:items="items"
						:items-per-page="pagination.itemsPerPage"
						:loading="loading"
						:hide-default-footer="true"
						:page.sync="pagination.page"
						@update:pagination="pagination"
					>
						<template v-slot:body="{ items }">
							<tr v-for="item in items" :key="item.CODEMP">
								<td>
									<v-btn @click="edit(item.CODEMP)" icon>
										<v-icon style="color: orange">mdi-pencil</v-icon>
									</v-btn>
								</td>
								<td>{{item.CODEMP}}</td>
								<td>{{item.companygroup.DESGRP}}</td>
								<td>{{item.DESEMP}}</td>
								<td>
									<v-btn @click="remove(item.CODEMP)" icon>
										<v-icon style="color: red">mdi-delete</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>

					<pagination
						v-model="pagination"
						:loading="loading"
						@change="loadData"
					/>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import CompanyService from "../../app/Services/CompanyService";
import pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import SortableMixin from "@/components/pagination/sort/SortableMixin";

const companyService = CompanyService.build();

export default {
	name: "CompanyGroupList",
	computed: {},
	components: {
		pagination
	},
	mixins: [PaginationMixin, SortableMixin],
	mounted() {
		this.fetch();
	},
	data: () => ({
		loading: false,
		filters: {},
		headers: [
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%"
			},
			{
				text: "Código",
				align: "left",
				sortable: false,
				value: "status",
				width: "10%"
			},
			{
				text: "Grupo",
				align: "left",
				sortable: false,
				value: "status",
				width: "20%"
			},
			{
				text: "Nome",
				align: "left",
				sortable: false,
				value: "status"
			},
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%"
			}
		],
		items: []
	}),
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		fetch() {
			this.loading = true;
			let filter = {};

			if (this.filters.description) {
				filter.description = this.filters.description
			}

			companyService.search(filter).then(response => {
				this.items = response;

				this.paginate({
					total: response.length || 0,
					last_page: Math.round(response.length / this.pagination.itemsPerPage),
					from: 1,
					to: 1,
					data: {}
				});

				this.loading = false;
			});
		},
		loadData(page) {
			this.pagination.from = this.pagination.page;
			this.pagination.to = page;
		},
		filter() {
			this.searching = true;
			this.loading = true;
			this.items = [];

			this.fetch();
		},
		edit(id) {
			this.$router.push({
				name: 'CompanyEdit',
				params: {
					id: id,
				},
			});
		},
		remove(id) {
			companyService
				.destroy({
					id: id
				})
				.then(() => {
					this.toast(
						"success",
						"Empresa removido com sucesso!"
					);

					this.fetch();
				});
		}
	}
};
</script>
