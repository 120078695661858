<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ view ? 'Visualizar area' : (id ? 'Editar Área' : 'Cadastrar Área') }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="7">
								<v-text-field v-model="form.DESAREA" label="Descrição" class="required"></v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									class="required"
									v-model="form.STATUS"
									:items="itemsStatus"
									item-text="desc"
									item-value="id"
									label="Status"
								></v-select>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn color="primary" depressed tile class="mr-4" @click="save" :disabled="loading" v-show="!view">Salvar</v-btn>
								<v-btn color="primary" text class="mr-4" @click="$router.push('/corporate-area/list')" :disabled="loading">Voltar</v-btn>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import CorporateAreaService from "../../app/Services/CorporateAreaService";

const corporateAreaService = CorporateAreaService.build();

export default {
	name: "CorporateAreaForm",
	data: () => ({
		id: null,
		form: {
			CODCOPARE: 0,
			DESAREA: '',
			STATUS: 1
		},
		view: false,
		itemsStatus: [
			{ id: 1, desc: 'Ativado' },
			{ id: 0, desc: 'Inativo' }
		],
		loading: false,
		loadingUnit: false,
		loadingCompany: false
	}),
	mounted() {
		const { id, view } = this.$route.params;

		if (id) {
			this.id = id;
			this.loadData();
		}

		if (view) {
			this.view = view;
		}
	},
	computed: {},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		save() {
			if (!this.form.DESAREA) {
				this.toast(
					"warning",
					"Informe a descrição da área para continuar"
				);
				return;
			}

			this.loading = true;

			const rest = this.id ? corporateAreaService.update({ id: this.id, ...this.form }) : corporateAreaService.create(this.form);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast('success', this.id ? 'Area atualizada com sucesso!' : 'Area adicionada com sucesso!');

				this.loading = false;

				this.$router.push('/corporate-area/list');
			});
		},
		loadData () {
			this.loading = true;

			return corporateAreaService.read({ id: this.id }).then((response) => {
				this.form = response;
				this.loading = false;
			});
		}
	}
};
</script>
