import Rest from '../../app/Base/Rest';

/**
 * @typedef {MeService}
 */
export default class MeService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/auth/me';

	me() {
		return this.post('/');
	}
}
