<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ id ? "Editar capa" : "Cadastrar capa" }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4" v-show="false">
								<v-select
									v-model="company"
									:items="companies"
									class="required"
									label="Empresa"
									item-text="DESEMP"
									item-value="CODEMP"
									:loading="loadingCompany"
									return-object
								></v-select>
							</v-col>

							<v-col cols="12" md="12">
								<v-text-field
									v-model="cover.CAPDES"
									label="Nome"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12" md="3">
								<label> Logo </label>
								<PictureUploader v-model="cover.CAPLOG" :img="cover.CAPLOG" />
							</v-col>
							<v-col cols="12" md="9">
								<label> Capa </label>
								<PictureUploader v-model="cover.CAPIMG" :img="cover.CAPIMG" />
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn
									color="primary"
									depressed
									tile
									class="mr-4"
									@click="save"
									:disabled="loading"
									>Salvar</v-btn
								>
								<v-btn
									color="primary"
									text
									class="mr-4"
									@click="$router.push({ name: 'CoverList' })"
									:disabled="loading"
									>Voltar</v-btn
								>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import CoverService from "../../app/Services/CoverService";
import CompanyService from "../../app/Services/CompanyService";

import PictureUploader from "../picture-uploader/PictureUploader";

const coverService = CoverService.build();
const companyService = CompanyService.build();

export default {
	name: "CoverForm",
	components: {
		PictureUploader,
	},
	mounted() {
		this.id = 1;
		this.fetch();
	},
	data: () => ({
		id: null,
		companies: [],
		cover: {
			CAPLOG: null,
			CAPIMG: null,
		},
		loading: false,
		loadingCompany: false,
		company: {},
	}),
	computed: {},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		save() {
			if (!this.company) {
				this.toast("warning", "Informe a empresa para continuar");
				return;
			}

			// if (!this.cover.CAPDES) {
			// 	this.toast("warning", "Informe o nome para continuar");
			// 	return;
			// }

			this.loading = true;

			const rest = this.id
				? coverService.update({
						...this.cover,
						id: this.id,
						CODEMP: this.company.CODEMP,
						CODGRP: this.company.CODGRP,
				})
				: coverService.create({
						CODEMP: this.company.CODEMP,
						CODGRP: this.company.CODGRP,
						...this.cover,
				});

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast(
					"success",
					this.id
						? "Capa atualizada com sucesso!"
						: "Capa adicionada com sucesso!"
				);

				this.loading = false;

				this.$router.push({ name: "CoverList" });
			});
		},
		getCover() {
			this.loading = true;

			return coverService.read({ id: this.id }).then((response) => {
				this.cover = response;
				this.company = response.company;
				this.loading = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;

			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;
			});
		},
		fetch() {
			this.loading = true;

			Promise.all([this.getCompanies()])
				.then(() => {})
				.finally(() => {
					// const { id } = this.$route.params;
					const id = this.id;

					if (id) {
						this.id = id;
						this.getCover();
					}

					this.loading = false;
				});
		},
	},
};
</script>
