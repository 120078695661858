<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="mt-2 pb-8 mb-5" flat>
					<v-card-title>
						<v-subheader>
							<span class="title font-weight-normal">Filtrar grupos de empresa</span>
						</v-subheader>
						<v-spacer></v-spacer>
						<v-btn class="white--text mr-2" depressed color="#34bfa3" @click="$router.push('/company-group/new')">+ Novo</v-btn>
						<v-btn class="white--text" depressed color="info" @click="filter()">Buscar</v-btn>
					</v-card-title>
					<v-spacer></v-spacer>
					<v-divider></v-divider>
					<v-card-text>
						<v-row class="pl-6">
							<v-col cols="12" md="4" sm="12" xs="12" lg="3">
								<v-text-field v-model="filters.description" @change="resetPagination()" label="Descrição"></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<v-card flat>
					<v-data-table
						:headers="headers"
						:items="items"
						:items-per-page="pagination.itemsPerPage"
						v-bind:loading="loading"
						loading-text="Aguarde...carregando"
						:hide-default-footer="true"
					>
						<template v-slot:body="{ items }">
							<tr v-for="item in items" :key="item.CODGRP">
								<td>
									<v-btn @click="edit(item.CODGRP)" icon>
										<v-icon style="color: orange">mdi-pencil</v-icon>
									</v-btn>
								</td>
								<td>{{item.CODGRP}}</td>
								<td>{{item.DESGRP}}</td>
								<td>
									<v-btn @click="remove(item.CODGRP)" icon>
										<v-icon style="color: red">mdi-delete</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>

					<pagination
						v-model="pagination"
						@change="loadData"
						:loading="loading"
					/>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import CompanyGroupService from "../../app/Services/CompanyGroupService";
import pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import SortableMixin from "@/components/pagination/sort/SortableMixin";

export default {
	name: "CompanyGroupList",
	components: { pagination },
	mixins: [PaginationMixin, SortableMixin],
	mounted() {
		this.loadData();
	},
	data: () => ({
		loading: false,
		filters: {},
		headers: [
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%"
			},
			{
				text: "Código",
				align: "left",
				sortable: false,
				value: "status",
				width: "15%"
			},
			{
				text: "Nome",
				align: "left",
				sortable: false,
				value: "status"
			},
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%"
			}
		],
		items: []
	}),
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		async loadData() {
			try {
				this.loading = true;

				const filter = this.filters;

				const query = {
					params: {
						filter,
						...this.pagination
					}
				};

				const response = await CompanyGroupService.build().index({
					query
				});

				this.items = response.data;
				this.loading = false;

				this.paginate(response);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e);
				this.defaultCatchError(e);
			} finally {
				this.loading = false;
			}
		},
		resetPagination() {
			this.pagination.page = 1;
		},
		filter() {
			this.searching = true;
			this.loading = true;
			this.items = [];

			this.loadData();
		},
		edit(id) {
			this.$router.push({
				name: 'CompanyGroupEdit',
				params: {
					id: id,
				},
			});
		},
		remove(id) {
			CompanyGroupService.build()
				.destroy({
					id: id
				})
				.then(() => {
					this.toast(
						"success",
						"Grupo de empresa removido com sucesso!"
					);

					this.loadData();
				});
		}
	}
};
</script>
