<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<v-card flat :loading="loading">
					<v-card-title> Registro de indicadores </v-card-title>

					<v-card-text>
						Selecione o planejamento estratégico para continuar...

						<v-row class="mt-5">
							<v-col cols="12" md="3">
								<v-select
									v-model="filter.company"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									:loading="loadingCompany"
									@change="getUnits"
								></v-select>
							</v-col>
							<v-col cols="12" md="3">
								<v-select
									v-model="filter.unit"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									:loading="loadingUnit"
								></v-select>
							</v-col>
							<v-col cols="12" md="3">
								<v-select
									v-model="filter.strategicPlanning"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									label="Planejamento estrategico"
									:loading="loadingPlanning"
									hide-details
								></v-select>
							</v-col>

							<v-col cols="12" md="3">
								<v-btn
									class="white--text mt-3"
									depressed
									color="info"
									@click="getIndicators()"
									:loading="loading"
									>Buscar</v-btn
								>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-data-table
			v-show="itens.length > 0"
			:headers="headers"
			:items="itens"
			:loading="loading"
			item-key="name"
			sort-by="name"
			:group-by="['area.DESARE']"
			class="elevation-0"
			hide-default-footer
			disable-pagination
		>
			<template v-slot:group="{ items }">
				<tr class="aggroup">
					<td class="text-xs-right" :colspan="headers.length">
						<strong>{{
							items[0].company.DESEMP +
							" > " +
							items[0].unit.DESUNI +
							" > " +
							items[0].area.DESARE
						}}</strong>
					</td>
				</tr>
				<tr
					class="aggroupLine"
					v-for="item in items"
					:key="item.CODIND"
					:style="item.STATUS === 'I' ? 'background-color: #d6d6d6;' : ''"
				>
					<td
						v-for="(header, idx) in headers"
						:key="idx"
						:align="header.align"
						class="borderRight"
						:style="getColor(header, item) + ' padding: 0px;'"
					>
						<div
							v-if="
								header.type !== 'fixed' &&
								checkToShow(header, item, item.indicator)
							"
							class="dot"
						></div>
						<div
							v-if="
								header.type !== 'fixed' &&
								checkToShowFfHavePlanAction(item, header)
							"
							class="dot dot-black"
						></div>
						<span
							:class="
								header.valueType === 'text'
									? 'pl-3'
									: header.valueType === 'number'
									? 'pr-1'
									: ''
							"
							v-if="header.type === 'fixed'"
						>
							{{ formatField(header, item) }}
						</span>

						<v-tooltip
							bottom
							:disabled="getHint(header, item.indicator, 1) === ''"
						>
							<template v-slot:activator="{ on: tooltip }">
								<div
									v-on="{ ...tooltip }"
									v-if="header.type === 'period' && item.indicator"
									class="divToClick"
									@click="openRegistration(header, item, item.indicator)"
								>
									<span
										>{{
											getFormatValue(getCalculateRealized(item, header.value))
										}}%</span
									>
								</div>
								<span v-else>&nbsp;</span>
							</template>
							<span>{{ getHint(header, item.indicator, 1) }}</span
							><br />
							<span>{{ getHint(header, item.indicator, 2) }}</span>
						</v-tooltip>
					</td>
				</tr>
			</template>
		</v-data-table>

		<v-row v-if="itens.length > 0">
			<v-col cols="12">
				<v-card flat>
					<v-card-text>
						<div>
							<v-row>
								<v-col cols="12"> Legenda </v-col>
							</v-row>
							<v-row>
								<v-col cols="12" class="mr-5 ml-5">
									<v-card flat>
										<v-row>
											<v-col cols="12" md="3" class="text-center">
												Vermelho = Meta não atingida
											</v-col>
											<v-col cols="12" md="3" class="text-center">
												Laranja - Meta dentro da Tolerância
											</v-col>
											<v-col cols="12" md="3" class="text-center">
												Verde = Meta Atingida
											</v-col>
											<v-col cols="12" md="3" class="text-center">
												Azul - Meta Superada
											</v-col>
										</v-row>
										<v-row>
											<v-col
												cols="12"
												md="3"
												class="text-center"
												:style="{
													'background-color': this.cores.red,
												}"
												>&nbsp;</v-col
											>
											<v-col
												cols="12"
												md="3"
												class="text-center"
												:style="{
													'background-color': this.cores.orange,
												}"
												>&nbsp;</v-col
											>
											<v-col
												cols="12"
												md="3"
												class="text-center"
												:style="{
													'background-color': this.cores.green,
												}"
												>&nbsp;</v-col
											>
											<v-col
												cols="12"
												md="3"
												class="text-center"
												:style="{
													'background-color': this.cores.blue,
												}"
												>&nbsp;</v-col
											>
										</v-row>
									</v-card>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-list disabled>
										<v-list-item-group color="primary">
											<v-list-item>
												<v-list-item-icon>
													<v-icon small color="black" class="icon-border"
														>mdi-circle</v-icon
													>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title>
														O registro de indicador possui um plano de ação
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-list-item>
												<v-list-item-icon>
													<v-icon small color="white" class="icon-border"
														>mdi-circle</v-icon
													>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title>
														O registro de indicador possui análise
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list-item-group>
									</v-list>
								</v-col>
							</v-row>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<IndicatorRegisterForm
			v-on:openAction="openAction"
			v-on:closeRegistration="closeRegistration"
			v-on:openAnalyze="openAnalyze"
			v-on:confirmRegistration="confirmRegistration"
			:registerIndicator="registerIndicator"
			:planAction="planAction"
			:registration="registration"
			:metrics="metrics"
		/>

		<IndicatorRegisterFormAnalyse
			v-on:closeAnalyze="closeAnalyze"
			v-on:confirmRegistrationAnalyze="confirmRegistrationAnalyze"
			:registrationAnalyze="registrationAnalyze"
			:registerAnalyze="registerAnalyze"
			:metrics="metrics"
		/>

		<RegistrationModel ref="RegistrationModel" />
		<PlanActionFormModal
			ref="PlanActionFormModal"
			v-on:close="closePlanActionModal"
		/>
	</v-container>
</template>

<script>
import RegistrationModel from "../plan-action/RegistrationModal.vue";
import PlanActionFormModal from "../plan-action/FormModal.vue";
import IndicatorRegisterForm from "./IndicatorRegisterForm.vue";
import IndicatorRegisterFormAnalyse from "./IndicatorRegisterFormAnalyse.vue";

import RangeIndicatorService from "../../app/Services/RangeIndicatorService";
import IndicatorService from "../../app/Services/IndicatorService";
import IndicatorRegistrationService from "../../app/Services/IndicatorRegistrationService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import AnalyzeRegistrationService from "../../app/Services/AnalyzeRegistrationService";
import MetricsService from "../../app/Services/MetricsService";

const rangeIndicatorService = RangeIndicatorService.build();
const indicatorService = IndicatorService.build();
const indicatorRegistrationService = IndicatorRegistrationService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();
const strategicPlanningService = StrategicPlanningService.build();
const analyzeRegistrationService = AnalyzeRegistrationService.build();
const metricsService = MetricsService.build();

export default {
	name: "IndicatorRegister",
	components: {
		RegistrationModel: RegistrationModel,
		PlanActionFormModal: PlanActionFormModal,
		IndicatorRegisterForm,
		IndicatorRegisterFormAnalyse,
	},
	data: () => ({
		loading: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingPlanning: false,
		registerIndicator: false,
		registerAnalyze: false,
		editValues: false,
		editAnalyze: false,
		disableStatus: false,
		strategicPlannings: [],
		companies: [],
		units: [],
		itens: [],
		analyzes: [],
		rangeIndicator: {},
		criterions: [
			{ id: "1", description: "Positivo é maior" },
			{ id: "2", description: "Positivo é menor" },
		],
		metrics: [],
		filter: {
			company: "",
			unit: "",
			strategicPlanning: "",
		},
		registration: {
			id: null,
			companyName: "",
			unitName: "",
			planningName: "",
			areaName: "",
			name: "",
			month: "",
			monthNumber: "",
			unit: "",
			frequency: "",
			um: "",
			meta: 0,
			value: 0,
		},
		registrationAnalyze: {
			id: null,
			companyName: "",
			unitName: "",
			planningName: "",
			areaName: "",
			name: "",
			month: "",
			monthNumber: "",
			unit: "",
			message: "",
		},
		teste: 0,
		planAction: [],
		cores: {
			blue: "#418BCC",
			green: "#39d839",
			red: "red",
			orange: "orange",
		},
		defaultValues: {},
	}),
	mounted() {
		this.getCompanies();
		this.getStrategicPlannings();

		const userData = JSON.parse(localStorage.getItem("user"));
		this.defaultValues = JSON.parse(localStorage.getItem("default_filters"));

		if (userData) {
			this.editValues =
				userData.user.TIPUSU === "E" || userData.user.TIPUSU === "G";
			this.editAnalyze =
				userData.user.TIPUSU === "E" ||
				userData.user.TIPUSU === "G" ||
				userData.user.TIPUSU === "P";
		}
	},
	computed: {
		headers() {
			const headers = [];

			headers.push({
				text: "Indicador",
				align: "start",
				type: "fixed",
				valueType: "text",
				value: "DESIND",
				sortable: false,
				groupable: false,
			});
			headers.push({
				text: "Meta",
				align: "right",
				type: "fixed",
				valueType: "number",
				value: "META",
				width: "5%",
				sortable: false,
				groupable: false,
			});
			headers.push({
				text: "UN",
				align: "center",
				type: "fixed",
				valueType: "text",
				option: this.metrics,
				value: "UNMEDI",
				width: "3%",
				sortable: false,
				groupable: false,
			});

			if (this.itens.length > 0) {
				var period = [];
				switch (this.itens[0].strategicplanning.PERIOD) {
					case "M":
						period = [
							"Jan",
							"Fev",
							"Mar",
							"Abr",
							"Mai",
							"Jun",
							"Jul",
							"Ago",
							"Set",
							"Out",
							"Nov",
							"Dez",
						];
						break;
					case "T":
						period = ["T1", "T2", "T3", "T4"];
						break;
					case "S":
						period = ["S1", "S2"];
						break;
				}

				for (
					let idxPeriod = 0;
					idxPeriod < this.itens[0].strategicplanning.QTDPER;
					idxPeriod++
				) {
					headers.push({
						text: period[idxPeriod],
						align: "center",
						type: "period",
						valueType: "number",
						value: String("0" + (idxPeriod + 1)).slice(-2),
						width: "6%",
						sortable: false,
						groupable: false,
					});
				}
			}

			return headers;
		},
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;

				if (this.companies.length === 1 || this.defaultValues.CODEMP) {
					this.filter.company =
						this.defaultValues.CODEMP ?? this.companies[0].CODEMP;
					this.getUnits();

					this.checkAndSearch();
				}

				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.getMetrics();

			this.units = [];
			this.loadingUnit = true;

			if (this.filter.company) {
				search.company = this.filter.company;
			}

			return unitService.search(search).then((response) => {
				this.units = response;

				if (this.units.length === 1 || this.defaultValues.CODUNI) {
					this.filter.unit = this.defaultValues.CODUNI ?? this.units[0].CODUNI;

					this.checkAndSearch();
				}

				this.loadingUnit = false;
			});
		},
		getIndicators() {
			if (!this.filter.company) {
				this.toast("warning", "Informe a empresa para continuar");
				return;
			}

			if (!this.filter.unit) {
				this.toast("warning", "Informe a unidade para continuar");
				return;
			}

			if (!this.filter.strategicPlanning) {
				this.toast(
					"warning",
					"Informe o planejamento estrategico para continuar"
				);
				return;
			}

			this.loading = true;
			this.itens = [];

			Promise.all([
				indicatorService.search(this.filter),
				rangeIndicatorService.search(this.filter),
				analyzeRegistrationService.search(this.filter),
			]).then((response) => {
				if (response[0]) {
					this.itens = response[0];
				}

				if (response[1]) {
					if (response[1].length > 0) {
						this.rangeIndicator = response[1][0];
					}
				}

				if (response[2]) {
					this.analyzes = response[2];
				}

				this.loading = false;
			});
		},
		getStrategicPlannings() {
			this.loadingPlanning = true;

			const userData = JSON.parse(localStorage.getItem("user"));

			strategicPlanningService
				.getPlanning({ codusu: userData.user.CODUSU })
				.then((response) => {
					this.strategicPlannings = response;

					if (
						this.strategicPlannings.length === 1 ||
						this.defaultValues.CODPLE
					) {
						this.filter.strategicPlanning =
							this.defaultValues.CODPLE ?? this.strategicPlannings[0].CODPLE;

						this.checkAndSearch();
					}

					this.loadingPlanning = false;
				});
		},
		getCalculateRealized(item, mes) {
			let result = 0;

			const indicator = this.getIndicator(item, mes);

			if (indicator) {
				switch (indicator.CRITER) {
					case "1":
						result =
							((parseFloat(indicator.VALREA) - parseFloat(indicator.VALPRJ)) /
								parseFloat(indicator.VALPRJ)) *
								100 +
							100;
						break;
					case "2":
						result =
							((parseFloat(indicator.VALPRJ) - parseFloat(indicator.VALREA)) /
								parseFloat(indicator.VALPRJ)) *
								100 +
							100;
						break;
					default:
				}

				if (parseFloat(indicator.VALREA) === 0) {
					result = 0;
				}
			}

			return result;
		},
		getIndicator(item, mes) {
			return item.indicator.find((ind) => {
				return ind.MES === mes;
			});
		},
		getFormatValue(result) {
			return this.numeral(result).format("0,0.00");
		},
		getTolerance(item, mes) {
			const indicator = this.getIndicator(item, mes);
			const meta = parseFloat(indicator.VALPRJ);
			const result = meta * (item.TOLERANCE / 100);

			let calculate = 0;

			switch (item.CRITER) {
				case "1":
					calculate = Math.abs(meta - result);
					return calculate;
				case "2":
					calculate = Math.abs(meta + result);
					return calculate;
			}

			return 0;
		},
		getColor(header, item) {
			let color = "";
			// let resultCalculate = this.getCalculateRealized(item, header.value);

			if (item.STATUS === "I") {
				return color;
			}

			if (!this.rangeIndicator) {
				return color;
			}

			if (header.type === "fixed") {
				return color;
			}

			const indicator = this.getIndicator(item, header.value);

			if (!indicator) {
				return "";
			}

			const realized = parseFloat(indicator.VALREA);
			const meta = parseFloat(indicator.VALPRJ);

			let tolerance = this.getTolerance(item, header.value);

			switch (item.CRITER) {
				case "1":
					if (realized < tolerance) {
						color = `background-color: ${this.cores.red};`;
					} else if (realized == meta) {
						color = `background-color: ${this.cores.green};`;
					} else if (realized > meta) {
						color = `background-color: ${this.cores.blue};`;
					} else if (realized >= tolerance && realized <= meta) {
						color = `background-color: ${this.cores.orange};`;
					}
					break;
				case "2":
					if (realized > tolerance) {
						color = `background-color: ${this.cores.red};`;
					} else if (realized == meta) {
						color = `background-color: ${this.cores.green};`;
					} else if (realized < meta) {
						color = `background-color: ${this.cores.blue};`;
					} else if (realized >= meta && realized <= tolerance) {
						color = `background-color: ${this.cores.orange};`;
					}
					break;
			}

			return color;
		},
		getHint(header, indicators, line) {
			let hint = "";

			if (header.type === "fixed") {
				return hint;
			}

			const indicator = indicators.find((ind) => {
				return ind.MES === header.value;
			});

			if (!indicator) {
				return hint;
			}

			if (parseFloat(indicator.VALREA) === 0) {
				return hint;
			}

			const realized = parseFloat(indicator.VALREA);
			const meta = parseFloat(indicator.VALPRJ);

			const diff = meta - realized;

			if (line === 1) {
				hint = `Realizado: ${this.numeral(realized).format("0,0.00")}`;
			}

			if (line === 2) {
				hint = `Diferença: ${this.numeral(diff).format("0,0.00")}`;
			}

			return hint;
		},
		async confirmRegistration(_registration) {
			const data = {
				CODEMP: this.filter.company,
				CODUNI: this.filter.unit,
				CODPLE: this.filter.strategicPlanning,
				CODIND: _registration.code,
				MES: _registration.monthNumber,
				VALPRJ: this.numeral(_registration.meta).value(),
				VALREA: this.numeral(_registration.value).value(),
				CRITER: _registration.criterioncode,
				UNMEDI: _registration.unit,
			};

			this.loading = true;

			// eslint-disable-next-line no-mixed-spaces-and-tabs
			const rest = _registration.id
				? indicatorRegistrationService.update({
						id: _registration.id,
						...data,
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  })
				: indicatorRegistrationService.create(data);

			await rest.then((response) => {
				this.loading = false;

				if (!response) {
					return;
				}

				this.toast(
					"success",
					_registration.id
						? "Indicador atualizado com sucesso!"
						: "Indicador adicionado com sucesso!"
				);
				this.getIndicators();
				this.closeRegistration();
			});
		},
		formatField(header, item) {
			if (header.valueType === "text") {
				if (header.value === "UNMEDI" && item["unmedi"] != null) {
					return item["unmedi"].DESABR;
				} else {
					return item[header.value];
				}
			}

			if (header.valueType === "number") {
				return this.numeral(parseFloat(item[header.value])).format("0,0.00");
			}

			if (
				header.valueType === "option" &&
				item[header.value].length > 0 &&
				item[header.value][0].description != null
			) {
				if (header.value === "UNMEDI") {
					return item[header.value].unmedi.DESABR;
				} else {
					return header.option.filter((obj) => obj.id === item[header.value])[0]
						.description;
				}
			}

			return item[header.value];
		},
		openRegistration(header, item, indicators) {
			if (header.type === "fixed") {
				return;
			}

			const _header = Object.assign({}, header);
			const _item = Object.assign({}, item);
			const _indicators = Object.assign([], indicators);

			const _indicator = Object.assign(
				{},
				_indicators.find((ind) => {
					return ind.MES === _header.value;
				})
			);

			const indicator = Object.assign({}, _indicator);

			this.disableStatus = false;
			if (_item.STATUS === "I") {
				this.disableStatus = true;
			}

			const meta = this.numeral(parseFloat(_item.META));

			this.planAction = _item.plan_action;

			if (this.planAction.length > 0) {
				this.planAction[0].company = _item.company;
				this.planAction[0].area = _item.area;
				this.planAction[0].strategicplanning = _item.strategicplanning;
				this.planAction[0].unit = _item.unit;
			}

			this.registration.frequency =
				_item.strategicplanning.PERIOD === "M" ? "Mensal" : "";
			this.registration.criterioncode = _item.CRITER;
			this.registration.criterion = this.criterions.filter(
				(obj) => obj.id === _item.CRITER
			)[0].description;
			this.registration.umcode = _item.UNMEDI;

			let uni = this.metrics.filter((obj) => obj.CODGER === _item.UNMEDI);
			if (uni != null && uni.length > 0) {
				this.registration.um = uni[0].description;
			}

			this.registration.companyName = _item.company.DESEMP;
			this.registration.unitName = _item.unit.DESUNI;
			this.registration.planningName = _item.strategicplanning.DESPLE;
			this.registration.area = _item.area.CODARE;
			this.registration.areaName = _item.area.DESARE;
			this.registration.code = _item.CODIND;
			this.registration.name = _item.DESIND;
			this.registration.unit = _item.UNMEDI;
			this.registration.month = _header.text;
			this.registration.monthNumber = _header.value;
			this.registration.meta = meta.format("0,0.00");
			this.registration.objectiveName = _item?.objective.DESOBJ;

			this.registration.unitCode = _item.unit.CODUNI;
			this.registration.companyCode = _item.company.CODEMP;
			this.registration.planningCode = _item.strategicplanning.CODPLE;

			if (indicator && indicator.CODCIN != undefined) {
				this.registration.id = indicator.CODCIN;
				this.registration.meta = this.numeral(
					parseFloat(indicator.VALPRJ)
				).format("0,0.00");

				let teste = indicator.VALREA;

				this.registration.value = teste;
				this.registration.umcode = indicator.UNMEDI;
				this.registration.unit = indicator.UNMEDI;

				let uniTmp = this.metrics.filter((obj) => obj.id === indicator.UNMEDI);

				if (uniTmp != null && uniTmp.length > 0) {
					this.registration.um = uniTmp[0].description;
				}

				this.registration.criterioncode = indicator.CRITER;
				this.registration.criterion = this.criterions.filter(
					(obj) => obj.id === indicator.CRITER
				)[0].description;
			}

			this.registerIndicator = true;
		},
		async closeRegistration() {
			this.registerIndicator = false;

			this.registration.id = null;
			this.registration.frequency = "";
			this.registration.criterioncode = "";
			this.registration.criterion = "";
			this.registration.umcode = "";
			this.registration.um = "";
			this.registration.unitCode = "";
			this.registration.companyCode = "";
			this.registration.planningCode = "";
			this.registration.companyName = "";
			this.registration.unitName = "";
			this.registration.planningName = "";
			this.registration.area = "";
			this.registration.areaName = "";
			this.registration.code = "";
			this.registration.name = "";
			this.registration.unit = "";
			this.registration.month = "";
			this.registration.monthNumber = "";
			this.registration.meta = 0;
			this.registration.value = 0;

			this.$root.$LoadingBar.hide();
		},
		convertValue() {
			// if (this.registration.unit === '4' || this.registration.unit === '5') {
			// 	this.registration.meta = parseInt(this.registration.meta);
			// 	this.registration.value = parseInt(this.registration.value);
			// } else {
			this.registration.meta = parseFloat(this.registration.meta);
			this.registration.value = parseFloat(this.registration.value);
			// }
		},
		checkAndSearch() {
			if (
				this.filter.company &&
				this.filter.unit &&
				this.filter.strategicPlanning
			) {
				this.getIndicators();
			}
		},
		openAction(obj) {
			this.$root.$LoadingBar.show("Aguarde um instante...");

			this.planAction = obj.planAction;

			if (obj.planAction && obj.planAction.length <= 0) {
				this.registerIndicator = false;

				this.registration = obj.registration;

				this.$refs.PlanActionFormModal.openRegistration(this.registration);

				this.$root.$LoadingBar.hide();
				// this.$refs.PlanActionFormModal.openRegistration(this.registration);

				// this.toast('warning', 'Indicador não vinculado com o plano de ação, favor corrigir o cadastro!');
				return;
			}

			this.$root.$LoadingBar.hide();

			this.closeRegistration();

			this.$refs.RegistrationModel.openRegistration(this.planAction[0]);
		},
		async openAnalyze(_registrationAnalyze) {
			const filter = {};

			this.registrationAnalyze = await _registrationAnalyze;

			this.registrationAnalyze.id = null;
			filter.company = this.filter.company;
			filter.planning = this.filter.strategicPlanning;
			filter.unit = this.filter.unit;
			filter.area = this.registrationAnalyze.area;
			filter.indicator = this.registrationAnalyze.indicator;
			filter.month = this.registrationAnalyze.monthNumber;

			await this.closeRegistration();

			await this.$root.$LoadingBar.show("Aguarde um instante...");

			await analyzeRegistrationService
				.search(filter)
				.then((response) => {
					if (response.length > 0) {
						this.registrationAnalyze.id = response[0].CODANA;
						this.registrationAnalyze.message = response[0].DESANA;
					}

					this.registerAnalyze = true;
					this.$root.$LoadingBar.hide();
				})
				.catch(() => {
					this.$root.$LoadingBar.hide();
				});
		},
		closeAnalyze() {
			this.registerAnalyze = false;

			this.registrationAnalyze.companyName = "";
			this.registrationAnalyze.unitName = "";
			this.registrationAnalyze.planningName = "";
			this.registrationAnalyze.area = "";
			this.registrationAnalyze.areaName = "";
			this.registrationAnalyze.code = "";
			this.registrationAnalyze.name = "";
			this.registrationAnalyze.unit = "";
			this.registrationAnalyze.month = "";
			this.registrationAnalyze.monthNumber = "";
			this.registrationAnalyze.message = "";

			this.$root.$LoadingBar.hide();
		},
		async confirmRegistrationAnalyze(_registrationAnalyze) {
			await this.$root.$LoadingBar.show("Aguarde um instante...");

			const data = {
				CODEMP: this.filter.company,
				CODUNI: this.filter.unit,
				CODPLE: this.filter.strategicPlanning,
				CODIND: _registrationAnalyze.code,
				PERIOD: _registrationAnalyze.monthNumber,
				CODARE: _registrationAnalyze.area,
				DESANA: _registrationAnalyze.message,
			};

			this.loading = true;

			const rest = _registrationAnalyze.id
				? analyzeRegistrationService.update({
						id: _registrationAnalyze.id,
						...data,
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  })
				: analyzeRegistrationService.create(data);

			await rest
				.then((response) => {
					this.loading = false;

					if (!response) {
						return;
					}

					this.toast(
						"success",
						_registrationAnalyze.id
							? "Analise atualizado com sucesso!"
							: "Analise adicionado com sucesso!"
					);
					this.closeAnalyze();
					this.getIndicators();

					this.$root.$LoadingBar.hide();
				})
				.catch(() => {
					this.$root.$LoadingBar.hide();
				});
		},
		checkToShow(header, item, indicators) {
			let show = false;

			const indicator = indicators.find((ind) => {
				return ind.MES === header.value;
			});

			if (indicator) {
				show = this.analyzes.find((analyze) => {
					return (
						analyze.PERIOD === indicator.MES &&
						analyze.CODIND === indicator.CODIND &&
						analyze.CODEMP === indicator.CODEMP &&
						analyze.CODPLE === indicator.CODPLE
					);
				});
			}

			return show;
		},
		checkToShowFfHavePlanAction(item, header) {
			header.have_action_plan_month = false;

			if (item?.plan_action.length > 0) {
				item?.plan_action.map((planaction) => {
					let qndini = new Date(planaction.QNDINI);
					let qndinimes = qndini.getUTCMonth() + 1;
					let qndfin = new Date(planaction.QNDFIN);
					let qndfinmes = qndfin.getUTCMonth() + 1;

					if (
						parseInt(header.value) >= parseInt(qndinimes) &&
						parseInt(header.value) <= parseInt(qndfinmes)
					) {
						header.have_action_plan_month = true;
					}
				});
			}

			return header.have_action_plan_month;
		},
		closePlanActionModal() {
			this.getIndicators();
			this.closeRegistration();

			this.$root.$LoadingBar.hide();
		},
		getMetrics() {
			const search = {};

			if (this.filter.company) {
				search.company = this.filter.company;
			}

			metricsService.search(search).then((response) => {
				this.metrics = response;
			});
		},
	},
};
</script>

<style>
.aggroup {
	background-color: #eaeaea;
}

.aggroup > td {
	font-size: 0.805rem;
	height: 40px;
}

.aggroupLine > td {
	font-size: 0.755rem;
	height: 40px;
}

.borderRight {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}

.colDialog {
	padding-top: 0px;
	padding-bottom: 0px;
}

.divToClick {
	cursor: pointer;
	padding-top: 10px;
	padding-bottom: 10px;
}

.dot {
	float: right;
	margin-top: 2px;
	margin-right: 4px;
	width: 8px;
	height: 8px;
	background-color: white;
	border-radius: 50%;
}
.dot-black {
	background-color: black !important;
}
.icon-border {
	box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
	border-radius: 50%;
}
</style>
