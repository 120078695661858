<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ view ? 'Visualizar Unidade de Medida' : (id ? 'Editar Unidade de Medida' : 'Cadastrar Unidade de Medida') }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="metric.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									class="required"
									:loading="loadingCompany"
								></v-select>
							</v-col>
							<v-col cols="12" md="8">
								<v-text-field v-model="metric.DESCNAME" label="Nome" class="required"></v-text-field>
							</v-col>
							<v-col cols="12" md="8">
								<v-text-field v-model="metric.DESABR" label="Abreviatura" class="required"></v-text-field>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn color="primary" depressed tile class="mr-4" @click="save" :disabled="loading" v-show="!view">Salvar</v-btn>
								<v-btn color="primary" text class="mr-4" @click="$router.push('/metrics/list')" :disabled="loading">Voltar</v-btn>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import MetricsService from "../../app/Services/MetricsService";
import CompanyService from "../../app/Services/CompanyService";

const metricsService = MetricsService.build();
const companyService = CompanyService.build();

export default {
	name: "MetricsForm",
	data: () => ({
		id: null,
		loading: false,
        loadingCompany: false,
        metric: []
	}),
	mounted() {
		const { id, view } = this.$route.params;

		if (id) {
			this.id = id;
		}

		if (view) {
			this.view = view;
		}

		this.getCompanies();
		this.fetch();
	},
	computed: {},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		save() {
            /*
			if (!this.metric.RESPON) {
				this.toast(
					"warning",
					"Informe o responsavel do indicador para continuar"
				);
				return;
			}
            */

			this.loading = true;

			const rest = this.id ? metricsService.update({ id: this.id, ...this.metric }) : metricsService.create(this.metric);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast('success', this.id ? 'Unidade de medida atualizado com sucesso!' : 'Unidade de medida adicionada com sucesso!');

				this.loading = false;

				this.$router.push('/metrics/list');
			});
		},
		fetch() {
			this.loading = true;

			return metricsService.read({ id: this.id }).then((response) => {
				this.metric = response;
				this.loading = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;
			});
		}
	}
};
</script>
