<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card flat>
					<v-card-title>
						<v-icon color="black">mdi-home</v-icon>
						<label class="pl-4">Início</label>
					</v-card-title>

					<v-divider></v-divider>

					<v-card-text>
						<v-row>
							<v-col class="mr-6" cols="12" md="2">
								<PictureUploader v-model="user.FOTUSU" @input="save" customEmit="profilePictureUpdated" />
							</v-col>
							<v-col cols="12" md="6">
								<v-icon color="primary" left>mdi-information</v-icon>
								<span style="color: #1976d2; font-weight: bold; font-size: 12px !important" class="overline">Informações</span>

								<v-row class="pa-4">
									<v-col cols="12" md="8">
										<v-text-field label="Nome" v-model="user.NOMUSU"></v-text-field>
									</v-col>
									<v-col cols="12" md="8">
										<v-text-field label="E-mail" v-model="user.EMAUSU"></v-text-field>
									</v-col>
									<v-col cols="12" md="8">
										<v-text-field label="Chave de usuário" readonly v-model="user.USRKEY"></v-text-field>
									</v-col>
									<v-col cols="12" md="8">
										<v-text-field label="Senha" v-model="user.newPassword" type="password"></v-text-field>
									</v-col>
								</v-row>
							</v-col>

							<v-row>
								<v-col cols="12" md="12">
									<v-icon color="primary" left>mdi-account-settings</v-icon>
									<span style="color: #1976d2; font-weight: bold; font-size: 12px !important" class="overline">Configurações</span>

									<v-row>
										<v-col cols="12" md="6">
											<v-dialog v-model="deleteDialog" persistent max-width="600">
												<template v-slot:activator="{ on, attrs }">
													<v-btn color="red" outlined dark v-bind="attrs" v-on="on">Excluir cadastro</v-btn>
												</template>
												<v-card>
													<v-card-title class="headline">Excluir cadastro</v-card-title>
													<v-divider></v-divider>
													<v-card-text>
														<v-row>
															<v-col cols="12" md="12">Deseja realmente excluir seu cadastro? Seus dados serão excluidos definitivamente</v-col>
														</v-row>
													</v-card-text>
													<v-card-actions>
														<v-spacer></v-spacer>
														<v-btn style="color: white" color="primary" @click="deleteRegistration">Sim, concordo em excluir meu cadastro</v-btn>
														<v-btn color="red darken-1" text @click="deleteDialog = false">Cancelar</v-btn>
													</v-card-actions>
												</v-card>
											</v-dialog>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-row>
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions class="pa-4">
						<v-spacer></v-spacer>
						<v-btn @click="save" color="primary" :loading="isLoading" depressed tile class="mr-4">
							<div>Atualizar dados</div>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import UserService from '../../app/Services/UserService';
import PictureUploader from '../picture-uploader/PictureUploader';

const userService = UserService.build();

export default {
	name: 'AdministratorHome',
	components: {
		PictureUploader: PictureUploader,
	},
	mounted() {
		const localStorageData = JSON.parse(localStorage.getItem('user'));
		this.id = localStorageData.user.CODUSU;

		this.loadData();
	},
	data: () => ({
		deleteDialog: false,
		isLoading: false,
		id: null,
		user: {},
		candidate: {},
	}),
	methods: {
		deleteRegistration() {
			return userService.deleteRegistration().then(() => {
				this.$toasted.success('Cadastro excluído com sucesso!', {
					icon: 'mdi-check',
				});

				this.$router.push({ path: '/logoff' });
			});
		},
		save() {
			this.isLoading = true;

			userService
				.updateData(
					this.user
				)
				.then(() => {
					this.$toasted.success('Usuário atualizado com sucesso!', { icon: 'mdi-check' });

					if (this.user.newPassword) {
						this.$router.push({ path: "/logoff" });
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		loadData() {
			userService
				.read({
					id: this.id,
				})
				.then((response) => {
					this.user = response;

					this.user.newPassword = '';
				});
		},
	},
};
</script>
