<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ view ? 'Visualizar tipo' : (id ? 'Editar tipo' : 'Cadastrar tipo') }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="type.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									class="required"
									:loading="loadingCompany"
									@change="getUnits"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									class="required"
									v-model="type.CODUNI"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									:loading="loadingUnit"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="12">
								<v-text-field v-model="type.DESTIP" label="Nome" class="required" :disabled="view"></v-text-field>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn color="primary" depressed tile class="mr-4" @click="save" :disabled="loading" v-show="!view">Salvar</v-btn>
								<v-btn color="primary" text class="mr-4" @click="$router.push('/type/list')" :disabled="loading">Voltar</v-btn>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import CompanyService from "../../app/Services/CompanyService";
import TypeService from "../../app/Services/TypeService";
import UnitService from "../../app/Services/UnitService";

const companyService = CompanyService.build();
const typeService = TypeService.build();
const unitService = UnitService.build();

export default {
	name: "TypeForm",
	data: () => ({
		id: null,
		view: false,
		companies: [],
		units: [],
		type: {},
		loading: false,
		loadingUnit: false,
		loadingCompany: false
	}),
	mounted() {
		const { id, view } = this.$route.params;

		if (id) {
			this.id = id;
			this.getType();
		}

		if (view) {
			this.view = view;
		}

		this.getCompanies();
	},
	computed: {},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		save() {
			if (!this.type.CODEMP) {
				this.toast(
					"warning",
					"Informe a empresa do indicador para continuar"
				);
				return;
			}

			if (!this.type.CODUNI) {
				this.toast(
					"warning",
					"Informe a unidade para continuar"
				);
				return;
			}

			if (!this.type.DESTIP) {
				this.toast(
					"warning",
					"Informe o nome da tipo para continuar"
				);
				return;
			}

			this.loading = true;

			const rest = this.id ? typeService.update({ id: this.id, ...this.type }) : typeService.create(this.type);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast('success', this.id ? 'Tipo atualizada com sucesso!' : 'Tipo adicionada com sucesso!');

				this.loading = false;

				this.$router.push('/type/list');
			});
		},
		getType () {
			this.loading = true;

			return typeService.read({ id: this.id }).then((response) => {
				this.type = response;
				this.loading = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;

				if (this.id) {
					this.getUnits();
				}
			});
		},
		getUnits() {
			const search = {};

			this.units = [];
			this.loadingUnit = true;

			if (this.type.CODEMP) {
				search.company = this.type.CODEMP;
			}

			return unitService.search(search).then((response) => {
				this.units = response;
				this.loadingUnit = false;
			});
		}
	}
};
</script>
