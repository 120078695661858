<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="mt-2 pb-8 mb-5" flat>
					<v-card-title>
						<v-subheader>
							<span class="title font-weight-normal"
								>Filtrar perguntas frequentes</span
							>
						</v-subheader>
						<v-spacer></v-spacer>
						<v-btn
							class="white--text mr-2"
							depressed
							color="#34bfa3"
							@click="$router.push('/faq/new')"
							>+ Nova</v-btn
						>
						<v-btn class="white--text" depressed color="info" @click="filter()"
							>Buscar</v-btn
						>
					</v-card-title>
					<v-spacer></v-spacer>
					<v-divider></v-divider>
					<v-card-text>
						<v-row class="pl-6">
							<v-col cols="12" md="4" sm="12" xs="12" lg="3">
								<v-text-field
									v-model="filters.question"
									@change="resetPagination()"
									label="Pergunta"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<v-card flat>
					<v-data-table
						:headers="headers"
						:items="items"
						:items-per-page="pagination.itemsPerPage"
						v-bind:loading="loading"
						loading-text="Aguarde...carregando"
						:hide-default-footer="true"
					>
						<template v-slot:body="{ items }">
							<tr v-for="item in items" :key="item.FAQCOD">
								<td>
									<v-btn @click="edit(item.FAQCOD)" icon>
										<v-icon style="color: orange">mdi-pencil</v-icon>
									</v-btn>
								</td>
								<td align="center">{{ item.FAQCOD }}</td>
								<td>{{ item.FAQPER }}</td>
								<td>
									<v-btn @click="remove(item.FAQCOD)" icon>
										<v-icon style="color: red">mdi-delete</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>

					<pagination
						v-model="pagination"
						@change="loadData"
						:loading="loading"
					/>
				</v-card>
			</v-col>
		</v-row>

		<template>
			<Dialog ref="confirm" />
		</template>
	</v-container>
</template>

<script>
import FaqService from "../../app/Services/FaqService";
import pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import SortableMixin from "@/components/pagination/sort/SortableMixin";
import Dialog from "../misc/Dialog";

export default {
	name: "FaqList",
	components: {
		pagination,
		Dialog,
	},
	mixins: [PaginationMixin, SortableMixin],
	mounted() {
		this.loadData();
	},
	data: () => ({
		loading: false,
		filters: {},
		headers: [
			{
				text: "",
				align: "center",
				sortable: false,
				value: "status",
				width: "3%",
			},
			{
				text: "Código",
				align: "center",
				sortable: true,
				value: "FAQCOD",
				width: "15%",
			},
			{
				text: "Nome",
				align: "left",
				sortable: true,
				value: "FAQPER",
			},
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%",
			},
		],
		items: [],
	}),
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		async loadData() {
			try {
				this.loading = true;

				const filter = this.filters;

				const query = {
					params: {
						filter,
						...this.pagination,
					},
				};

				const response = await FaqService.build().index({
					query,
				});

				this.items = response.data;
				this.loading = false;

				this.paginate(response);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e);
				this.defaultCatchError(e);
			} finally {
				this.loading = false;
			}
		},
		resetPagination() {
			this.pagination.page = 1;
		},
		filter() {
			this.searching = true;
			this.loading = true;
			this.items = [];

			this.loadData();
		},
		edit(id) {
			this.$router.push({
				name: "FaqEdit",
				params: {
					id: id,
				},
			});
		},
		async remove(id) {
			await this.$refs.confirm
				.open("Atenção", "Deseja mesmo excluir a pergunta?")
				.then(() => {
					this.$root.$Loading.show("Removendo pergunta...");

					FaqService.build()
						.destroy({
							id: id,
						})
						.then(() => {
							this.toast("success", "pergunta removida com sucesso!");

							this.loadData();
						})
						.finally(() => {
							this.$root.$Loading.hide();
						});
				});
		},
	},
};
</script>
