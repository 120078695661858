<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card flat>
					<v-card-title>
						<v-icon color="black">mdi-home</v-icon>
						<label class="pl-4">Início</label>
					</v-card-title>

					<v-divider></v-divider>

					<v-card-text>
						<v-row>
							<v-col class="pb-5" cols="12" md="12">
								<!-- <v-responsive>
									<iframe src="http://www.google.com/finance/quote/USD-BRL" frameborder="0"></iframe>
								</v-responsive> -->
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "CompanyHomeFinance"
};
</script>
