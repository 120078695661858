import Rest from '../../app/Base/Rest';

/**
 * @typedef {UserService}
 */
export default class UserService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/users';

	verifyEmail(email) {
		return this.post('verify-email', { email });
	}

	activate(token) {
		return this.post('activate', { token });
	}

	deleteRegistration() {
		return this.post('delete-registration', {});
	}

	usersByKey() {
		return this.get('users-by-key', {});
	}

	reSendMail(user) {
		return this.post('resendmail', { user });
	}

	updateData(user) {
		return this.put('data', { user });
	}
}
