import Rest from '../../app/Base/Rest';

/**
 * @typedef {PasswordResetService}
 */
export default class PasswordResetService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = 'auth/password';

	validate(email) {
		return this.post('validate', { email });
	}

	reset({ email, token, password}) {
		return this.post('reset', {
			email,
			token,
			password
		});
	}
}