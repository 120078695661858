<template>
	<div>
		<v-dialog
			v-model="dialog"
			max-width="290"
		>
			<v-card>
				<v-card-title class="text-h5">
					{{ title }}
				</v-card-title>

				<v-card-text>
					{{ message }}
				</v-card-text>

				<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn
					color="green darken-1"
					text
					@click="cancel()"
				>
					Não
				</v-btn>

				<v-btn
					color="green darken-1"
					text
					@click="success()"
				>
					Sim
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'Dialog',
	data () {
		return {
			dialog: false,
			title: '',
			message: '',
			resolve: null,
			reject: null
		}
	},
	methods: {
		open (title, message) {
			this.dialog = true;
			this.title = title;
			this.message = message;

			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			})
		},
		success() {
			this.resolve(true);
			this.hide();
		},
		cancel() {
			this.reject(false);
			this.hide();
		},
		hide () {
			this.dialog = false;
		}
	}
}
</script>
