import Rest from "../Base/Rest";

/**
 * @typedef {OrganizationChartLevelService}
 */
export default class OrganizationChartLevelService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = "/organization-chart-level";
}
