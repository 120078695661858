<template>
	<v-container fluid>
		<v-row class="mt-5">
			<v-col cols="12" md="3">
				<v-select
					v-model="filter.group"
					:items="groups"
					item-text="DESGRP"
					item-value="CODGRP"
					label="Grupo"
					:loading="loadingGroup"
				></v-select>
			</v-col>
			<v-col cols="12" md="3">
				<v-select
					v-model="filter.corporateArea"
					:items="corporateAreas"
					item-text="DESAREA"
					item-value="GERCOPARE"
					label="Área Corporativa"
					:loading="loadingCorporateArea"
					@change="getUnits()"
				></v-select>
			</v-col>
			<v-col cols="12" md="3">
				<v-select
					v-model="filter.company"
					:items="companies"
					item-text="DESEMP"
					item-value="CODEMP"
					label="Empresa"
					:disabled="disableCompany"
					:loading="loadingCompany"
					@change="getUnits()"
				></v-select>
			</v-col>
			<v-col cols="12" md="3">
				<v-select
					v-model="filter.unit"
					:items="units"
					item-text="DESUNI"
					item-value="CODUNI"
					label="Unidade"
					:disabled="disableUnit"
					:loading="loadingUnit"
					@change="getAreas()"
				></v-select>
			</v-col>
			<v-col cols="12" md="3">
				<v-select
					v-model="filter.area"
					:items="areas"
					item-text="DESARE"
					item-value="CODARE"
					label="Área"
					:disabled="disableArea"
					:loading="loadingAreas"
				></v-select>
			</v-col>
			<!-- <v-col cols="12" md="3">
				<v-select
					v-model="filter.type"
					:items="types"
					item-text="DESTIP"
					item-value="CODTIP"
					label="Tipo"
					:disabled="disableType"
					:loading="loadingType"
					no-data-text="Nenhum tipo encontrado"
				></v-select>
			</v-col> -->
		</v-row>
	</v-container>
</template>

<script>
import moment from "moment";

import CompanyGroupService from "../../app/Services/CompanyGroupService";
import CompanyService from "@/app/Services/CompanyService";
import UnitService from "@/app/Services/UnitService";
import AreaService from "@/app/Services/AreaService";
import CorporateAreaService from "../../app/Services/CorporateAreaService";
import TypeService from "@/app/Services/TypeService";

const companyGroupService = CompanyGroupService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();
const areaService = AreaService.build();
const corporateAreaService = CorporateAreaService.build();
const typeService = TypeService.build();

export default {
	name: "SearchFilterDefault",
	props: {
		value: Object,
	},
	data: () => ({
		loading: false,
		loadingGroup: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingCorporateArea: false,
		loadingAreas: false,
		loadingType: false,
		corporateAreas: [
			{
				DESAREA: "Todos",
				GERCOPARE: "",
			},
		],
		groups: [
			{
				CODGRP: "",
				DESGRP: "Todos",
			},
		],
		companies: [
			{
				CODEMP: "",
				DESEMP: "Todos",
			},
		],
		units: [
			{
				CODUNI: "",
				DESUNI: "Todos",
			},
		],
		areas: [
			{
				CODARE: "",
				DESARE: "Todos",
			},
		],
		types: [
			{
				CODTIP: "",
				DESTIP: "Todos",
			},
		],
		itens: [],
		userData: {},
		filter: {
			group: "",
			corporateArea: "",
			company: "",
			unit: "",
			area: "",
			type: "",
		},
		planningHeaders: [
			{ text: "Data", align: "center", sortable: false, width: "12%" },
			{ text: "Hora", align: "center", sortable: false, width: "12%" },
			{ text: "Usuário", align: "center", sortable: false, width: "15%" },
			{ text: "Descrição", align: "left", sortable: false },
		],
		disableCompany: false,
		disableUnit: false,
		disableArea: false,
		disableType: false,
	}),
	async mounted() {
		this.userData = JSON.parse(localStorage.getItem("user"));

		this.filter = this.value;

		await this.getGroup();
		await this.getCorporateArea();
		await this.getCompanies();
		await this.getUnits();
	},
	watch: {
		filter() {
			this.$emit("input", this.filter);
		},
	},
	methods: {
		search() {
			this.$emit("search", this.filter);
		},
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		getGroup() {
			companyGroupService.search("").then((response) => {
				this.groups = response;

				this.groups.unshift({
					CODGRP: "",
					DESGRP: "Todos",
				});

				this.loadingGroup = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;

				this.companies.unshift({
					CODEMP: "",
					DESEMP: "Todos",
				});

				if (this.companies.length === 1) {
					this.filter.company = this.companies[0].CODEMP;
				}

				this.loadingCompany = false;
			});
		},
		getCorporateArea(change) {
			const search = {};

			this.corporateAreas = [];
			this.loadingCorporateArea = true;

			if (change) {
				search.CODARE = "";
			}

			if (this.filter.company) {
				search.company = this.filter.company;
			}

			if (this.filter.unit) {
				search.unit = this.filter.unit;
			}

			if (this.filter.corporateArea) {
				search.corporateArea = this.filter.corporateArea;
			}

			corporateAreaService.search(search).then((response) => {
				this.corporateAreas = response;

				this.corporateAreas.unshift({
					GERCOPARE: "",
					DESAREA: "Todos",
				});

				this.loadingCorporateArea = false;
			});
		},
		corporateAreaFilters() {
			if (parseInt(this.filter.corporateArea) > 0) {
				this.filter.company = "";
				this.filter.unit = "";
				this.filter.area = "";

				this.disableCompany = true;
				this.disableUnit = true;
				this.disableArea = true;
				this.disableType = true;
			} else {
				this.filter.corporateArea = "";

				this.disableCompany = false;
				this.disableUnit = false;
				this.disableArea = false;
				this.disableType = false;
			}
		},
		getUnits() {
			const search = {};

			this.corporateAreaFilters();

			this.units = [];
			this.loadingUnit = true;

			if (this.filter.company) {
				search.company = this.filter.company;
			}

			return unitService.search(search).then((response) => {
				this.units = response;

				this.units.unshift({
					CODUNI: "",
					DESUNI: "Todos",
				});

				if (this.units.length === 1) {
					this.filter.unit = this.units[0].CODUNI;
				}

				this.loadingUnit = false;
			});
		},
		getAreas() {
			this.loadingAreas = true;

			this.areas = [];

			return areaService
				.search({
					filter: {
						codemp: this.filter.company,
						coduni: this.filter.unit,
					},
				})
				.then((response) => {
					this.areas = response;

					this.areas.unshift({
						CODARE: "",
						DESARE: "Todos",
					});

					this.areas.unshift({
						CODARE: "",
						DESARE: "Todos",
					});

					this.loadingAreas = false;
				});
		},
		getTypes() {
			this.types = [];
			this.loadingType = true;

			if (this.filter.company && this.filter.unit) {
				typeService
					.search({
						filter: {
							codemp: this.filter.company,
							coduni: this.filter.unit,
						},
					})
					.then((response) => {
						this.types = response.data;
						this.types.unshift({
							CODTIP: "",
							DESTIP: "Todos",
						});
						this.loadingType = false;
					});
			}
		},
		formatDate(value) {
			return moment(value).format("DD/MM/YYYY");
		},
		formatNumber(value) {
			return this.numeral(parseFloat(value)).format("0,0.00");
		},
	},
};
</script>

<style>
.aggroup {
	background-color: #eaeaea;
}

.aggroup > td {
	font-size: 0.805rem;
	height: 40px;
}

.aggroupLine > td {
	font-size: 0.755rem;
	height: 40px;
}

.borderRight {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}

.colDialog {
	padding-top: 0px;
	padding-bottom: 0px;
}

.divToClick {
	cursor: pointer;
	padding-top: 10px;
	padding-bottom: 10px;
}

.breakLine {
	word-break: break-word;
}
</style>
