import axios from "axios";
import { bus } from "@/main";
import qs from "qs";

export function getAuthentication() {
	if (localStorage.getItem("user")) {
		const rawLoggedUser = localStorage.getItem("user");
		const loggedUser = JSON.parse(rawLoggedUser);

		return `Bearer ${loggedUser.access_token}`;
	}

	return "none";
}

let standard = axios.create({
	headers: {
		"Access-Control-Allow-Origin": "*"
	},
	baseURL: process.env.VUE_APP_BASE_URL,
	timeout: 0,
	transformResponse: [
		function(data) {
			return data;
		}
	],
	paramsSerializer: params => qs.stringify(params)
});

export function authenticateAllRoutes(config) {
	config.headers.common.Authorization = getAuthentication();

	return config;
}

export function showUnauthenticatedDialog() {
	bus.$emit("showToast", {
		status: "error",
		message:
			"Usuário sem permissão para essa operação, realize login e tente novamente."
	});
}

const locationToRedirect = "/logoff";
export function redirectIfUnauthorized(error) {
	if (error && error.response && error.response.status === 401) {
		localStorage.removeItem("user");
		localStorage.removeItem("permissions");

		const alreadyInLogin =
			window.location.href.indexOf(locationToRedirect) != -1;

		if (!alreadyInLogin) {
			showUnauthenticatedDialog();
		}

		setTimeout(() => {
			if (!alreadyInLogin) {
				window.location = locationToRedirect;
			}
		}, 1300);
	}

	return Promise.reject(error);
}

standard.interceptors.request.use(authenticateAllRoutes, error =>
	Promise.reject(error)
);

standard.interceptors.response.use(response => {
	if (response.data instanceof ArrayBuffer) {
		return response;
	}

	if (!(response.data.startsWith("[{") || response.data.startsWith("{"))) {
		return response;
	}

	return response;
});

export default standard;
