<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="mt-2 pb-8 mb-5" flat>
					<v-card-title>
						<v-subheader>
							<span class="title font-weight-normal"
								>Filtrar dados base</span
							>
						</v-subheader>
						<v-spacer></v-spacer>
						<v-btn
							v-show="!view"
							class="white--text mr-2"
							depressed
							color="#34bfa3"
							@click="$router.push('/base-data/new')"
							>+ Novo</v-btn
						>
						<v-btn
							class="white--text"
							depressed
							color="info"
							@click="filter()"
							>Buscar</v-btn
						>
						<search-filter-default
							v-model="filters"
							@search="filter()"
						/>
					</v-card-title>
				</v-card>
				<v-card flat>
					<v-data-table
						:headers="headers"
						:items="items"
						:items-per-page="pagination.itemsPerPage"
						v-bind:loading="loading"
						loading-text="Aguarde...carregando"
						:hide-default-footer="true"
						:footer-props="{
							'items-per-page-text': 'Itens por página',
							'items-per-page-all-text': 'Todos'
						}"
					>
						<template v-slot:body="{ items }">
							<tr v-for="item in items" :key="item.CODBAS">
								<td v-if="!view">
									<v-btn @click="edit(item.CODBAS)" icon>
										<v-icon style="color: orange"
											>mdi-pencil</v-icon
										>
									</v-btn>
								</td>
								<td v-else>&nbsp;</td>
								<td>{{ item.CODBAS }}</td>
								<td>{{ item.strategicplanning.DESABR }}</td>
								<td>{{ item.company.DESABR }}</td>
								<td>{{ item.unit.DESABR }}</td>
								<td>{{ item.MISSAO }}</td>
								<td v-if="!view">
									<v-btn @click="remove(item.CODBAS)" icon>
										<v-icon style="color: red"
											>mdi-delete</v-icon
										>
									</v-btn>
								</td>
								<td v-else>
									<v-btn @click="edit(item.CODBAS)" icon>
										<v-icon>mdi-eye</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>

					<pagination
						v-model="pagination"
						@change="loadData"
						:loading="loading"
					/>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import BaseDataService from "../../app/Services/BaseDataService";
import Pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import SortableMixin from "@/components/pagination/sort/SortableMixin";
import searchFilterDefault from "@/components/searchFilterDefault/searchFilterDefault.vue";

export default {
	name: "BaseDataList",
	components: { searchFilterDefault, Pagination },
	mixins: [PaginationMixin, SortableMixin],
	mounted() {
		const userData = JSON.parse(localStorage.getItem("user"));

		if (userData) {
			this.view = userData.user.TIPUSU === "P";
		}

		this.loadData();
	},
	data: () => ({
		loading: false,
		view: false,
		filters: {},
		headers: [
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%"
			},
			{
				text: "Código",
				align: "left",
				sortable: true,
				value: "CODBAS",
				width: "10%"
			},
			{
				text: "Planej.",
				align: "left",
				sortable: true,
				value: "strategicplanning.DESABR",
				width: "10%"
			},
			{
				text: "Empresa",
				align: "left",
				sortable: true,
				value: "company.DESABR",
				width: "10%"
			},
			{
				text: "Unidade",
				align: "left",
				sortable: true,
				value: "unit.DESABR",
				width: "10%"
			},
			{
				text: "Missão",
				align: "left",
				sortable: true,
				value: "MISSAO"
			},
			{
				text: "Ações",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%"
			}
		],
		items: []
	}),
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		async loadData() {
			try {
				this.loading = true;

				const filter = this.filters;

				const query = {
					params: {
						filter,
						...this.pagination,
						orderBy: this.orderBy()
					}
				};

				const response = await BaseDataService.build().index({
					query
				});

				this.items = response.data;
				this.loading = false;

				this.paginate(response);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e);
				this.defaultCatchError(e);
			} finally {
				this.loading = false;
			}
		},
		filter() {
			this.searching = true;
			this.items = [];
			this.pagination.page = 1;

			this.loadData();
		},
		edit(id) {
			this.$router.push({
				name: "BaseDataEdit",
				params: {
					id: id,
					view: this.view
				}
			});
		},
		remove(id) {
			BaseDataService.build()
				.destroy({
					id: id
				})
				.then(() => {
					this.toast("success", "Dados base removido com sucesso!");

					this.loadData();
				});
		}
	}
};
</script>
