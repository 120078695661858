<template>
	<v-content style="background-color: rgb(60, 41, 121);">
		<v-container class="fill-height" grid-list-md fluid>
			<v-row align="center" justify="center">
				<v-col cols="12" sm="10" md="4">
					<v-card class="pa-5">
						<v-toolbar flat>
							<v-toolbar-title>
								<v-btn @click="$router.push('/login')" icon>
									<v-icon>mdi-arrow-left</v-icon>
								</v-btn>Nova senha
							</v-toolbar-title>
						</v-toolbar>
						<v-card-text>
							<v-form ref="form">
								<v-row>
									<v-text-field
										:rules="[passwordLongEnoughRule]"
										v-model="password"
										label="Nova senha"
										type="password"
									></v-text-field>
								</v-row>
								<v-row>
									<v-text-field
										:rules="[passwordsMatchingRule]"
										v-model="password_repeat"
										label="Repetir a senha"
										type="password"
									></v-text-field>
								</v-row>
							</v-form>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								:loading="loading"
								@click="renew"
								block
								color="#3c2979"
								dark
								tile
								depressed
							>Redefinir a senha</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-content>
</template>

<script>
import PasswordResetService from "../app/Services/PasswordResetService";

const passwordResetService = PasswordResetService.build();

export default {
	name: "NewPassword",
	computed: {
		passwordsMatchingRule() {
			return () =>
				this.password === this.password_repeat ||
				"Senhas não correspondem";
		},
		passwordLongEnoughRule() {
			return () =>
				this.password.length >= 6 ||
				"A senha precisa ter no mínimo 6 caracteres";
		}
	},
	mounted() {
		const { token, email } = this.$route.query;

		if (!token || !email) {
			this.$router.push("/login");
			return;
		}
	},
	data: () => ({
		password: "",
		password_repeat: "",
		loading: false
	}),
	methods: {
		renew() {
			const { token, email } = this.$route.query;

			if (!(this.password.length >= 6)) {
				this.$toasted.info(
					"A senha precisa ter no mínimo 6 caracteres",
					{ icon: "mdi-information" }
				);
				return;
			}

			if (!(this.password === this.password_repeat)) {
				this.$toasted.info("As senhas informadas não correspondem", {
					icon: "mdi-information"
				});
				return;
			}

			this.loading = true;

			return passwordResetService
				.reset({
					token,
					email,
					password: this.password
				})
				.then(response => {
					if (
						response.status == "invalid_token" ||
						response.status == "user_not_found"
					) {
						this.$toasted.error("Dados inválidos", {
							icon: "mdi-account-remove"
						});
						return;
					}

					this.$router.push("/login");

					this.$toasted.success(
						"Senha renovada com sucesso. Faça o login para continuar"
					);
				})
				.finally(() => {
					this.loading = false;
				});
		}
	}
};
</script>
