<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>Permissões do usuário</v-card-title>
					<v-divider></v-divider>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="12">
								<v-card
									flat
									:loading="loadingUser"
									elevation="1"
								>
									<v-card-title
										>Dados do usuário</v-card-title
									>

									<v-divider></v-divider>

									<v-col cols="12" md="12">
										<v-row>
											<v-col cols="12" md="4">
												<v-text-field
													v-model="permission.NOMUSU"
													label="Nome do usuário"
													disabled
												/>
											</v-col>

											<v-col cols="12" md="4">
												<v-text-field
													v-model="permission.EMAUSU"
													label="Email do usuário"
													disabled
												/>
											</v-col>

											<v-col cols="12" md="4">
												<v-select
													:items="rolesFiltered"
													item-text="NOMPRF"
													item-value="SIGPRF"
													v-model="permission.TIPUSU"
													label="Tipo do usuário"
												/>
											</v-col>
										</v-row>
									</v-col>
								</v-card>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="12">
								<v-card
									flat
									:loading="loadingCompany"
									elevation="1"
								>
									<v-card-title class="mb-0 pb-0"
										>Empresas</v-card-title
									>
									<small class="ml-4 caption"
										>Selecione as empresas</small
									>

									<v-divider></v-divider>

									<v-col cols="12" md="12">
										<v-checkbox
											v-model="permission.companies"
											v-for="(company,
											index) in companies"
											:key="index"
											:label="company.DESEMP"
											:value="company.CODEMP"
											:disabled="loadingUnit"
											hide-details
										/>
									</v-col>
								</v-card>
							</v-col>

							<v-col cols="12" md="12">
								<v-card
									flat
									:loading="loadingUnit"
									elevation="1"
								>
									<v-card-title class="mb-0 pb-0"
										>Unidades</v-card-title
									>
									<small class="ml-4 caption"
										>Selecione as unidades</small
									>

									<v-divider></v-divider>

									<v-col cols="12" md="12">
										<v-checkbox
											v-model="permission.units"
											v-for="(unit, index) in units"
											:key="index"
											:label="
												unit.company.DESEMP +
													' > ' +
													unit.DESUNI
											"
											:value="unit.CODUNI"
											:disabled="loadingArea"
											hide-details
										/>
									</v-col>
								</v-card>
							</v-col>

							<v-col cols="12" md="12">
								<v-card
									flat
									:loading="loadingModules"
									elevation="1"
								>
									<v-card-title class="mb-0 pb-0"
										>Módulos</v-card-title
									>
									<small class="ml-4 caption"
										>Selecione os módulos</small
									>

									<v-divider></v-divider>

									<v-col cols="12" md="12">
										<v-checkbox
											v-model="permission.modules"
											v-for="(module, index) in modules"
											:key="index"
											:label="module.NAME"
											:value="module.CODMOD"
											:disabled="loadingModules"
											hide-details
										/>
									</v-col>
								</v-card>
							</v-col>

							<v-col cols="12" md="12">
								<v-card
									flat
									:loading="loadingModules"
									elevation="1"
								>
									<v-card-title class="mb-0 pb-0"
										>Planejamento estratégico</v-card-title
									>
									<small class="ml-4 caption"
										>Selecione os planejamentos</small
									>

									<v-divider></v-divider>

									<v-col cols="12" md="12">
										<v-checkbox
											v-model="permission.planning"
											v-for="(planning,
											index) in planning"
											:key="index"
											:label="planning.DESPLE"
											:value="planning.CODPLE"
											:disabled="loadingPlanning"
											hide-details
										/>
									</v-col>

									<v-divider></v-divider>
								</v-card>
							</v-col>

							<v-col cols="12" md="12">
								<v-card
									flat
									:loading="loadingCorporateAreas"
									elevation="1"
								>
									<v-card-title class="mb-0 pb-0"
										>Áreas corporativas</v-card-title
									>
									<small class="ml-4 caption"
										>Selecione as áreas corporativas</small
									>

									<v-divider></v-divider>

									<v-col cols="12" md="12">
										<v-checkbox
											v-model="permission.corporateAreas"
											v-for="(corporateArea,
											index) in corporateAreas"
											:key="index"
											:label="corporateArea.DESAREA"
											:value="corporateArea.GERCOPARE"
											hide-details
										/>
									</v-col>

									<v-divider></v-divider>
								</v-card>
							</v-col>

							<v-col cols="12" md="12">
								<v-card
									flat
									:loading="loadingArea"
									elevation="1"
								>
									<v-card-title class="mb-0 pb-0"
										>Areas</v-card-title
									>
									<small class="ml-4 caption"
										>Selecione as areas</small
									>

									<v-divider></v-divider>

									<v-col cols="12" md="12">
										<v-checkbox
											v-model="permission.areas"
											v-for="(area, index) in areas"
											:key="index"
											:label="
												area.company.DESEMP +
													' > ' +
													area.unit.DESUNI +
													' > ' +
													area.DESARE
											"
											:value="area.CODARE"
											hide-details
										/>
									</v-col>
								</v-card>
							</v-col>
						</v-row>

						<v-row class="mt-5">
							<v-container>
								<v-btn
									color="primary"
									depressed
									tile
									class="mr-4"
									@click="save"
									:disabled="loading"
									>Salvar</v-btn
								>
								<v-btn
									color="primary"
									text
									class="mr-4"
									@click="$router.push('/company/access')"
									:disabled="loading"
									>Voltar</v-btn
								>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import UserService from "../../app/Services/UserService";
import UserPermissionService from "../../app/Services/UserPermissionService";
import RoleService from "../../app/Services/RoleService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";
import ModuleService from "../../app/Services/ModuleService";
import AreaService from "../../app/Services/AreaService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import CorporateAreaService from "../../app/Services/CorporateAreaService";

const userService = UserService.build();
const userPermissionService = UserPermissionService.build();
const roleService = RoleService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();
const moduleService = ModuleService.build();
const areaService = AreaService.build();
const strategicPlanningService = StrategicPlanningService.build();
const corporateAreaService = CorporateAreaService.build();

export default {
	name: "UserPermission",
	data: () => ({
		id: null,
		companies: [],
		units: [],
		areas: [],
		modules: [],
		planning: [],
		corporateAreas: [],
		roles: [],
		user: {},
		permission: {
			user: {},
			companies: [],
			units: [],
			areas: [],
			modules: [],
			planning: [],
			corporateAreas: []
		},
		loading: false,
		loadingUser: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingModules: false,
		loadingArea: false,
		loadingPlanning: false,
		loadingCorporateAreas: false
	}),
	async mounted() {
		const { id } = this.$route.params;

		if (id) {
			this.id = id;
			await this.fetch();
		}

		await Promise.all([this.getUnits(), this.getModules()]);

		await this.fetchPermission();
	},
	computed: {
		rolesFiltered() {
			const roles = [];

			for (const it of this.roles) {
				if (it.SIGPRF !== "A" && it.SIGPRF !== "E") {
					roles.push(it);
				}
			}

			return roles;
		}
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		fetch() {
			this.loading = true;

			Promise.all([this.getUser(), this.getRoles()])
				.then(() => {})
				.finally(() => {
					this.loading = false;
				});
		},
		fetchPermission() {
			this.loading = true;
			this.loadingCompany = true;

			Promise.all([this.getCompanies()]).then(async () => {
				this.loadingCompany = false;
				await this.getUserPermission();
			});
		},
		getUser() {
			this.loadingUser = true;

			return userService.read(this.id).then(user => {
				this.loadingUser = false;
				this.user = user;
				this.permission.NOMUSU = this.user.NOMUSU;
				this.permission.EMAUSU = this.user.EMAUSU;
				this.permission.TIPUSU = this.user.TIPUSU;
			});
		},
		getRoles() {
			return roleService.search({}).then(roles => {
				this.roles = roles;
			});
		},
		getCompanies() {
			return companyService.search({}).then(response => {
				this.companies = response;
			});
		},
		getUnits() {
			const search = {};

			this.units = [];
			// this.permission.units = [];
			// this.areas = [];
			// this.permission.areas = [];
			this.loadingUnit = true;

			if (this.permission.companies.length > 0) {
				search.companies = this.permission.companies;
			}

			return unitService.search(search).then(response => {
				if (this.permission.companies.length > 0) {
					this.units = response;
				}

				this.loadingUnit = false;
			});
		},
		getModules() {
			const search = {};

			this.modules = [];
			// this.permission.units = [];
			// this.areas = [];
			// this.permission.areas = [];
			this.loadingModules = true;

			if (this.permission.modules.length > 0) {
				search.modules = this.permission.modules;
			}

			return moduleService.search(search).then(response => {
				this.modules = response;
				this.loadingModules = false;
			});
		},
		getAreas() {
			const search = {};

			this.areas = [];
			// this.permission.areas = [];
			this.loadingArea = true;

			if (this.permission.companies.length > 0) {
				search.companies = this.permission.companies;
			}

			if (this.permission.units.length > 0) {
				search.units = this.permission.units;
			}

			areaService.search(search).then(response => {
				if (this.permission.units.length > 0) {
					this.areas = response;
				}

				this.areas.sort((a, b) =>
					a.unit.DESUNI > b.unit.DESUNI
						? 1
						: a.unit.DESUNI === b.unit.DESUNI
						? a.DESARE > b.DESARE
							? 1
							: -1
						: -1
				);

				this.loadingArea = false;
			});
		},
		getPlanning() {
			this.loadingPlanning = true;

			strategicPlanningService.search({}).then(response => {
				this.planning = response;
				this.loadingPlanning = false;
			});
		},
		getCorporateAreas() {
			this.loadingCorporateAreas = true;

			corporateAreaService.search({}).then(response => {
				this.corporateAreas = response;
				this.loadingCorporateAreas = false;
			});
		},
		getUserPermission() {
			this.loading = true;

			return userPermissionService
				.search({ id: this.id })
				.then(async permissions => {
					for (const company of permissions.companies) {
						this.permission.companies.push(company.CODEMP);
					}

					if (this.permission.companies.length > 0) {
						for (const unit of permissions.units) {
							this.permission.units.push(unit.CODUNI);
						}

						for (const mod of permissions.modules) {
							this.permission.modules.push(mod.CODMOD);
						}

						await this.getAreas();

						for (const area of permissions.areas) {
							this.permission.areas.push(area.CODARE);
						}

						await this.getPlanning();

						for (const planning of permissions.planning) {
							this.permission.planning.push(planning.CODPLE);
						}

						await this.getCorporateAreas();

						for (const corporateAreas of permissions.corporateAreas) {
							this.permission.corporateAreas.push(corporateAreas.GERCOPARE);
						}
					} else {
						await this.getAreas();
						await this.getPlanning();
						await this.getCorporateAreas();
					}

					this.loading = false;
				});
		},
		save() {
			if (this.permission.modules.length == 0) {
				this.toast(
					"warning",
					"Selecione no mínimo um módulo para o usuário!"
				);
				return;
			}

			this.loading = true;
			this.permission.id = this.id;

			userPermissionService.update(this.permission).then(response => {
				this.loading = false;

				if (!response) {
					return;
				}

				this.toast("success", "Usuário atualizado com sucesso!");
				this.$router.push("/company/access");
			});
		}
	},
	watch: {
		"permission.companies": {
			handler() {
				this.getUnits();
			},
			deep: true
		},
		"permission.units": {
			handler() {
				this.getAreas();
			},
			deep: true
		}
	}
};
</script>
