<template>
	<v-app>
		<router-view></router-view>
	</v-app>
</template>

<script>
export default {
	name: "LoginView",
	components: {},
	data: () => ({})
};
</script>

<style>
</style>
