import Rest from '../Base/Rest';

/**
 * @typedef {MetricsService}
 */
export default class MetricsService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/metric';
}
