import Rest from '../Base/Rest';

/**
 * @typedef {UnitService}
 */
export default class UnitService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/unit';
}
