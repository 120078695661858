import Rest from '../Base/Rest';

/**
 * @typedef {UserKeyService}
 */
export default class UserKeyService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/userkey';
}
