<template>
	<v-app
		:style="{
			'background-color': $vuetify.theme.dark ? '#242424' : '#f2f3f8'
		}"
	>
		<Loading ref="Loading" />
		<router-view></router-view>
	</v-app>
</template>

<script>
import Loading from "./components/Loading";

export default {
	name: "AppView",
	components: {
		Loading
	},
	mounted() {
		this.$root.$Loading = this.$refs.Loading;
	},
	data: () => ({
		loading: false
	})
};
</script>

<style></style>