<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ id ? 'Editar unidade' : 'Cadastrar unidade' }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="3">
								<v-select
									v-model="unit.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									class="required"
									label="Empresa"
									:loading="loadingCompany"
								></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="3">
								<v-text-field v-model="unit.CNPJ" v-mask="'##.###.###/####-##'" label="CNPJ" class="required"></v-text-field>
							</v-col>
							<v-col cols="12" md="7">
								<v-text-field v-model="unit.DESUNI" label="Nome" class="required"></v-text-field>
							</v-col>
							<v-col cols="12" md="2">
								<v-text-field v-model="unit.DESABR" label="Abreviação" class="required" maxlength="10"></v-text-field>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn color="primary" depressed tile class="mr-4" @click="save" :disabled="loading">Salvar</v-btn>
								<v-btn color="primary" text class="mr-4" @click="$router.push('/unit/list')" :disabled="loading">Voltar</v-btn>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import UnitService from "../../app/Services/UnitService";
import CompanyService from "../../app/Services/CompanyService";

const unitService = UnitService.build();
const companyService = CompanyService.build();

export default {
	name: "UnitForm",
	data: () => ({
		id: null,
		companies: [],
		unit: {},
		loading: false,
		loadingCompany: false
	}),
	computed: {},
	mounted() {
		this.fetch();
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		save() {
			if (!this.unit.CODEMP) {
				this.toast(
					"warning",
					"Informe a empresa da unidade para continuar"
				);
				return;
			}

			if (!this.unit.CNPJ) {
				this.toast(
					"warning",
					"Informe o cnpj da unidade para continuar"
				);
				return;
			}

			if (!this.unit.DESUNI) {
				this.toast(
					"warning",
					"Informe o nome da unidade para continuar"
				);
				return;
			}

			if (!this.unit.DESABR) {
				this.toast(
					"warning",
					"Informe a abreviação da unidade para continuar"
				);
				return;
			}

			this.loading = true;

			const rest = this.id ? unitService.update({ id: this.id, ...this.unit }) : unitService.create(this.unit);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast('success', this.id ? 'Unidade atualizada com sucesso!' : 'Unidade adicionada com sucesso!');

				this.loading = false;

				this.$router.push('/unit/list');
			});
		},
		getUnit() {
			this.loading = true;

			return unitService.read({ id: this.id }).then((response) => {
				this.unit = response;
				this.loading = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;

			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;
			});
		},
		fetch () {
			this.loading = true;

			Promise.all([this.getCompanies()])
				.then(() => {
				})
				.finally(() => {
					const { id } = this.$route.params;

					if (id) {
						this.id = id;
						this.getUnit();
					}

					this.loading = false;
				});
		}
	}
};
</script>
