<template>
	<v-container fluid>
		<v-dialog v-model="registerIndicator" persistent max-width="600">
			<v-card>
				<v-card-title class="headline">
					Registrar indicador
					<v-spacer />
					<v-btn color="red darken-1" fab small text v-show="!loading" @click="closeRegistration"><v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>

				<v-divider></v-divider>

				<v-card-text>
					<v-row>
						<v-col cols="12" md="12" class="colDialog mt-6"><strong>Empresa:</strong> <span>{{ registration.companyName }}</span></v-col>
						<v-col cols="12" md="12" class="colDialog"><strong>Unidade:</strong> <span>{{ registration.unitName }}</span></v-col>
						<v-col cols="12" md="12" class="colDialog"><strong>Planejamento:</strong> <span>{{ registration.planningName }}</span></v-col>
						<v-col cols="12" md="12" class="colDialog"><strong>Objetivo:</strong> <span>{{ registration.objectiveName }}</span></v-col>
						<v-col cols="12" md="12" class="colDialog"><strong>Área:</strong> <span>{{ registration.areaName }}</span></v-col>
						<v-col cols="12" md="12" class="colDialog"><strong>Indicador:</strong> <span>{{ registration.name }}</span></v-col>
						<v-col cols="12" md="12" class="colDialog"><strong>Mês:</strong> <span>{{ registration.month }}</span></v-col>
					</v-row>

					<v-row class="mt-6">
						<v-col cols="12" md="12" class="colDialog"><strong>Periodicidade:</strong> <span>{{ registration.frequency }}</span></v-col>

						<v-col cols="12" md="6">
							<v-select
								v-model="registration.criterioncode"
								:items="criterions"
								item-text="description"
								item-value="id"
								label="Critério"
								:disabled="!editValues || disableStatus"
							></v-select>
						</v-col>

						<v-col cols="12" md="6">
							<v-select
								v-model="registration.unit"
								:items="metrics"
								item-text="DESCNAME"
								item-value="CODGER"
								label="Un. Medida"
								@change="convertValue"
								:disabled="!editValues || disableStatus"
							></v-select>
						</v-col>

						<v-col cols="12" md="6">
							<v-text-field v-model="registration.meta" label="Meta" :disabled="!editValues || disableStatus" hide-details></v-text-field>
						</v-col>

						<v-col cols="12" md="6">
							<v-currency-field prefix="" v-model="registration.value" label="Realizado" :disabled="disableStatus"></v-currency-field>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="info darken-1" text :loading="loading" v-if="editAnalyze" @click="openAction">Ação</v-btn>
					<v-btn color="info darken-1" text :loading="loading" v-if="editAnalyze" @click="openAnalyze">Analise</v-btn>
					<v-btn color="primary darken-1" text :loading="loading" :disabled="disableStatus" @click="confirmRegistration">Registrar indicador</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
export default {
	name: "IndicatorRegisterForm",
	props: {
		registration: { type: Object, required: true, value: {} },
		planAction: { type: Array, value: [] },
		registerIndicator: { type: Boolean, value: false },
		metrics: { type: Array, value: [] }
	},
	data: () => ({
		loading: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingPlanning: false,
		registerAnalyze: false,
		loadingMetrics: false,
		editValues: false,
		editAnalyze: false,
		disableStatus: false,
		strategicPlannings: [],
		companies: [],
		units: [],
		itens: [],
		analyzes: [],
		rangeIndicator: {},
		criterions: [
			{ id: '1', description: 'Positivo é maior' },
			{ id: '2', description: 'Positivo é menor' },
		],
		filter: {
			company: '',
			unit: '',
			strategicPlanning: '',
		},
		registrationAnalyze: {
			id: null,
			companyName: '',
			unitName: '',
			planningName: '',
			areaName: '',
			name: '',
			month: '',
			monthNumber: '',
			unit: '',
			message: ''
		}
	}),
	mounted() {
		const userData = JSON.parse(localStorage.getItem("user"));

		if (userData) {
			this.editValues = (userData.user.TIPUSU === 'E' || userData.user.TIPUSU === 'G');
			this.editAnalyze = (userData.user.TIPUSU === 'E' || userData.user.TIPUSU === 'G' || userData.user.TIPUSU === 'P');
		}
	},
	computed: {
		headers () {
			const headers = [];

			headers.push({ text: 'Indicador', align: 'start', type: 'fixed', valueType: 'text', value: 'DESIND', sortable: false, groupable: false });
			headers.push({ text: 'Meta', align: 'right', type: 'fixed', valueType: 'number', value: 'META', width: '5%', sortable: false, groupable: false });
			headers.push({ text: 'UN', align: 'center', type: 'fixed', valueType: 'option', option: this.metrics, value: 'UNMEDI', width: '3%', sortable: false, groupable: false });

			if (this.itens.length > 0) {
                var period = [];
                switch (this.itens[0].strategicplanning.PERIOD) {
                    case 'M':
                        period = [ 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
                        break;
                    case 'T':
                        period = [ 'T1', 'T2', 'T3', 'T4'];
                        break;
                    case 'S':
                        period = [ 'S1', 'S2'];
                        break;
                }

                for (let idxPeriod = 0; idxPeriod < this.itens[0].strategicplanning.QTDPER; idxPeriod++) {
                    headers.push({ text: period[idxPeriod], align: 'center', type: 'period', valueType: 'number', value: String("0" + (idxPeriod + 1)).slice(-2), width: '6%', sortable: false, groupable: false });
                }
			}

			return headers;
		}
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		confirmRegistration () {
			this.$emit('confirmRegistration', this.registration);
		},
		closeRegistration () {
			/*
			this.registerIndicator = false;

			this.registration.id = null;
			this.registration.frequency = '';
			this.registration.criterioncode = '';
			this.registration.criterion = '';
			this.registration.umcode = '';
			this.registration.um = '';
			this.registration.unitCode = '';
			this.registration.companyCode = '';
			this.registration.planningCode = '';
			this.registration.companyName = '';
			this.registration.unitName = '';
			this.registration.planningName = '';
			this.registration.area = '';
			this.registration.areaName = '';
			this.registration.code = '';
			this.registration.name = '';
			this.registration.unit = '';
			this.registration.month = '';
			this.registration.monthNumber = '';
			this.registration.meta = 0;
			this.registration.value = 0;
			*/

			this.$emit('closeRegistration');
		},
		convertValue () {
			// if (this.registration.unit === '4' || this.registration.unit === '5') {
			// 	this.registration.meta = parseInt(this.registration.meta);
			// 	this.registration.value = parseInt(this.registration.value);
			// } else {
				this.registration.meta = parseFloat(this.registration.meta);
				this.registration.value = parseFloat(this.registration.value);
			// }
		},
		checkAndSearch () {
			if (this.filter.company && this.filter.unit && this.filter.strategicPlanning) {
				this.getIndicators();
			}
		},
		openAction () {
			this.$emit('openAction', { registration: this.registration, planAction: this.planAction });
		},
		openAnalyze () {
			this.registrationAnalyze.companyName = this.registration.companyName;
			this.registrationAnalyze.unitName = this.registration.unitName;
			this.registrationAnalyze.planningName = this.registration.planningName;
			this.registrationAnalyze.area = this.registration.area;
			this.registrationAnalyze.areaName = this.registration.areaName;
			this.registrationAnalyze.code = this.registration.code;
			this.registrationAnalyze.name = this.registration.name;
			this.registrationAnalyze.unit = this.registration.unit;
			this.registrationAnalyze.month = this.registration.month;
			this.registrationAnalyze.monthNumber = this.registration.monthNumber;
			this.registrationAnalyze.indicator = this.registration.code;

			this.$emit('openAnalyze', this.registrationAnalyze);
		}
	}
};
</script>

<style>
	.aggroup {
		background-color: #eaeaea;
	}

	.aggroup > td {
		font-size: 0.805rem;
		height: 40px;
	}

	.aggroupLine > td {
		font-size: 0.755rem;
		height: 40px;
	}

	.borderRight {
		border-right: thin solid rgba(0, 0, 0, 0.12);
	}

	.colDialog {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.divToClick {
		cursor: pointer;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.dot {
		float: right;
		margin-top: 2px;
		margin-right: 4px;
		width: 8px;
		height: 8px;
		background-color: white;
		border-radius: 50%;
	}
</style>
