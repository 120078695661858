<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ id ? "Editar empresa" : "Cadastrar empresa" }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="2">
								<PictureUploader
									v-model="company.FOTEMP"
									:img="company.FOTEMP"
									@input="save"
								/>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									class="required"
									v-model="company.CODGRP"
									:items="companyGroup"
									item-text="DESGRP"
									item-value="CODGRP"
									label="Grupo de empresa"
									:loading="loadingCompany"
								></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="3">
								<v-switch
									v-model="company.VERLOGO"
									label="Mostrar logo na Capa"
								></v-switch>
							</v-col>

							<v-col cols="12" md="3" class="mt-4">
								<label>Cor Predominante:</label>
								<input
									type="color"
									v-model="company.FIRSTCOLOR"
									class="required alignColor"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="7">
								<v-text-field
									v-model="company.DESEMP"
									label="Nome"
									class="required"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="2">
								<v-text-field
									v-model="company.DESABR"
									label="Abreviação"
									maxlength="10"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn
									color="primary"
									depressed
									tile
									class="mr-4"
									@click="save"
									:disabled="loading"
									>Salvar</v-btn
								>
								<v-btn
									color="primary"
									text
									class="mr-4"
									@click="$router.push('/company/list')"
									:disabled="loading"
									>Voltar</v-btn
								>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import CompanyService from "../../app/Services/CompanyService";
import CompanyGroupService from "../../app/Services/CompanyGroupService";
import PictureUploader from "../picture-uploader/PictureUploader";

const companyService = CompanyService.build();
const companyGroupService = CompanyGroupService.build();

export default {
	name: "CompanyForm",
	data: () => ({
		id: null,
		company: {},
		companyGroup: [],
		loading: false,
		loadingCompany: false,
	}),
	components: {
		PictureUploader: PictureUploader,
	},
	computed: {},
	mounted() {
		const { id } = this.$route.params;

		if (id) {
			this.id = id;
			this.getCompanies();
		}

		this.getCompanyGroups();
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		save() {
			if (!this.company.CODGRP) {
				this.toast("warning", "Informe o grupo da empresa para continuar");
				return;
			}

			// if (!this.company.CNPJ) {
			// 	this.toast(
			// 		"warning",
			// 		"Informe o cnpj da empresa para continuar"
			// 	);
			// 	return;
			// }

			if (!this.company.DESEMP) {
				this.toast("warning", "Informe o nome da empresa para continuar");
				return;
			}

			this.loading = true;

			const rest = this.id
				? companyService.update({ id: this.id, ...this.company })
				: companyService.create(this.company);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast(
					"success",
					this.id
						? "Empresa atualizado com sucesso!"
						: "Empresa adicionado com sucesso!"
				);

				this.loading = false;

				this.$router.push("/company/list");
			});
		},
		getCompanies() {
			this.loading = true;

			return companyService.read({ id: this.id }).then((response) => {
				this.company = response;
				this.loading = false;
			});
		},
		getCompanyGroups() {
			this.loadingCompany = true;

			companyGroupService.search({}).then((response) => {
				this.companyGroup = response;
				this.loadingCompany = false;
			});
		},
	},
};
</script>
<style>
.alignColor {
	vertical-align: bottom;
}
</style>
