<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{
							view
								? "Visualizar Nível"
								: id
								? "Editar Nível"
								: "Cadastrar Nível"
						}}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="chartlevel.CODGRP"
									:items="groups"
									item-text="DESGRP"
									item-value="CODGRP"
									label="Grupo"
									class="required"
									clearable
									:loading="loadingGroup"
									@change="getOrganizationChart(true)"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="chartlevel.GERCOPARE"
									:items="corporateAreas"
									item-text="DESAREA"
									item-value="GERCOPARE"
									label="Área Corporativa"
									:loading="loadingCorporateArea"
									clearable
									@change="
										updateCorporateArea();
										getOrganizationChart(true);
									"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="chartlevel.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									:disabled="disableCompany"
									:loading="loadingCompany"
									clearable
									@change="getUnits(true)"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="chartlevel.CODUNI"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									:disabled="disableUnit"
									:loading="loadingUnit"
									clearable
									@change="
										getAreas(true);
										getOrganizationChart(true);
									"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="chartlevel.CODARE"
									:items="areas"
									item-text="DESARE"
									item-value="CODARE"
									label="Área"
									:disabled="disableArea"
									clearable
									:loading="loadingArea"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="chartlevel.CODORG"
									:items="organizationCharts"
									item-text="DESORG"
									item-value="CODORG"
									label="Organograma"
									class="required"
									no-data-text="Nenhum Organograma"
									clearable
									:loading="loadingOrganizationChart"
								></v-select>
							</v-col>
							<v-col cols="6" md="6">
								<v-text-field
									v-model="chartlevel.DESNVL"
									label="Descrição"
									class="required"
								></v-text-field>
							</v-col>
							<v-col cols="6" md="6">
								<v-select
									v-model="chartlevel.NVLTIP"
									:items="[
										{ value: 'LEVEL', text: 'Nível' },
										{
											value: 'ACCESSORY',
											text: 'Assessoria',
										},
									]"
									@change="handleChangeLevelType"
									item-text="text"
									item-value="value"
									label="Tipo"
									class="required"
									clearable
								></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col
								cols="12"
								md="12"
								v-if="chartlevel.NVLTIP == 'LEVEL'"
							>
								<v-text-field
									v-model="chartlevel.NIVEL"
									label="Nível"
									class="required"
								></v-text-field>
							</v-col>

							<v-col
								cols="12"
								md="12"
								v-if="chartlevel.NVLTIP == 'ACCESSORY'"
							>
								<v-select
									v-model="chartlevel.NVLVIC"
									:items="linkedlevel"
									item-text="DESNVL"
									item-value="CODNVL"
									label="Nível Vinculado"
									class="required"
									clearable
									:loading="loadingLinkedlevel"
								></v-select>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn
									color="primary"
									depressed
									tile
									class="mr-4"
									@click="save"
									:disabled="loading"
									v-show="!view"
									>Salvar</v-btn
								>
								<v-btn
									color="primary"
									text
									class="mr-4"
									@click="
										$router.push(
											'/organization-chart/level/list'
										)
									"
									:disabled="loading"
									>Voltar</v-btn
								>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
		<template>
			<Dialog ref="confirm" />
		</template>
	</v-container>
</template>

<script>
import CompanyGroupService from "../../app/Services/CompanyGroupService";

import Dialog from "../misc/Dialog";
import OrganizationChartService from "../../app/Services/OrganizationChartService";
import OrganizationChartLevelService from "../../app/Services/OrganizationChartLevelService";
import CompanyService from "../../app/Services/CompanyService";
import AreaService from "../../app/Services/AreaService";
import CorporateAreaService from "../../app/Services/CorporateAreaService";
import UnitService from "../../app/Services/UnitService";
import UserService from "../../app/Services/UserService";
import axios from "../../config/service/standard";

const companyGroupService = CompanyGroupService.build();
const organizationChartService = OrganizationChartService.build();
const organizationChartLevelService = OrganizationChartLevelService.build();
const companyService = CompanyService.build();
const areaService = AreaService.build();
const corporateAreaService = CorporateAreaService.build();
const unitService = UnitService.build();
const userService = UserService.build();

export default {
	name: "OrganizationChartLevelForm",
	components: { Dialog },
	data: () => ({
		id: null,
		fileRules: [],
		view: false,
		groups: [],
		companies: [],
		areas: [],
		corporateAreas: [],
		units: [],
		organizationCharts: [],
		linkedlevel: [],
		status: [
			{ id: "A", description: "Aberto" },
			{ id: "F", description: "Fechado" },
		],
		chartlevel: {
			CODNVL: 0,
			CODGRP: 0,
			CODEMP: 0,
			CODUNI: 0,
			CODARE: 0,
			GERCOPARE: 0,
			DESNVL: "",
			NIVEL: 1,
			CREATED_AT: "",
			UPDATED_AT: "",
		},
		loadingGroup: false,
		loading: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingArea: false,
		loadingCorporateArea: false,
		loadingOrganizationChart: false,
		disableCompany: false,
		disableUnit: false,
		disableArea: false,
		loadingLinkedlevel: false,
		fileBase64: "",
	}),
	mounted() {
		const { id, view } = this.$route.params;

		if (id) {
			this.id = id;
		}

		if (view) {
			this.view = view;
		}

		this.fetch();
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		save() {
			if (!this.chartlevel.CODGRP) {
				this.toast("warning", "Informe o grupo para continuar");
				return;
			}

			if (!this.chartlevel.DESNVL) {
				this.toast("warning", "Informe o descrição para continuar");
				return;
			}

			if (!this.chartlevel.NVLTIP) {
				this.toast("warning", "Informe o tipo para continuar");
				return;
			}

			if (!this.chartlevel.CODORG) {
				this.toast("warning", "Informe o organograma para continuar");
				return;
			}

			if (this.chartlevel.NVLTIP == "LEVEL") {
				if (!this.chartlevel.NIVEL) {
					this.toast("warning", "Informe o nível para continuar");
					return;
				}

				if (this.chartlevel.NIVEL < 1) {
					this.toast(
						"warning",
						"Informe o nível maior que 0 para continuar"
					);
					return;
				}
			}

			if (this.chartlevel.NVLTIP == "ACCESSORY") {
        if (!this.chartlevel.NVLVIC) {
					this.toast("warning", "Informe o nível vinculado para continuar");
					return;
				}
			}

			this.loading = true;
			this.chartlevel.CODGRP = this.chartlevel.CODGRP || 0;
			this.chartlevel.CODEMP = this.chartlevel.CODEMP || 0;
			this.chartlevel.CODUNI = this.chartlevel.CODUNI || 0;
			this.chartlevel.CODARE = this.chartlevel.CODARE || 0;
			this.chartlevel.GERCOPARE = this.chartlevel.GERCOPARE || 0;

			const rest = this.id
				? organizationChartLevelService.update({
						id: this.id,
						...this.chartlevel,
				})
				: organizationChartLevelService.create({
						...this.chartlevel,
				});

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast(
					"success",
					this.id
						? "Nível atualizada com sucesso!"
						: "Nível adicionada com sucesso!"
				);
				this.loading = false;

				if (!this.isInModal) {
					this.$router.push("/organization-chart/level/list");
				} else {
					this.$emit("saved", true);
				}
			});
		},
		async getOrganizationChartLevel() {
			this.loading = true;

			return organizationChartLevelService
				.read({ id: this.id })
				.then((response) => {
					this.chartlevel = response;
					this.loading = false;
				});
		},
		getGroup() {
			this.loadingGroup = true;

			companyGroupService.search("").then((response) => {
				this.groups = response;
				this.loadingGroup = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;
			});
		},
		getUnits(change) {
			const search = {};

			this.units = [];
			this.areas = [];
			this.loadingUnit = true;

			if (change) {
				this.chartlevel.CODUNI = "";
				this.chartlevel.CODARE = "";
			}

			if (this.chartlevel.CODEMP) {
				search.company = this.chartlevel.CODEMP;
			}

			return unitService.search(search).then((response) => {
				this.units = response;
				this.loadingUnit = false;
			});
		},
		getAreas(change) {
			const search = {};

			this.areas = [];
			this.loadingArea = true;

			if (change) {
				this.chartlevel.CODARE = "";
			}

			if (this.chartlevel.CODEMP) {
				search.company = this.chartlevel.CODEMP;
			}

			if (this.chartlevel.CODUNI) {
				search.unit = this.chartlevel.CODUNI;
			}

			areaService.search(search).then((response) => {
				this.areas = response;
				this.loadingArea = false;
			});
		},
		getCorporateArea(change) {
			const search = {};

			this.corporateAreas = [];
			this.loadingCorporateArea = true;

			if (change) {
				this.chartlevel.CODARE = "";
			}

			if (this.chartlevel.CODEMP) {
				search.company = this.chartlevel.CODEMP;
			}

			if (this.chartlevel.CODUNI) {
				search.unit = this.chartlevel.CODUNI;
			}

			corporateAreaService.search(search).then((response) => {
				this.corporateAreas = response;
				this.loadingCorporateArea = false;
			});
		},
		getOrganizationChart(change) {
			const search = {};

			this.organizationCharts = [];
			this.loadingOrganizationChart = true;

			if (this.chartlevel.GERCOPARE) {
				search.corporateArea = this.chartlevel.GERCOPARE;
			} else {
				if (change) {
					this.chartlevel.CODNVL = "";
				}

				if (this.chartlevel.CODGRP) {
					search.company = this.chartlevel.CODGRP;
				}

				if (this.chartlevel.CODEMP) {
					search.company = this.chartlevel.CODEMP;
				}

				if (this.chartlevel.CODUNI) {
					search.unit = this.chartlevel.CODUNI;
				}
			}

			if (search) {
				organizationChartService.search(search).then((response) => {
					this.organizationCharts = response.data;
					this.loadingOrganizationChart = false;
				});
			}
		},
		async getChartsLevel() {
			const search = {};

			this.organizationCharts = [];
			this.loadingLinkedlevel = true;

			if (this.chartlevel.GERCOPARE) {
				search.corporateArea = this.chartlevel.GERCOPARE;
			} else {
				if (this.chartlevel.CODGRP) {
					search.company = this.chartlevel.CODGRP;
				}

				if (this.chartlevel.CODEMP) {
					search.company = this.chartlevel.CODEMP;
				}

				if (this.chartlevel.CODUNI) {
					search.unit = this.chartlevel.CODUNI;
				}
			}

			if (search) {
				const query = {
					params: {
						search
					}
				};
				const response =
					await OrganizationChartLevelService.build().index({
						query,
					});

        this.linkedlevel = response.data;
				this.loadingLinkedlevel = false;
			}
		},
		getUsers() {
			this.loadingUser = true;
			return userService.usersByKey().then((response) => {
				this.users = response;
				this.loadingUser = false;
			});
		},
		updateCorporateArea() {
			if (parseInt(this.chartlevel.GERCOPARE) > 0) {
				this.chartlevel.CODEMP = 0;
				this.chartlevel.CODUNI = 0;
				this.chartlevel.CODARE = 0;

				this.disableCompany = true;
				this.disableUnit = true;
				this.disableArea = true;
			} else {
				this.disableCompany = false;
				this.disableUnit = false;
				this.disableArea = false;
			}
		},
		fetch() {
			const funcs = [];

			if (this.id) {
				funcs.push(this.getOrganizationChartLevel());
			}

			funcs.push(this.getGroup());
			funcs.push(this.getCompanies());
			funcs.push(this.getUsers());
			funcs.push(this.getCorporateArea());
			funcs.push(this.getOrganizationChart());

			Promise.all(funcs)
				.then(() => {
					if (this.id) {
						this.getUnits(false);
						this.getAreas();
						this.updateCorporateArea();
						this.getOrganizationChart();

						if (this.chartlevel.NVLTIP == "ACCESSORY") {
              this.getChartsLevel();
            }
					}
				})
				.finally(() => {});
		},
		handleChangeLevelType() {
			if (this.chartlevel.NVLTIP == "ACCESSORY") this.getChartsLevel();
		},
		onFileSelect(fileTmp) {
			if (fileTmp == null) return;

			this.chartlevel.FILE_NAME = fileTmp.name;

			this.toBase64(fileTmp).then((base64) => {
				this.fileBase64 = base64;
			});
		},
		toBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
				reader.readAsDataURL(file);
			});
		},
		download() {
			this.$root.$Loading.show("Fazendo download...");

			axios
				.get("/api/process/download", {
					responseType: "arraybuffer",
					params: {
						id: this.chartlevel.GERPRO,
					},
				})
				.then((response) => {
					let blob = new Blob([response.data], {
						type: "application/pdf",
					});
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = this.chartlevel.TITPRO;
					link.click();
				})
				.finally(() => {
					this.$root.$Loading.hide();
				});
		},
		async removeDownload() {
			await this.$refs.confirm
				.open("Atenção", "Deseja mesmo excluir?")
				.then(() => {
					this.$root.$Loading.show("Removendo arquivo...");

					axios
						.post("/api/process/removeDownload", {
							id: this.chartlevel.GERPRO,
						})
						.then(() => {
							this.toast(
								"success",
								"Arquivo removido com sucesso!"
							);
							this.chartlevel.FILE = null;
						})
						.finally(() => {
							this.$root.$Loading.hide();
						});
				});
		},
	},
};
</script>
