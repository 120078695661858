import Rest from '../../app/Base/Rest';

/**
 * @typedef {TypeService}
 */
export default class TypeService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/type';
}
