<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<v-card flat :loading="loading">
					<v-card-title> Consulta de desempenho </v-card-title>

					<v-card-text>
						Selecione o planejamento estratégico para continuar...

						<v-row class="mt-5">
							<v-col cols="12" md="3">
								<v-select
									class="required"
									v-model="filter.companyGroup"
									:items="companyGroups"
									item-text="DESGRP"
									item-value="CODGRP"
									label="Grupo de empresa"
									:loading="loadingCompanyGroup"
									hide-details
									@change="getCompanies"
								></v-select>
							</v-col>
							<v-col cols="12" md="3">
								<v-select
									class="required"
									v-model="filter.strategicPlanning"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									label="Planejamento estrategico"
									:loading="loadingPlanning"
									hide-details
								></v-select>
							</v-col>
							<v-col cols="12" md="3">
								<v-select
									v-model="filter.company"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									:loading="loadingCompany"
									@change="getUnits"
									clearable
								></v-select>
							</v-col>
							<v-col cols="12" md="3">
								<v-select
									v-model="filter.unit"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									:loading="loadingUnit"
									clearable
								></v-select>
							</v-col>

							<v-col cols="12" md="3">
								<v-btn
									class="white--text mt-3"
									depressed
									color="info"
									@click="getIndicators()"
									:loading="loading"
									>Buscar</v-btn
								>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row cols="12" v-if="itensCalculed.length > 0">
			<v-col cols="12">
				<v-row cols="12">
					<v-col cols="12">
						<v-card
							elevation="0"
							shaped
							:style="getColor(numeral(percentGeneral).value())"
						>
							<v-col cols="12" class="text-center">
								<div style="padding-top: 20px; padding-bottom: 20px">
									<h4 class="font-card">Geral</h4>
									<h1>{{ percentGeneral + " %" }}</h1>
								</div>
							</v-col>
						</v-card>
					</v-col>
				</v-row>

				<v-row cols="12" v-for="(perspective, idx) of itensCalculed" :key="idx">
					<v-col v-if="perspective.aggroup" cols="12" md="12">
						<v-card flat>
							<v-card-title>
								{{ perspective.aggroup }}
							</v-card-title>
						</v-card>
					</v-col>

					<v-col cols="12" md="2">
						<v-row cols="12">
							<v-col cols="12">
								<v-card elevation="0" shaped :style="perspective.color">
									<v-col cols="12" class="text-center">
										<div style="padding-top: 20px; padding-bottom: 20px">
											<h4
												class="font-card"
												style="padding-top: 4px; padding-bottom: 4px"
											>
												{{ perspective.description }}
											</h4>
											<h1>
												{{ perspective.percent + " %" }}
											</h1>
										</div>
									</v-col>
								</v-card>
							</v-col>
						</v-row>
					</v-col>

					<v-col cols="12" md="10">
						<v-row cols="12">
							<v-col
								cols="12"
								md="3"
								v-for="(objective, idx) of perspective.objectives"
								:key="idx"
							>
								<v-card elevation="0" shaped :style="objective.color">
									<v-col cols="12" class="text-center">
										<h4
											class="font-card"
											style="padding-top: 4px; padding-bottom: 4px"
										>
											{{ objective.description }}
										</h4>
										<h1>{{ objective.percent + " %" }}</h1>
									</v-col>
								</v-card>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-row v-if="itens.length > 0">
			<v-col cols="12">
				<v-card flat>
					<v-card-text>
						<div>
							<v-row>
								<v-col cols="12"> Legenda </v-col>
							</v-row>
							<v-row>
								<v-col cols="12" class="mr-5 ml-5">
									<v-card flat>
										<v-row>
											<v-col cols="12" md="3" class="text-center">
												{{
													numeral(
														parseFloat(rangeIndicator.VERMELHO_DE)
													).format("0,0.00") +
													" até " +
													numeral(
														parseFloat(rangeIndicator.VERMELHO_ATE)
													).format("0,0.00")
												}}
											</v-col>
											<v-col cols="12" md="3" class="text-center">
												{{
													numeral(parseFloat(rangeIndicator.LARANJA_DE)).format(
														"0,0.00"
													) +
													" até " +
													numeral(
														parseFloat(rangeIndicator.LARANJA_ATE)
													).format("0,0.00")
												}}
											</v-col>
											<v-col cols="12" md="3" class="text-center">
												{{
													numeral(parseFloat(rangeIndicator.AMARELO_DE)).format(
														"0,0.00"
													) +
													" até " +
													numeral(
														parseFloat(rangeIndicator.AMARELO_ATE)
													).format("0,0.00")
												}}
											</v-col>
											<v-col cols="12" md="3" class="text-center">
												{{
													numeral(parseFloat(rangeIndicator.VERDE_DE)).format(
														"0,0.00"
													) +
													" até " +
													numeral(parseFloat(rangeIndicator.VERDE_ATE)).format(
														"0,0.00"
													)
												}}
											</v-col>
										</v-row>
										<v-row>
											<v-col
												cols="12"
												md="3"
												class="text-center"
												style="background-color: red"
												>&nbsp;</v-col
											>
											<v-col
												cols="12"
												md="3"
												class="text-center"
												style="background-color: orange"
												>&nbsp;</v-col
											>
											<v-col
												cols="12"
												md="3"
												class="text-center"
												style="background-color: yellow"
												>&nbsp;</v-col
											>
											<v-col
												cols="12"
												md="3"
												class="text-center"
												style="background-color: #39d839"
												>&nbsp;</v-col
											>
										</v-row>
									</v-card>
								</v-col>
							</v-row>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import RangeIndicatorService from "../../app/Services/RangeIndicatorService";
import PerspectiveService from "../../app/Services/PerspectiveService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import CompanyGroupService from "../../app/Services/CompanyGroupService";

const rangeIndicatorService = RangeIndicatorService.build();
const perspectiveService = PerspectiveService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();
const strategicPlanningService = StrategicPlanningService.build();
const companyGroupService = CompanyGroupService.build();

export default {
	name: "InquiryPerformance",
	data: () => ({
		loading: false,
		loadingCompanyGroup: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingPlanning: false,
		registerIndicator: false,
		rangeIndicator: {},
		strategicPlannings: [],
		companies: [],
		units: [],
		itens: [],
		resultGeral: 0,
		resultGeralPeso: 0,
		itensFilter: {},
		companyGroups: [],
		filter: {
			company: "",
			unit: "",
			strategicPlanning: "",
		},
		defaultValues: {},
	}),
	mounted() {
		this.defaultValues = JSON.parse(localStorage.getItem("default_filters"));
		this.getCompanyGroups();
		this.getStrategicPlannings();
	},
	computed: {
		itensCalculed() {
			const itens = [];
			let color = "";
			let auxAggroup = "";

			for (const perspective of this.itens) {
				const objectives = [];
				let resultObjective = 0;
				let aggroup = "";
				let qtdPesoPerspective = 0;

				for (const objective of perspective.objective) {
					let result = 0;
					let objectivePercent = 0;
					let qtdPeso = 0;

					for (const indicator of objective.indicator) {
						if (indicator.STATUS !== "I") {
							for (const cadIndicator of indicator.indicator) {
								// result += parseFloat(cadIndicator.VALREA);
								qtdPeso += parseInt(indicator.NIVEL);
								qtdPesoPerspective += parseInt(indicator.NIVEL);
								this.setResultGeralPeso(indicator.NIVEL);

								if (cadIndicator.CRITER == "1") {
									let calcTmp =
										(((parseFloat(cadIndicator.VALREA) -
											parseFloat(cadIndicator.VALPRJ)) /
											parseFloat(cadIndicator.VALPRJ)) *
											100 +
											100) *
										indicator.NIVEL;

									if (calcTmp && isFinite(calcTmp)) {
										result += parseFloat(calcTmp);
									}

									// totalVlrEstrateg += (result * indicator.nivel);
								}

								if (cadIndicator.CRITER == "2") {
									let calcTmp =
										(((parseFloat(cadIndicator.VALPRJ) -
											parseFloat(cadIndicator.VALREA)) /
											parseFloat(cadIndicator.VALPRJ)) *
											100 +
											100) *
										indicator.NIVEL;

									if (calcTmp && isFinite(calcTmp)) {
										result += parseFloat(calcTmp);
									}
								}

								if (parseFloat(cadIndicator.VALREA) == 0) {
									result += 0;
								}
							}
						}
					}

					if (qtdPeso > 0) {
						let calcTmp = result / qtdPeso;
						if (calcTmp) {
							objectivePercent += calcTmp;
						}
					}

					color = this.getColor(objectivePercent);

					objectives.push({
						description: objective.DESOBJ,
						color: color,
						percent: this.numeral(objectivePercent).format("0,0.00"),
					});

					let resultTot = result;

					resultObjective += resultTot || 0;
				}

				const perspectivePercent = resultObjective / qtdPesoPerspective;

				this.setResultGeral(resultObjective);

				color = this.getColor(perspectivePercent);

				const companyDesc = perspective.company.DESEMP;
				const unitDesc = perspective.unit.DESUNI;

				if (!this.itensFilter.company && !this.itensFilter.unit) {
					aggroup = `${companyDesc} > ${unitDesc}`;
				}

				if (this.itensFilter.company && !this.itensFilter.unit) {
					aggroup = `${unitDesc}`;
				}

				itens.push({
					aggroup: aggroup !== auxAggroup ? aggroup : "",
					company: companyDesc,
					unit: unitDesc,
					description: perspective.DESPER,
					color: color,
					percent: this.numeral(perspectivePercent).format("0,0.00"),
					objectives: objectives,
				});

				auxAggroup = aggroup;
			}

			return itens;
		},
		percentGeneral() {
			return this.numeral(this.getResultTotal()).format("0,0.00");
		},
	},
	methods: {
		setResultGeral(vlr) {
			this.resultGeral += vlr;
		},
		setResultGeralPeso(vlr) {
			this.resultGeralPeso += vlr || 0;
		},
		getResultTotal() {
			return this.resultGeral / this.resultGeralPeso || 0;
		},
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		getCompanyGroups() {
			this.loadingCompanyGroup = true;

			companyGroupService.search({}).then((response) => {
				this.companyGroups = response;

				if (this.companyGroups.length == 1 || this.defaultValues.CODGRP) {
					this.filter.companyGroup =
						this.defaultValues.CODGRP ?? this.companyGroups[0].CODGRP;
					this.getCompanies();

					this.checkAndSearch();
				}

				this.loadingCompanyGroup = false;
			});
		},
		getCompanies() {
			const search = {};

			this.filter.company = "";
			this.companies = [];
			this.filter.unit = "";
			this.units = [];
			this.loadingCompany = true;

			if (this.filter.companyGroup) {
				search.companyGroup = this.filter.companyGroup;
			}

			return companyService.search(search).then((response) => {
				this.companies = response;

				if (this.companies.length == 1 || this.defaultValues.CODEMP) {
					this.filter.company =
						this.defaultValues.CODEMP ?? this.companies[0].CODEMP;
					this.getUnits();

					this.checkAndSearch();
				}

				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.filter.unit = "";
			this.units = [];
			this.loadingUnit = true;

			if (this.filter.company) {
				search.company = this.filter.company;
			}

			return unitService.search(search).then((response) => {
				this.units = response;

				if (this.units.length == 1 || this.defaultValues.CODUNI) {
					this.filter.unit = this.defaultValues.CODUNI ?? this.units[0].CODUNI;

					this.checkAndSearch();
				}

				this.loadingUnit = false;
			});
		},
		getColor(value) {
			let color = "";

			if (!this.rangeIndicator) {
				return color;
			}

			if (
				value >= parseFloat(this.rangeIndicator.VERDE_DE) &&
				value <= parseFloat(this.rangeIndicator.VERDE_ATE)
			) {
				color = "background-color: #39d839;";
			}

			if (
				value >= parseFloat(this.rangeIndicator.AMARELO_DE) &&
				value <= parseFloat(this.rangeIndicator.AMARELO_ATE)
			) {
				color = "background-color: yellow;";
			}

			if (
				value >= parseFloat(this.rangeIndicator.LARANJA_DE) &&
				value <= parseFloat(this.rangeIndicator.LARANJA_ATE)
			) {
				color = "background-color: orange;";
			}

			if (
				value >= parseFloat(this.rangeIndicator.VERMELHO_DE) &&
				value <= parseFloat(this.rangeIndicator.VERMELHO_ATE)
			) {
				color = "background-color: red;";
			}

			if (value < 0) {
				color = "background-color: red;";
			}

			return color;
		},
		getIndicators() {
			if (!this.filter.companyGroup) {
				this.toast("warning", "Informe o grupo de empresas para continuar");
				return;
			}

			if (!this.filter.strategicPlanning) {
				this.toast(
					"warning",
					"Informe o planejamento estrategico para continuar"
				);
				return;
			}

			// if (!this.filter.company) {
			// 	this.toast(
			// 		"warning",
			// 		"Informe a empresa para continuar"
			// 	);
			// 	return;
			// }

			// if (!this.filter.unit) {
			// 	this.toast(
			// 		"warning",
			// 		"Informe a unidade para continuar"
			// 	);
			// 	return;
			// }

			this.loading = true;

			this.filter.company = this.filter.company ? this.filter.company : "";
			this.filter.unit = this.filter.unit ? this.filter.unit : "";

			Promise.all([
				perspectiveService.search(this.filter),
				rangeIndicatorService.search(this.filter),
			]).then((response) => {
				if (response[0]) {
					this.itens = response[0].data;
					this.itensFilter = response[0].filters;
				}

				if (response[1]) {
					if (response[1].length > 0) {
						this.rangeIndicator = response[1][0];
					}
				}

				this.loading = false;
			});
		},
		getStrategicPlannings() {
			this.resultGeral = 0;
			this.resultGeralPeso = 0;

			this.loadingPlanning = true;
			strategicPlanningService.search({}).then((response) => {
				this.strategicPlannings = response;

				if (this.strategicPlannings.length == 1 || this.defaultValues.CODPLE) {
					this.filter.strategicPlanning =
						this.defaultValues.CODPLE ?? this.strategicPlannings[0].CODPLE;

					this.checkAndSearch();
				}

				this.loadingPlanning = false;
			});
		},
		checkAndSearch() {
			if (
				this.filter.company &&
				this.filter.unit &&
				this.filter.strategicPlanning &&
				this.filter.companyGroup
			) {
				this.getIndicators();
			}
		},
	},
};
</script>

<style>
.aggroup {
	background-color: #eaeaea;
}

.aggroup > td {
	font-size: 0.805rem;
	height: 40px;
}

.aggroupLine > td {
	font-size: 0.755rem;
	height: 40px;
}

.borderRight {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}

.colDialog {
	padding-top: 0px;
	padding-bottom: 0px;
}

.divToClick {
	cursor: pointer;
	padding-top: 10px;
	padding-bottom: 10px;
}

.font-card {
	font-weight: normal;
	font-size: 13px;
}
</style>
