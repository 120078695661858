import Rest from "../../app/Base/Rest";

/**
 * @typedef {PlanActionTaskService}
 */
export default class PlanActionTaskService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = "/planactiontask";

	createArrayTasks(param) {
		return this.post(`/new-all/${param.codpla}`, param.tasks);
	}

	getArrayTasks(codpla, tarsit = '') {
		return this.get(`/plan/${codpla}/${tarsit}`);
	}
}
