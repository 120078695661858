<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-4" flat>
          <v-card-title>Cadastrar termo de utilização</v-card-title>
          <v-col class="pl-10 pb-5" cols="10" md="10">
            <v-row>
              <v-col cols="12" md="12">
                <v-switch v-model="term.STATUS" :true-value="1" :false-value="null" label="Ativo"></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea auto-grow counter label="Termo" v-model="term.DESTER"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-container>
                <v-btn
                  :loading="loading"
                  color="primary"
                  depressed
                  tile
                  class="mr-4"
                  @click="save"
                >Salvar</v-btn>
                <v-btn color="primary" text class="mr-4" @click="$router.push('/term/list')">Voltar</v-btn>
              </v-container>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TermService from '../../app/Services/TermService';

const termService = TermService.build();

export default {
	name: 'TermEdit',
	data: () => ({
		term: {
			STATUS: '',
			DESTER: '',
		},
		loading: false,
	}),
	mounted() {
		this.getTerm();
	},
	methods: {
		getTerm() {
			const { id } = this.$route.params;

			return termService.read({ id }).then((term) => {
				this.term = term;
			});
		},
		save() {
			if (!this.term.DESTER) {
				this.$toasted.info('É necessário informar a descrição do termo para continuar!', {
					icon: 'mdi-information',
				});
				return;
			}

			this.loading = true;

			return termService
				.update({
					id: this.$route.params.id,
					STATUS: this.term.STATUS,
					DESTER: this.term.DESTER,
				})
				.then(() => {
					this.$toasted.success('Termo atualizado com sucesso!', {
						icon: 'mdi-check',
					});

					this.$router.push('/term/list');
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
