export default {
  data() {
    return {
      sortable_options: {}
    };
  },
  methods: {
    orderBy() {
      const { sortBy, sortDesc } = this.sortable_options;

      if (!sortBy || !sortBy.length || !sortDesc || !sortDesc.length) {
        return {};
      }

      const orderBy = [
        {
          column: sortBy[0],
          order: sortDesc[0] ? "desc" : "asc"
        }
      ];

      return orderBy;
    },
    sortRequest(requestOptions) {
      this.sortable_options = { ...requestOptions };

      if (this.loadData) {
        this.loadData();
      }
    }
  }
};
