<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title v-if="!isInModal">
						<v-col cols="10">
							{{
								view
									? "Visualizar plano de ação"
									: id
									? "Editar plano de ação"
									: "Cadastrar plano de ação"
							}}
						</v-col>
						<v-col md="2" sm="12">
							<v-btn
								style="float: right;"
								depressed
								color="primary"
								@click="handleClickOpenModalPlanActionTasks"
							>
								<v-icon
									dark
									left
								>
								mdi-calendar-check-outline
								</v-icon>
								Tarefas
							</v-btn>
						</v-col>
					</v-card-title>

					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="planAction.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									class="required"
									:loading="loadingCompany"
									@change="
										getUnits(true);
										getDiagnostic();
									"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="planAction.CODUNI"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									class="required"
									:loading="loadingUnit"
									@change="
										getObjectiveAndArea(true);
										getDiagnostic();
									"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="planAction.CODPLE"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									class="required"
									label="Planejamento estratégico"
									:loading="loadingPlanning"
									@change="
										getIndicator(true);
										getDiagnostic();
									"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="12">
								<v-text-field
									v-model="planAction.DESPLA"
									label="Descrição"
									class="required"
									:disabled="view"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="planAction.CODARE"
									:items="areas"
									item-text="DESARE"
									item-value="CODARE"
									label="Area"
									class="required"
									:loading="loadingArea"
									:disabled="view"
								></v-select>
							</v-col>
							<!-- <v-col cols="12" md="4">
								<v-select
									v-model="planAction.CODOBJ"
									:items="objectives"
									item-text="DESOBJ"
									item-value="CODOBJ"
									label="Objetivo"
									class="required"
									:loading="loadingObjective"
									:disabled="view"
								></v-select>
							</v-col> -->
							<v-col cols="12" md="4">
								<v-select
									v-model="planAction.CODDIA"
									:items="diagnostics"
									item-text="DESDIA"
									item-value="CODDIA"
									label="Diagnósticos"
									:loading="loadingDiagnostic"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="3">
								<v-menu
									ref="menuDtPicker"
									v-model="menuDtPicker"
									:close-on-content-click="false"
									:return-value.sync="planAction.QNDINI"
									transition="scale-transition"
									offset-y
									min-width="290px"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="dateFormatedIni"
											label="Previsão de inicio"
											readonly
											v-bind="attrs"
											v-on="on"
											:disabled="view"
										/>
									</template>
									<v-date-picker
										v-model="planAction.QNDINI"
										locale="pt-br"
										@input="
											$refs.menuDtPicker.save(
												planAction.QNDINI
											)
										"
										no-title
										scrollable
									>
										<v-spacer></v-spacer>
										<v-btn
											text
											color="primary"
											@click="menuDtPicker = false"
											>Cancel</v-btn
										>
										<v-btn
											text
											color="primary"
											@click="
												$refs.menuDtPicker.save(
													planAction.QNDINI
												)
											"
											>OK</v-btn
										>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12" md="3">
								<v-menu
									ref="menuDtPicker2"
									v-model="menuDtPicker2"
									:close-on-content-click="false"
									:return-value.sync="planAction.QNDFIN"
									transition="scale-transition"
									offset-y
									min-width="290px"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="dateFormatedFin"
											label="Previsão de término"
											readonly
											v-bind="attrs"
											v-on="on"
											:disabled="view"
										/>
									</template>
									<v-date-picker
										v-model="planAction.QNDFIN"
										@input="
											$refs.menuDtPicker2.save(
												planAction.QNDFIN
											)
										"
										locale="pt-br"
										no-title
										scrollable
									>
										<v-spacer></v-spacer>
										<v-btn
											text
											color="primary"
											@click="menuDtPicker2 = false"
											>Cancel</v-btn
										>
										<v-btn
											text
											color="primary"
											@click="
												$refs.menuDtPicker2.save(
													planAction.QNDFIN
												)
											"
											>OK</v-btn
										>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12" md="3">
								<v-text-field
									v-model="planAction.ONDFEI"
									label="Onde será feito"
									:disabled="view"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="3">
								<v-currency-field
									v-if="!view"
									prefix=""
									v-model="planAction.QUNCST"
									label="Quanto irá custar"
								></v-currency-field>
								<v-text-field
									v-else
									v-model="planAction.QUNCST"
									label="Quanto irá custar"
									:disabled="view"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-textarea
									v-model="planAction.OQUFAZ"
									label="Oque será feito"
									:disabled="view"
								></v-textarea>
							</v-col>
							<v-col cols="12" md="4">
								<v-textarea
									v-model="planAction.PORFEI"
									label="Porque será feito"
									:disabled="view"
								></v-textarea>
							</v-col>
							<v-col cols="12" md="4">
								<v-textarea
									v-model="planAction.COMFEI"
									label="Como será feito"
									:disabled="view"
								></v-textarea>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="planAction.QUEFAZ"
									:items="users"
									class="required"
									item-text="NOMUSU"
									item-value="CODUSU"
									label="Quem fará"
									:loading="loadingUser"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="planAction.STATUS"
									:items="status"
									item-text="description"
									item-value="id"
									label="Status"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-currency-field
									v-if="!view"
									prefix=""
									v-model="planAction.PEREXE"
									label="% Executado"
								></v-currency-field>
								<v-text-field
									v-else
									v-model="planAction.PEREXE"
									label="% Executado"
									:disabled="view"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="planAction.CODIND"
									:items="indicators"
									item-text="DESIND"
									item-value="CODIND"
									label="Indicador"
									:loading="loadingIndicator"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<InvolvedSelect
									ref="involved"
									v-model="involved"
									v-bind:vempresa.sync="planAction.CODEMP"
									v-bind:vunidade.sync="planAction.CODUNI"
								/>
							</v-col>
							<v-col cols="12" md="4">
								<v-file-input
									id="fileUploader"
									type="file"
									:rules="fileRules"
									accept="application/pdf"
									placeholder="Clique aqui para selecionar um arquivo"
									prepend-icon="mdi-paperclip"
									show-size
									label="Arquivo"
								></v-file-input>
								<v-btn
									v-if="planAction.FILE"
									color="primary"
									@click="download()"
									>Download</v-btn
								>
								<v-btn
									v-if="planAction.FILE"
									style="float: right"
									color="secondary"
									@click="RemoveDownload()"
									>Remover</v-btn
								>
							</v-col>
						</v-row>

						<!-- <v-row>
							<v-col cols="12" md="12">
								<v-combobox
									chips
									multiple
									append-icon="mdi-plus"
									@click:append="
										handleClickOpenModalPlanActionTasks
									"
									v-model="planAction.tasks"
									label="Tarefas"
									readonly
								>
									<template
										v-slot:selection="{
											attrs,
											item,
											select,
											selected,
										}"
									>
										<v-chip
											small
											close
											close-icon="mdi-pencil"
											v-bind="attrs"
											:input-value="selected"
											@click="select"
											@click:close="
												handleClickEditTask(item)
											"
										>
											{{ item.TARTIT }}
										</v-chip>
									</template>
								</v-combobox>
							</v-col>
						</v-row> -->

						<v-row class="mt-5" v-if="!isInModal">
							<v-container>
								<v-btn
									color="primary"
									depressed
									tile
									class="mr-4"
									@click="save"
									:disabled="loading"
									v-show="!view"
									>Salvar</v-btn
								>
								<v-btn
									color="primary"
									text
									class="mr-4"
									@click="$router.push('/plan-action/list')"
									:disabled="loading"
									>Voltar</v-btn
								>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>

		<modalFormPlanActionTask
			:form="formPlanActionTask"
			:dialog="toggleModalTask"
			:handleCloseModalTask="handleCloseModalTask"
			:handleSaveFormModalTask="handleSaveFormModalTask"
			:handleDeleteModalTask="handleDeleteModalTask"
			:tasks="planAction.tasks"
			:handleClickEditTask="handleClickEditTask"
		/>
	</v-container>
</template>

<script>
import PlanActionService from "../../app/Services/PlanActionService";
import PlanActionTaskService from "../../app/Services/PlanActionTaskService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import CompanyService from "../../app/Services/CompanyService";
import AreaService from "../../app/Services/AreaService";
import UnitService from "../../app/Services/UnitService";
import ObjectiveService from "../../app/Services/ObjectiveService";
import UserService from "../../app/Services/UserService";
import IndicatorService from "../../app/Services/IndicatorService";
import InvolvedSelect from "./InvolvedSelect.vue";
import moment from "moment";
import DiagnosticService from "../../app/Services/DiagnosticService";
import modalFormPlanActionTask from "./components/modalFormPlanActionTask.vue";

const planActionService = PlanActionService.build();
const planActionTaskService = PlanActionTaskService.build();
const strategicPlanningService = StrategicPlanningService.build();
const companyService = CompanyService.build();
const areaService = AreaService.build();
const unitService = UnitService.build();
const objectiveService = ObjectiveService.build();
const userService = UserService.build();
const indicatorService = IndicatorService.build();
const diagnosticService = DiagnosticService.build();

export default {
	name: "PlanActionForm",
	components: { InvolvedSelect, modalFormPlanActionTask },
	data: () => ({
		id: null,
		view: false,
		menuDtPicker: false,
		menuDtPicker2: false,
		isInModal: false,
		fileRules: [],
		strategicPlannings: [],
		companies: [],
		areas: [],
		units: [],
		objectives: [],
		indicators: [],
		involved: [],
		users: [],
		diagnostics: [],
		status: [
			{ id: "A", description: "Aberto" },
			{ id: "F", description: "Fechado" },
		],
		planAction: {
			CODEMP: 0,
			CODUNI: 0,
			STATUS: "A",
			tasks: [],
		},
		loading: false,
		loadingPlanning: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingArea: false,
		loadingObjective: false,
		loadingIndicator: false,
		loadingUser: false,
		loadingDiagnostic: false,
		fileBase64: {},
		toggleModalTask: false,
		formPlanActionTask: {},
	}),
	mounted() {
		const { id, view } = this.$route.params;

		if (id) {
			this.id = id;
		}

		if (view) {
			this.view = view;
		}

		this.fetch();
	},
	computed: {
		dateFormatedIni() {
			return this.planAction.QNDINI
				? moment(this.planAction.QNDINI).format("DD/MM/YYYY")
				: "";
		},
		dateFormatedFin() {
			return this.planAction.QNDFIN
				? moment(this.planAction.QNDFIN).format("DD/MM/YYYY")
				: "";
		},
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		save() {
			if (!this.planAction.DESPLA) {
				this.toast(
					"warning",
					"Informe a descrição do plano de ação para continuar"
				);
				return;
			}

			if (!this.planAction.CODEMP) {
				this.toast(
					"warning",
					"Informe a empresa do plano de ação para continuar"
				);
				return;
			}

			if (!this.planAction.CODUNI) {
				this.toast(
					"warning",
					"Informe a unidade do plano de ação para continuar"
				);
				return;
			}

			if (!this.planAction.CODPLE) {
				this.toast(
					"warning",
					"Informe o planejamento estrategico do plano de ação para continuar"
				);
				return;
			}

			if (!this.planAction.CODARE) {
				this.toast(
					"warning",
					"Informe a area do plano de ação para continuar"
				);
				return;
			}

			// if (!this.planAction.CODOBJ) {
			// 	this.toast(
			// 		"warning",
			// 		"Informe o objetivo do plano de ação para continuar"
			// 	);
			// 	return;
			// }

			if (!this.planAction.QUEFAZ) {
				this.toast(
					"warning",
					"Informe quem fará o plano de ação para continuar"
				);
				return;
			}

			this.loading = true;
			if (this.isInModal) {
				this.$emit("onsave", true);
			}

			this.planAction["involved"] = this.involved;

			const rest = this.id
				? planActionService.update({ id: this.id, ...this.planAction })
				: planActionService.create({ ...this.planAction });

			rest.then((response) => {
				if (!response) {
					return;
				}

				planActionTaskService
					.createArrayTasks({
						tasks: this.planAction.tasks,
						codpla: response.CODPLA,
					})
					.then(() => {
						this.toast(
							"success",
							this.id
								? "Plano e ação atualizada com sucesso!"
								: "Plano de ação adicionada com sucesso!"
						);
						this.loading = false;

						if (!this.isInModal) {
							this.$router.push("/plan-action/list");
						} else {
							this.$emit("saved", true);
						}
					});
			});
		},
		getPlanAction() {
			this.loading = true;

			return planActionService.read({ id: this.id }).then((response) => {
				this.planAction = response;

				this.planAction.QUNCST = parseFloat(response.QUNCST);
				this.planAction.PEREXE = parseFloat(response.PEREXE);

				if (
					this.planAction.involved &&
					this.planAction.involved.length > 0
				) {
					this.involved = [];

					for (const item of this.planAction.involved) {
						this.involved.push(item.CODUSU);
					}
				}

				if (this.view) {
					this.planAction.QUNCST = this.numeral(
						parseFloat(response.QUNCST)
					).format("0,0.00");
					this.planAction.PEREXE = this.numeral(
						parseFloat(response.PEREXE)
					).format("0,0.00");
				}

				planActionTaskService
					.getArrayTasks(this.id)
					.then((response) => (this.planAction.tasks = response));

				this.loading = false;
			});
		},
		getStrategicPlannings() {
			this.loadingPlanning = true;
			const userData = JSON.parse(localStorage.getItem("user"));

			return strategicPlanningService
				.getPlanning({ codusu: userData.user.CODUSU })
				.then((response) => {
					this.strategicPlannings = response;
					this.loadingPlanning = false;
				});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;
			});
		},
		getUnits(change) {
			const search = {};

			this.units = [];
			this.areas = [];
			this.objectives = [];
			this.loadingUnit = true;

			if (change) {
				this.planAction.CODUNI = "";
				this.planAction.CODARE = "";
				this.planAction.CODOBJ = "";
			}

			if (this.planAction.CODEMP) {
				search.company = this.planAction.CODEMP;
			}

			return unitService.search(search).then((response) => {
				this.units = response;
				this.loadingUnit = false;

				if (this.$refs.involved) {
					this.$refs.involved.setCompanyUnit(
						this.planAction.CODEMP,
						this.planAction.CODUNI
					);
					this.$refs.involved.search("");
				}
			});
		},
		getAreas(change) {
			const search = {};

			this.areas = [];
			this.loadingArea = true;

			if (change) {
				this.planAction.CODARE = "";
			}

			if (this.planAction.CODEMP) {
				search.company = this.planAction.CODEMP;
			}

			if (this.planAction.CODUNI) {
				search.unit = this.planAction.CODUNI;
			}

			areaService.search(search).then((response) => {
				this.areas = response;
				this.loadingArea = false;
			});
		},
		getObjectives(change) {
			const search = {};

			this.objectives = [];
			this.loadingObjective = true;

			if (change) {
				this.planAction.CODOBJ = "";
			}

			if (this.planAction.CODEMP) {
				search.company = this.planAction.CODEMP;
			}

			if (this.planAction.CODUNI) {
				search.unit = this.planAction.CODUNI;
			}

			if (this.planAction.CODPLE) {
				search.strategicPlanning = this.planAction.CODPLE;
			}

			objectiveService.search(search).then((response) => {
				this.objectives = response;
				this.loadingObjective = false;
			});
		},
		getIndicator(change) {
			const search = {};

			this.getObjectives();

			this.indicators = [];
			this.loadingIndicator = true;

			if (change) {
				this.planAction.CODOBJ = "";
			}

			if (this.planAction.CODEMP) {
				search.company = this.planAction.CODEMP;
			}

			if (this.planAction.CODUNI) {
				search.unit = this.planAction.CODUNI;
			}

			if (this.planAction.CODPLE) {
				search.strategicPlanning = this.planAction.CODPLE;
			}

			if (this.planAction.CODARE) {
				search.area = this.planAction.CODARE;
			}

			return indicatorService.search(search).then((response) => {
				this.indicators = response;
				this.loadingIndicator = false;
			});
		},
		getUsers() {
			this.loadingUser = true;
			return userService.usersByKey().then((response) => {
				this.users = response;
				this.loadingUser = false;
			});
		},
		getObjectiveAndArea(change) {
			this.getObjectives(change);
			this.getAreas(change);
			this.getUsers();
		},
		fetch() {
			const funcs = [];

			if (this.id) {
				funcs.push(this.getPlanAction());
			}

			funcs.push(this.getStrategicPlannings());
			funcs.push(this.getCompanies());
			funcs.push(this.getUsers());

			Promise.all(funcs)
				.then(() => {
					if (this.id) {
						this.getUnits(false);
						this.getAreas();
						this.getObjectives();
						this.getIndicator();
						this.getDiagnostic();
					}
				})
				.finally(() => {});
		},
		setIsInModal(value) {
			this.isInModal = value;
		},
		setModalData(data) {
			this.planAction.CODEMP = data.companyCode;
			this.getUnits(true);
			this.planAction.CODUNI = data.unitCode;
			this.planAction.CODPLE = data.planningCode;
			this.getAreas();
			this.planAction.CODARE = data.area;
			this.getObjectives();
			this.getIndicator();
			this.planAction.CODIND = data.code;
		},
		getDiagnostic() {
			const search = {};

			if (this.planAction.CODEMP) {
				search.company = this.planAction.CODEMP;
			}

			if (this.planAction.CODUNI) {
				search.unit = this.planAction.CODUNI;
			}

			if (this.planAction.CODPLE) {
				search.strategicPlanning = this.planAction.CODPLE;
			}

			if (search.company && search.unit && search.strategicPlanning) {
				this.loadingDiagnostic = true;
				diagnosticService.getSwot(search).then((response) => {
					this.loadingDiagnostic = false;
					this.diagnostics = response;
				});
			}
		},
		handleClickOpenModalPlanActionTasks() {
			this.toggleModalTask = true;
			this.formPlanActionTask = {
				TARSIT: 'A'
			};
		},
		handleCloseModalTask() {
			this.formPlanActionTask = {};
			this.toggleModalTask = false;
		},
		handleSaveFormModalTask() {
			if (
				!this.formPlanActionTask.TARTIT ||
				!this.formPlanActionTask.TARSIT
			) {
				this.toast("warning", "Preencha todos os campos");
				return;
			}

			if (this.formPlanActionTask.update) {
				const index = this.planAction.tasks.findIndex(
					(task) => task.TARSEQ == this.formPlanActionTask.TARSEQ
				);

				if (this.planAction.tasks[index]) {
					this.planAction.tasks[index] = {
						...this.planAction.tasks[index],
						...this.formPlanActionTask,
					};
				}
			} else {
				if (!this.planAction.tasks) this.planAction.tasks = [];

				this.planAction.tasks.push({
					...this.formPlanActionTask,
					CODPLA: this.id,
					TARSEQ: this.planAction.tasks.length + 1,
				});
			}

			this.formPlanActionTask = {};
			this.$nextTick();
			this.toggleModalTask = false;
		},
		handleClickEditTask(item) {
			this.formPlanActionTask = item;
			this.formPlanActionTask.update = true;
			this.toggleModalTask = true;
		},
		handleDeleteModalTask(tarseq) {
			const index = this.planAction.tasks.findIndex(
				(task) => task.TARSEQ == tarseq
			);

			if (index >= 0) {
				this.planAction.tasks.splice(index, 1);
				this.planAction.tasks = this.planAction.tasks.map((task, index) => {
					return {
						...task,
						TARSEQ: index + 1,
					};
				});
				this.toggleModalTask = false;
				this.formPlanActionTask = {};
			}
		},
	},
};
</script>
