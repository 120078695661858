<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="pa-4" flat>
					<div class="text-center">
						<p class="display-2 font-weight-thin">
							Sem permissão para acessar esta página
						</p>
						<v-btn class="mt-5" depressed @click="$router.go(-1)" rounded color="primary" dark>Voltar à página anterior</v-btn>
					</div>	
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "NotAllowed",
	computed: {},
	data: () => ({}),
	methods: {}
};
</script>
