<template>
	<v-container fluid>
		<v-dialog v-model="registerAnalyze" persistent max-width="700">
			<v-card>
				<v-card-title class="headline">
					Registrar analise
					<v-spacer />
					<v-btn
						color="red darken-1"
						fab
						small
						text
						v-show="!loading"
						@click="closeAnalyze"
						><v-icon>mdi-close</v-icon></v-btn
					>
				</v-card-title>

				<v-divider></v-divider>

				<v-card-text>
					<v-row>
						<v-col cols="12" md="12" class="colDialog mt-6"
							><strong>Empresa:</strong>
							<span>{{
								registrationAnalyze.companyName
							}}</span></v-col
						>
						<v-col cols="12" md="12" class="colDialog"
							><strong>Unidade:</strong>
							<span>{{
								registrationAnalyze.unitName
							}}</span></v-col
						>
						<v-col cols="12" md="12" class="colDialog"
							><strong>Planejamento:</strong>
							<span>{{
								registrationAnalyze.planningName
							}}</span></v-col
						>
						<v-col cols="12" md="12" class="colDialog"
							><strong>Área:</strong>
							<span>{{
								registrationAnalyze.areaName
							}}</span></v-col
						>
						<v-col cols="12" md="12" class="colDialog"
							><strong>Indicador:</strong>
							<span>{{ registrationAnalyze.name }}</span></v-col
						>
						<v-col cols="12" md="12" class="colDialog"
							><strong>Mês:</strong>
							<span>{{ registrationAnalyze.month }}</span></v-col
						>
					</v-row>

					<v-row>
						<v-col>
							<v-textarea
								v-model="registrationAnalyze.message"
								label="Descrição"
							></v-textarea>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary darken-1"
						text
						:loading="loading"
						:disabled="disableStatus"
						@click="confirmRegistrationAnalyze"
						>Registrar analise</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
export default {
	name: "IndicatorRegisterFormAnalyse",
	props: {
		registrationAnalyze: { type: Object, required: true, value: {} },
		registerAnalyze: { type: Boolean, value: false }
	},
	data: () => ({
		loading: false,
		disableStatus: false,
		analyzes: []
	}),
	mounted() {},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		closeAnalyze() {
			this.$emit('closeAnalyze');
		},
		confirmRegistrationAnalyze () {
			this.$emit('confirmRegistrationAnalyze', this.registrationAnalyze);
		},
	}
};
</script>

<style>
	.aggroup {
		background-color: #eaeaea;
	}

	.aggroup > td {
		font-size: 0.805rem;
		height: 40px;
	}

	.aggroupLine > td {
		font-size: 0.755rem;
		height: 40px;
	}

	.borderRight {
		border-right: thin solid rgba(0, 0, 0, 0.12);
	}

	.colDialog {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.divToClick {
		cursor: pointer;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.dot {
		float: right;
		margin-top: 2px;
		margin-right: 4px;
		width: 8px;
		height: 8px;
		background-color: white;
		border-radius: 50%;
	}
</style>
