<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{
							view
								? "Visualizar processo"
								: id
								? "Editar processo"
								: "Cadastrar processo"
						}}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="process.CODGRP"
									:items="groups"
									item-text="DESGRP"
									item-value="CODGRP"
									label="Grupo"
									class="required"
									clearable
									:loading="loadingGroup"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="process.GERCOPARE"
									:items="corporateAreas"
									item-text="DESAREA"
									item-value="GERCOPARE"
									label="Área Corporativa"
									:loading="loadingCorporateArea"
									clearable
									@change="updateCorporateArea()"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="process.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									:disabled="disableCompany"
									:loading="loadingCompany"
									clearable
									@change="getUnits(true)"
								></v-select>
							</v-col>
							<v-col cols="12" md="6">
								<v-select
									v-model="process.CODUNI"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									:disabled="disableUnit"
									:loading="loadingUnit"
									clearable
									@change="
										getAreas(true);
									"
								></v-select>
							</v-col>
							<v-col cols="12" md="6">
								<v-select
									v-model="process.CODARE"
									:items="areas"
									item-text="DESARE"
									item-value="CODARE"
									label="Área"
									:disabled="disableArea"
									clearable
									:loading="loadingArea"
								></v-select>
							</v-col>
							<!-- <v-col cols="12" md="4">
								<v-select
									v-model="process.CODTIP"
									:items="types"
									item-text="DESTIP"
									item-value="CODTIP"
									label="Tipo"
									:disabled="disableType"
									clearable
									:loading="loadingType"
								></v-select>
							</v-col> -->
							<v-col cols="6" md="6">
								<v-text-field
									v-model="process.TITPROG"
									label="Título"
									class="required"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn
									color="primary"
									depressed
									tile
									class="mr-4"
									@click="save"
									:disabled="loading"
									v-show="!view"
									>Salvar</v-btn
								>
								<v-btn
									color="primary"
									text
									class="mr-4"
									@click="$router.push('/process/program')"
									:disabled="loading"
									>Voltar</v-btn
								>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
		<template>
			<Dialog ref="confirm" />
		</template>
	</v-container>
</template>

<script>
import CompanyGroupService from "../../app/Services/CompanyGroupService";

import Dialog from "../misc/Dialog";
import ProgramServiceService from "../../app/Services/ProgramService";
import CompanyService from "../../app/Services/CompanyService";
import AreaService from "../../app/Services/AreaService";
import CorporateAreaService from "../../app/Services/CorporateAreaService";
import UnitService from "../../app/Services/UnitService";
import UserService from "../../app/Services/UserService";
import TypeService from "../../app/Services/TypeService";
import axios from "../../config/service/standard";

const companyGroupService = CompanyGroupService.build();
const ProgramService = ProgramServiceService.build();
const companyService = CompanyService.build();
const areaService = AreaService.build();
const corporateAreaService = CorporateAreaService.build();
const unitService = UnitService.build();
const userService = UserService.build();
const typeService = TypeService.build();

export default {
	name: "ProgramForm",
	components: { Dialog },
	data: () => ({
		id: null,
		fileRules: [],
		view: false,
		groups: [],
		companies: [],
		areas: [],
		types: [],
		corporateAreas: [],
		units: [],
		status: [
			{ id: "A", description: "Aberto" },
			{ id: "F", description: "Fechado" },
		],
		process: {
			GERPROG: 0,
			CODGRP: 0,
			CODEMP: 0,
			CODUNI: 0,
			CODARE: 0,
			CODTIP: 0,
			GERCOPARE: 0,
			TITPROG: "",
			CREATED_AT: "",
			UPDATED_AT: "",
		},
		loadingGroup: false,
		loading: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingArea: false,
		loadingType: false,
		loadingCorporateArea: false,
		disableCompany: false,
		disableUnit: false,
		disableArea: false,
		disableType: false,
		fileBase64: "",
	}),
	mounted() {
		const { id, view } = this.$route.params;

		if (id) {
			this.id = id;
		}

		if (view) {
			this.view = view;
		}

		this.fetch();
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		save() {
			if (!this.process.CODGRP) {
				this.toast("warning", "Informe o grupo do programa para continuar");
				return;
			}

			if (!this.process.TITPROG) {
				this.toast("warning", "Informe o título do programa para continuar");
				return;
			}

			this.loading = true;
			this.process.CODGRP = this.process.CODGRP || 0;
			this.process.CODEMP = this.process.CODEMP || 0;
			this.process.CODUNI = this.process.CODUNI || 0;
			this.process.CODARE = this.process.CODARE || 0;
			this.process.CODTIP = this.process.CODTIP || 0;
			this.process.GERCOPARE = this.process.GERCOPARE || 0;

			const rest = this.id
				? ProgramService.update({ id: this.id, ...this.process })
				: ProgramService.create({ ...this.process });

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast(
					"success",
					this.id
						? "Processo atualizada com sucesso!"
						: "Processo adicionada com sucesso!"
				);
				this.loading = false;

				if (!this.isInModal) {
					this.$router.push("/process/program");
				} else {
					this.$emit("saved", true);
				}
			});
		},
		async getProcess() {
			this.loading = true;

			return ProgramService.read({ id: this.id }).then((response) => {
				this.process = response;
				this.loading = false;
			});
		},
		getGroup() {
			this.loadingGroup = true;

			companyGroupService.search("").then((response) => {
				this.groups = response;
				this.loadingGroup = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;
			});
		},
		getUnits(change) {
			const search = {};

			this.units = [];
			this.areas = [];
			this.loadingUnit = true;

			if (change) {
				this.process.CODUNI = "";
				this.process.CODARE = "";
				this.process.CODTIP = "";
			}

			if (this.process.CODEMP) {
				search.company = this.process.CODEMP;
			}

			return unitService.search(search).then((response) => {
				this.units = response;
				this.loadingUnit = false;
			});
		},
		getAreas(change) {
			const search = {};

			this.areas = [];
			this.loadingArea = true;

			if (change) {
				this.process.CODARE = "";
			}

			if (this.process.CODEMP) {
				search.company = this.process.CODEMP;
			}

			if (this.process.CODUNI) {
				search.unit = this.process.CODUNI;
			}

			areaService.search(search).then((response) => {
				this.areas = response;
				this.loadingArea = false;
			});
		},
		getTypes(change) {
			const search = {};

			this.types = [];
			this.loadingType = true;

			if (change) {
				this.process.CODTIP = "";
			}

			if (this.process.CODEMP) {
				search.company = this.process.CODEMP;
			}

			if (this.process.CODUNI) {
				search.unit = this.process.CODUNI;
			}

			typeService.search(search).then((response) => {
				this.types = response.data;
				this.loadingType = false;
			});
		},
		getCorporateArea(change) {
			const search = {};

			this.corporateAreas = [];
			this.loadingCorporateArea = true;

			if (change) {
				this.process.CODARE = "";
			}

			if (this.process.CODEMP) {
				search.company = this.process.CODEMP;
			}

			if (this.process.CODUNI) {
				search.unit = this.process.CODUNI;
			}

			corporateAreaService.search(search).then((response) => {
				this.corporateAreas = response;
				this.loadingCorporateArea = false;
			});
		},
		getUsers() {
			this.loadingUser = true;
			return userService.usersByKey().then((response) => {
				this.users = response;
				this.loadingUser = false;
			});
		},
		updateCorporateArea() {
			if (parseInt(this.process.GERCOPARE) > 0) {
				this.process.CODEMP = 0;
				this.process.CODUNI = 0;
				this.process.CODARE = 0;
				this.process.CODTIP = 0;

				this.disableCompany = true;
				this.disableUnit = true;
				this.disableArea = true;
				this.disableType = true;
			} else {
				this.disableCompany = false;
				this.disableUnit = false;
				this.disableArea = false;
				this.disableType = false;
			}
		},
		fetch() {
			const funcs = [];

			if (this.id) {
				funcs.push(this.getProcess());
			}

			funcs.push(this.getGroup());
			funcs.push(this.getCompanies());
			funcs.push(this.getUsers());
			funcs.push(this.getCorporateArea());

			Promise.all(funcs)
				.then(() => {
					if (this.id) {
						this.getUnits(false);
						this.getAreas();
						// this.getTypes();
						this.updateCorporateArea();
					}
				})
				.finally(() => {});
		},
		onFileSelect(fileTmp) {
			if (fileTmp == null) return;

			this.process.FILE_NAME = fileTmp.name;

			this.toBase64(fileTmp).then((base64) => {
				this.fileBase64 = base64;
			});
		},
		toBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
				reader.readAsDataURL(file);
			});
		},
		download() {
			this.$root.$Loading.show("Fazendo download...");

			axios
				.get("/api/process/download", {
					responseType: "arraybuffer",
					params: {
						id: this.process.GERPRO,
					},
				})
				.then((response) => {
					let blob = new Blob([response.data], {
						type: "application/pdf",
					});
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = this.process.TITPRO;
					link.click();
				})
				.finally(() => {
					this.$root.$Loading.hide();
				});
		},
		async removeDownload() {
			await this.$refs.confirm
				.open("Atenção", "Deseja mesmo excluir?")
				.then(() => {
					this.$root.$Loading.show("Removendo arquivo...");

					axios
						.post("/api/process/removeDownload", {
							id: this.process.GERPRO,
						})
						.then(() => {
							this.toast("success", "Arquivo removido com sucesso!");
							this.process.FILE = null;
						})
						.finally(() => {
							this.$root.$Loading.hide();
						});
				});
		},
	},
};
</script>
