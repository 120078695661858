import Rest from "../Base/Rest";

/**
 * @typedef {OrganizationChartService}
 */
export default class OrganizationChartService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = "/organization-chart";
}
