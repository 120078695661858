import Rest from '../Base/Rest';

/**
 * @typedef {CoverService}
 */
export default class CoverService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/cover';

	coverImages() {
		return this.get('images')
	}
}
