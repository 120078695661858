<template>
	<v-container>
		<v-row>
			<v-col cols="12" md="12">
				<v-card flat :loading="loading">
					<v-card-title class="card-title">
						<v-icon class="card-title-icon" color="black">mdi-home</v-icon>
						<label class="pl-4 card-title-label">Início</label>
					</v-card-title>

					<v-divider></v-divider>

					<v-card-text>
						<v-col class="pb-5" cols="12" md="12">
							<v-row>
								<v-tabs v-model="tab" grow>
									<v-tabs-slider color="red"></v-tabs-slider>

									<v-tab><v-icon left>mdi-information</v-icon> Informações</v-tab>
									<v-tab><v-icon left>mdi-key</v-icon> Minhas chaves</v-tab>
									<v-tab><v-icon left>mdi-account-settings</v-icon> Configurações</v-tab>
								</v-tabs>

								<v-tabs-items v-model="tab" style="width: 100%;">
									<v-tab-item>
										<v-container>
											<v-row>
												<v-col cols="12" md="12">
													<v-row>
														<v-col cols="12" md="2">
															<PictureUploader v-model="user.FOTUSU" @input="save" customEmit="profilePictureUpdated" />
														</v-col>
														<v-col cols="12" md="10">
															<v-row class="pa-4">
																<v-col cols="12" md="8">
																	<v-text-field label="Nome" v-model="user.NOMUSU"></v-text-field>
																</v-col>
																<v-col cols="12" md="8">
																	<v-text-field label="E-mail" v-model="user.EMAUSU"></v-text-field>
																</v-col>
																<v-col cols="12" md="8" v-if="user.TIPUSU === 'E'">
																	<v-text-field label="Chave de usuário" readonly v-model="user.USRKEY"></v-text-field>
																</v-col>
																<v-col cols="12" md="8">
																	<v-text-field label="Senha" v-model="user.newPassword" type="password"></v-text-field>
																</v-col>
															</v-row>
														</v-col>
													</v-row>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="12" md="12" style="text-align: center;">
													<v-btn class="white--text mr-4" color="primary" :loading="isLoading" :disabled="loading" depressed @click="save">Atualizar dados</v-btn>
												</v-col>
											</v-row>
										</v-container>
									</v-tab-item>

									<v-tab-item>
										<v-container>
											<v-row>
												<v-col cols="12" md="9">
													<v-text-field v-model="userKey.USRKEY" label="Chave"></v-text-field>
												</v-col>
												<v-col cols="12" md="3">
													<v-btn class="white--text mt-3" depressed color="primary" :loading="isLoadingKey" @click="addKey()">Adicionar chave</v-btn>
												</v-col>
											</v-row>
										</v-container>

										<v-data-table
											:headers="headers"
											:items="items"
											:items-per-page="pagination.itemsPerPage"
											:loading="loadingKeys"
											:hide-default-footer="true"
											:page.sync="pagination.page"
											@update:pagination="pagination"
										>
											<template v-slot:body="{ items }">
												<tr v-for="item in items" :key="item.CODKEY">
													<td>
														<v-chip v-if="item.STATUS === 'P'" class="ma-2" small color="warning">Pendente</v-chip>
														<v-chip v-if="item.STATUS === 'A'" class="ma-2" small color="success">Aprovada</v-chip>
														<v-chip v-if="item.STATUS === 'R'" class="ma-2" small color="danger">Reprovada</v-chip>
													</td>
													<td>{{item.USRKEY}}</td>
													<td>{{item.owneruser.NOMUSU}}</td>
													<td>
														<v-btn @click="removeKey(item.CODKEY)" :disabled="loading" icon>
															<v-icon style="color: red">mdi-delete</v-icon>
														</v-btn>
													</td>
												</tr>
											</template>
										</v-data-table>

										<pagination
											v-model="pagination"
											:loading="loading"
											@change="loadData"
										/>
									</v-tab-item>

									<v-tab-item>
										<v-container>
											<v-row>
												<v-col cols="12" md="12" style="text-align: center;">
													<v-dialog v-model="deleteDialog" persistent max-width="600">
														<template v-slot:activator="{ on, attrs }">
															<v-btn color="red" outlined dark v-bind="attrs" v-on="on" :disabled="loading">Excluir cadastro</v-btn>
														</template>
														<v-card>
															<v-card-title class="headline">Excluir cadastro</v-card-title>
															<v-divider></v-divider>
															<v-card-text>
																<v-row>
																	<v-col cols="12" md="12">Deseja realmente excluir seu cadastro? Seus dados serão excluidos definitivamente</v-col>
																</v-row>
															</v-card-text>
															<v-card-actions>
																<v-spacer></v-spacer>
																<v-btn style="color: white" color="primary" @click="deleteRegistration">Sim, concordo em excluir meu cadastro</v-btn>
																<v-btn color="red darken-1" text @click="deleteDialog = false">Cancelar</v-btn>
															</v-card-actions>
														</v-card>
													</v-dialog>
												</v-col>
											</v-row>
										</v-container>
									</v-tab-item>
								</v-tabs-items>
							</v-row>
						</v-col>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import UserService from '../../app/Services/UserService';
import UserKeyService from '../../app/Services/UserKeyService';
import PictureUploader from '../picture-uploader/PictureUploader';

import pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import SortableMixin from "@/components/pagination/sort/SortableMixin";

const userService = UserService.build();
const userKeyService = UserKeyService.build();

export default {
	name: 'CandidateHome',
	components: {
		PictureUploader, pagination
	},
	mixins: [PaginationMixin, SortableMixin],
	mounted() {
		const localStorageData = JSON.parse(localStorage.getItem('user'));
		this.id = localStorageData.user.CODUSU;

		this.fetchData();
	},
	data: () => ({
		tab: null,
		id: null,
		deleteDialog: false,
		isLoading: false,
		isLoadingKey: false,
		loading: false,
		loadingKeys: false,
		user: {
			newPassword: ''
		},
		userKey: {
			USRKEY: '',
			STATUS: 'P'
		},
		headers: [
			{
				text: "Status",
				align: "left",
				sortable: false,
				value: "status",
				width: "10%"
			},
			{
				text: "Chave",
				align: "left",
				sortable: false,
				value: "status",
				width: "20%"
			},
			{
				text: "Proprietário",
				align: "left",
				sortable: false,
				value: "status"
			},
			{
				text: "Ações",
				align: "left",
				sortable: false,
				value: "status",
				width: "10%"
			}
		],
		items: []
	}),
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		deleteRegistration() {
			return userService.deleteRegistration().then(() => {
				this.$toasted.success('Cadastro excluído com sucesso!', {
					icon: 'mdi-check',
				});

				this.$router.push({ path: '/logoff' });
			});
		},
		save() {
			this.isLoading = true;

			userService
				.updateData(
					this.user
				)
				.then(() => {
					this.isLoading = false;

					this.$toasted.success('Usuário atualizado com sucesso!', {
						icon: 'mdi-check',
					});

					if (this.user.newPassword) {
						this.$router.push({ path: "/logoff" });
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		getUserData () {
			return userService
				.read({
					id: this.id,
				})
				.then((response) => {
					this.user = response;

					this.user.newPassword = '';
				});
		},
		getMyKeys () {
			this.loadingKeys = true;
			this.items = [];

			return userKeyService
				.search({})
				.then((response) => {
					this.items = response;
					this.loadingKeys = false;
				});
		},
		fetchData() {
			this.loading = true;

			Promise.all([this.getUserData(), this.getMyKeys()])
				.then(() => {
					this.paginate({
						total: this.items.length || 0,
						last_page: Math.round(this.items.length / this.pagination.itemsPerPage),
						from: 1,
						to: 1,
						data: {}
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
		loadData(page) {
			this.pagination.from = this.pagination.page;
			this.pagination.to = page;
		},
		addKey () {
			if (!this.userKey.USRKEY) {
				this.toast(
					"warning",
					"Nenhuma chave foi informada"
				);
				return;
			}

			this.isLoadingKey = true;

			const rest = userKeyService.create(this.userKey);

			rest.then((response) => {
				this.isLoadingKey = false;

				if (!response) {
					return;
				}

				if (response.status == 'your_key') {
					this.$toasted.error('A sua chave não pode ser informada', { icon: 'mdi-account-remove' });
					return;
				}

				if (response.status == 'key_exists') {
					this.$toasted.error('A chave informada já foi cadastrada', { icon: 'mdi-account-remove' });
					return;
				}

				this.userKey.USRKEY = '';

				this.toast('success', 'Chave adicionada com sucesso!');
				this.loading = false;

				this.getMyKeys();
			});
		},
		removeKey(id) {
			this.loading = true;

			userKeyService
				.destroy({
					id: id
				})
				.then(() => {
					this.toast(
						"success",
						"Chave removida com sucesso!"
					);

					this.loading = false;
					this.getMyKeys();
				});
		}
	},
};
</script>
