<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<v-card flat :loading="loading">
					<v-card-title> Consulta de Swot </v-card-title>

					<v-card-text>
						Selecione o planejamento estratégico para continuar...

						<v-row class="mt-5">
							<v-col cols="12" md="3">
								<v-select
									class="required"
									v-model="filter.companyGroup"
									:items="companyGroups"
									item-text="DESGRP"
									item-value="CODGRP"
									label="Grupo de empresa"
									:loading="loadingCompanyGroup"
									hide-details
									@change="getCompanies"
								></v-select>
							</v-col>
							<v-col cols="12" md="3">
								<v-select
									class="required"
									v-model="filter.strategicPlanning"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									label="Planejamento estrategico"
									:loading="loadingPlanning"
									hide-details
								></v-select>
							</v-col>
							<v-col cols="12" md="3">
								<v-select
									v-model="filter.company"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									:loading="loadingCompany"
									@change="getUnits"
									clearable
								></v-select>
							</v-col>
							<v-col cols="12" md="3">
								<v-select
									v-model="filter.unit"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									:loading="loadingUnit"
									clearable
								></v-select>
							</v-col>
							<v-col cols="12" md="12" class="text-right">
								<v-btn
									class="white--text mt-3"
									depressed
									color="info"
									@click="fetchData()"
									:loading="loading"
									>Buscar</v-btn
								>
								&nbsp;
								<v-btn
									class="white--text mt-3"
									depressed
									color="info"
									@click="downloadSwot()"
									:loading="loading"
									>Download</v-btn
								>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-card flat :loading="loading" v-if="swot.force.length > 0">
			<v-row cols="12" class="line">
				<v-col cols="12" md="5">
					<v-card elevation="0" shaped>
						<v-col cols="12" class="text-center">
							<div style="padding-top: 20px; padding-bottom: 20px">
								<h1>
									<v-icon class="cardIcon cardTitleBlue">mdi-dumbbell</v-icon>
									<div class="cardTitle cardTitleBlue">FORÇAS</div>
								</h1>
								<div style="padding: 50px 0">
									<h5 v-for="(item, index) in swot.force" :key="index">
										{{ item }}
									</h5>
								</div>
							</div>
						</v-col>
					</v-card>
				</v-col>
				<v-col cols="12" md="2" class="text-center">
					<div class="analyse d-inline-flex" style="margin-top: 52px">
						<div>
							<v-icon>mdi-arrow-left</v-icon>
						</div>
						<div>
							ANÁLISE INTERNA
							<br />
							(Organização)
						</div>
						<div>
							<v-icon>mdi-arrow-right</v-icon>
						</div>
					</div>
					<div class="lineVertical d-sm-none d-md-inline">&nbsp;</div>
				</v-col>
				<v-col cols="12" md="5">
					<v-card elevation="0" shaped>
						<v-col cols="12" class="text-center">
							<div style="padding-top: 20px; padding-bottom: 20px">
								<h1>
									<v-icon class="cardIcon cardTitleRed"
										>mdi-thermometer-alert</v-icon
									>
									<div class="cardTitle cardTitleRed">FRAQUEZAS</div>
								</h1>
								<div style="padding: 50px 0">
									<h5 v-for="(item, index) in swot.weakness" :key="index">
										{{ item }}
									</h5>
								</div>
							</div>
						</v-col>
					</v-card>
				</v-col>
			</v-row>
			<v-row cols="12">
				<v-col cols="12" md="5" style="align-self: end">
					<v-card elevation="0" shaped>
						<v-col cols="12" class="text-center">
							<div style="padding-top: 20px; padding-bottom: 20px">
								<div style="padding: 50px 0">
									<h5 v-for="(item, index) in swot.opportunities" :key="index">
										{{ item }}
									</h5>
								</div>
								<h1>
									<v-icon class="cardIcon cardTitleGreen"
										>mdi-bullseye-arrow</v-icon
									>
									<div class="cardTitle cardTitleGreen">OPORTUNIDADES</div>
								</h1>
							</div>
						</v-col>
					</v-card>
				</v-col>
				<v-col
					cols="12"
					md="2"
					class="text-center"
					style="align-self: end; padding: 0px 0px 65px"
				>
					<div class="analyse d-inline-flex">
						<div>
							<v-icon>mdi-arrow-left</v-icon>
						</div>
						<div>
							ANÁLISE EXTERNA
							<br />
							(Mercado)
						</div>
						<div>
							<v-icon>mdi-arrow-right</v-icon>
						</div>
					</div>
				</v-col>
				<v-col cols="12" md="5" style="align-self: end">
					<v-card elevation="0" shaped>
						<v-col cols="12" class="text-center">
							<div style="padding-top: 20px; padding-bottom: 20px">
								<div style="padding: 50px 0">
									<h5 v-for="(item, index) in swot.threats" :key="index">
										{{ item }}
									</h5>
								</div>
								<h1>
									<v-icon class="cardIcon cardTitleRed">mdi-alert</v-icon>
									<div class="cardTitle cardTitleRed">AMEAÇAS</div>
								</h1>
							</div>
						</v-col>
					</v-card>
				</v-col>
			</v-row>
		</v-card>
	</v-container>
</template>

<script>
import DiagnosticService from "../../app/Services/DiagnosticService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import CompanyGroupService from "../../app/Services/CompanyGroupService";
import axios from "../../config/service/standard";

const diagnosticService = DiagnosticService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();
const strategicPlanningService = StrategicPlanningService.build();
const companyGroupService = CompanyGroupService.build();

export default {
	name: "Swot",
	data: () => ({
		loading: false,
		loadingCompanyGroup: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingPlanning: false,
		registerIndicator: false,
		strategicPlannings: [],
		companies: [],
		units: [],
		itens: [],
		itensFilter: {},
		companyGroups: [],
		swot: {
			force: [],
			weakness: [],
			opportunities: [],
			threats: [],
		},
		filter: {
			company: "",
			unit: "",
			strategicPlanning: "",
		},
		defaultValues: {},
	}),
	mounted() {
		this.defaultValues = JSON.parse(localStorage.getItem("default_filters"));
		this.getCompanyGroups();
		this.getStrategicPlannings();
		this.fetchData();
	},
	computed: {},
	methods: {
		fetchData() {
			this.swot.force = [];
			this.swot.weakness = [];
			this.swot.opportunities = [];
			this.swot.threats = [];

			return diagnosticService.getSwot(this.filter).then((response) => {
				this.diagnostic = response;

				for (const item of this.diagnostic) {
					switch (item.AMBIEN) {
						case "I":
							switch (item.TIPO) {
								case "O":
									this.swot.force.push(item.DESDIA);
									break;
								default:
									this.swot.weakness.push(item.DESDIA);
									break;
							}
							break;
						case "E":
							switch (item.TIPO) {
								case "P":
									this.swot.opportunities.push(item.DESDIA);
									break;
								default:
									this.swot.threats.push(item.DESDIA);
									break;
							}
							break;
					}
				}

				this.loading = false;
			});
		},
		getCompanyGroups() {
			this.loadingCompanyGroup = true;

			companyGroupService.search({}).then((response) => {
				this.companyGroups = response;

				if (this.companyGroups.length === 1 || this.defaultValues.CODGRP) {
					this.filter.companyGroup =
						this.defaultValues.CODGRP ?? this.companyGroups[0].CODGRP;
					this.getCompanies();
				}

				this.loadingCompanyGroup = false;
			});
		},
		getCompanies() {
			const search = {};

			this.filter.company = "";
			this.companies = [];
			this.filter.unit = "";
			this.units = [];
			this.loadingCompany = true;

			if (this.filter.companyGroup) {
				search.companyGroup = this.filter.companyGroup;
			}

			return companyService.search(search).then((response) => {
				this.companies = response;

				if (this.companies.length === 1 || this.defaultValues.CODEMP) {
					this.filter.company =
						this.defaultValues.CODEMP ?? this.companies[0].CODEMP;
					this.getUnits();
				}

				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.filter.unit = "";
			this.units = [];
			this.loadingUnit = true;

			if (this.filter.company) {
				search.company = this.filter.company;
			}

			return unitService.search(search).then((response) => {
				this.units = response;

				if (this.units.length === 1 || this.defaultValues.CODUNI) {
					this.filter.unit = this.defaultValues.CODUNI ?? this.units[0].CODUNI;
				}

				this.loadingUnit = false;
			});
		},
		getStrategicPlannings() {
			this.resultGeral = 0;
			this.resultGeralPeso = 0;

			this.loadingPlanning = true;
			strategicPlanningService.search({}).then((response) => {
				this.strategicPlannings = response;

				if (this.strategicPlannings.length === 1 || this.defaultValues.CODPLE) {
					this.filter.strategicPlanning =
						this.defaultValues.CODPLE ?? this.strategicPlannings[0].CODPLE;
				}

				this.loadingPlanning = false;
			});
		},
		downloadSwot() {
			this.$root.$Loading.show("Gerando PDF do SWOT...");

			axios
				.get("/api/diagnostic/downloadSwot", {
					responseType: "arraybuffer",
					params: this.filter,
				})
				.then((response) => {
					this.$root.$Loading.hide();

					let blob = new Blob([response.data], {
						type: "application/pdf",
					});

					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = "swot.pdf";
					link.click();
				})
				.finally(() => {
					this.$root.$Loading.hide();
				});
		},
	},
};
</script>

<style>
.cardTitle {
	color: white;
	border-top-right-radius: 35px;
	border-bottom-right-radius: 35px;
	padding: 7px 10%;
	height: 65px;
	max-width: 100%;
	display: inline-block;
	vertical-align: middle;
}
.cardTitleBlue {
	background: #008ac9;
	border: 5px solid #008ac9;
}
.cardTitleRed {
	background: #f23f28;
	border: 5px solid #f23f28;
}
.cardTitleGreen {
	background: #77c14e;
	border: 5px solid #77c14e;
}
.cardIcon {
	position: relative;
	box-shadow: 1px 1px 4px -1px black;
	left: 25px;
	padding: 6px;
	background: white;
	border-radius: 43px;
	font-size: 54px !important;
}
.analyse {
	padding: 5px 0;
	background: white;
	position: relative;
	z-index: 2;
	color: rgb(128, 129, 185);
	font-weight: bold;
	border-radius: 10px;
}
.line {
	border-bottom: 1px solid #ccc;
}
.lineVertical {
	position: absolute;
	top: 0;
	left: 50%;
	width: 1px;
	height: 100%;
	z-index: 1;
	box-shadow: 1px 1px 1px 0.1px #ccc;
}
.row .col-12 {
	z-index: 2;
}
</style>
