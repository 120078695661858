<template>
	<v-container fluid>
		<v-row class="mt-5">
			<v-col cols="12" md="3">
				<v-select
					v-model="filter.company"
					:items="companies"
					item-text="DESEMP"
					item-value="CODEMP"
					label="Empresa"
					:loading="loadingCompany"
					@change="getUnits"
				></v-select>
			</v-col>
			<v-col cols="12" md="3">
				<v-select
					v-model="filter.unit"
					:items="units"
					item-text="DESUNI"
					item-value="CODUNI"
					label="Unidade"
					:loading="loadingUnit"
				></v-select>
			</v-col>
			<v-col cols="12" md="3">
				<v-select
					v-model="filter.strategicPlanning"
					:items="strategicPlannings"
					item-text="DESPLE"
					item-value="CODPLE"
					label="Planejamento estrategico"
					:loading="loadingPlanning"
					hide-details
				></v-select>
			</v-col>
			<v-col cols="12" :md="filterId === true ? 2 : 3">
				<v-text-field
					v-model="filter.description"
					label="Descrição"
				></v-text-field>
			</v-col>
			<v-col cols="12" md="1" v-if="filterId === true">
				<v-text-field v-model="filter.id" label="Código"></v-text-field>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import moment from "moment";

import PlanActionService from "@/app/Services/PlanActionService";
import CompanyService from "@/app/Services/CompanyService";
import UnitService from "@/app/Services/UnitService";
import StrategicPlanningService from "@/app/Services/StrategicPlanningService";

const planActionService = PlanActionService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();
const strategicPlanningService = StrategicPlanningService.build();

export default {
	name: "searchFilterDefault",
	props: {
		value: Object,
		filterId: Boolean,
	},
	data: () => ({
		loading: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingPlanning: false,
		loadingPlanningRegistration: false,
		strategicPlannings: [
			{
				CODPLE: "0",
				DESPLE: "Todos",
			},
		],
		companies: [
			{
				CODEMP: "0",
				DESEMP: "Todos",
			},
		],
		units: [
			{
				CODUNI: "0",
				DESUNI: "Todos",
			},
		],
		itens: [],
		userData: {},
		filter: {
			company: "",
			unit: "",
			strategicPlanning: "",
			description: "",
		},
		planningHeaders: [
			{ text: "Data", align: "center", sortable: false, width: "12%" },
			{ text: "Hora", align: "center", sortable: false, width: "12%" },
			{ text: "Usuário", align: "center", sortable: false, width: "15%" },
			{ text: "Descrição", align: "left", sortable: false },
		],
		defaultValues: {},
	}),
	mounted() {
		this.userData = JSON.parse(localStorage.getItem("user"));

		this.filter = this.value;

		this.defaultValues = JSON.parse(localStorage.getItem("default_filters"));

		const data = {
			company: this.defaultValues.CODEMP,
			unit: this.defaultValues.CODUNI,
			strategicPlanning: this.defaultValues.CODPLE,
		};

		this.$emit("input", data);
		this.$emit("search", data);
		this.filter = data;

		this.getCompanies();
		this.getStrategicPlannings();
	},
	watch: {
		filter() {
			this.$emit("input", this.filter);
		},
	},
	methods: {
		search() {
			this.$emit("search", this.filter);
		},
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;

				this.companies.unshift({
					CODEMP: "0",
					DESEMP: "Todos",
				});

				if (this.companies.length === 1 || this.defaultValues.CODEMP) {
					this.filter.company = this.defaultValues.CODEMP ?? this.companies[0].CODEMP;
					this.getUnits();

					this.checkAndSearch();
				}

				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.units = [];
			this.loadingUnit = true;

			if (this.filter.company) {
				search.company = this.filter.company;
			}

			return unitService.search(search).then((response) => {
				this.units = response;

				this.units.unshift({
					CODUNI: "0",
					DESUNI: "Todos",
				});

				if (this.units.length === 1) {
					this.filter.unit = this.units[0].CODUNI;

					this.checkAndSearch();
				}

				this.loadingUnit = false;
			});
		},
		getPlans() {
			if (!this.filter.company) {
				this.toast("warning", "Informe a empresa para continuar");
				return;
			}

			if (!this.filter.unit) {
				this.toast("warning", "Informe a unidade para continuar");
				return;
			}

			if (!this.filter.strategicPlanning) {
				this.toast(
					"warning",
					"Informe o planejamento estrategico para continuar"
				);
				return;
			}

			this.filter.status = "A";
			this.loading = true;
			this.itens = [];

			Promise.all([planActionService.search(this.model)]).then((response) => {
				if (response[0]) {
					this.itens = response[0];
				}

				this.loading = false;
			});
		},
		getStrategicPlannings() {
			this.loadingPlanning = true;

			strategicPlanningService
				.getPlanning({ codusu: this.userData.user.CODUSU })
				.then((response) => {
					this.strategicPlannings = response;

					this.strategicPlannings.unshift({
						CODPLE: "0",
						DESPLE: "Todos",
					});

					if (this.strategicPlannings.length === 1) {
						this.filter.strategicPlanning = this.strategicPlannings[0].CODPLE;

						this.checkAndSearch();
					}

					this.loadingPlanning = false;
				});
		},
		checkAndSearch() {
			if (
				this.filter.company &&
				this.filter.unit &&
				this.filter.strategicPlanning
			) {
				this.getPlans();
			}
		},
		formatDate(value) {
			return moment(value).format("DD/MM/YYYY");
		},
		formatNumber(value) {
			return this.numeral(parseFloat(value)).format("0,0.00");
		},
	},
};
</script>

<style>
.aggroup {
	background-color: #eaeaea;
}

.aggroup > td {
	font-size: 0.805rem;
	height: 40px;
}

.aggroupLine > td {
	font-size: 0.755rem;
	height: 40px;
}

.borderRight {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}

.colDialog {
	padding-top: 0px;
	padding-bottom: 0px;
}

.divToClick {
	cursor: pointer;
	padding-top: 10px;
	padding-bottom: 10px;
}

.breakLine {
	word-break: break-word;
}
</style>
