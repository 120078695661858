<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{
							id ? "Editar Pergunta frequente" : "Cadastrar Pergunta frequente"
						}}
					</v-card-title>
					<v-col class="pl-5 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="12">
								<v-text-field
									v-model="faq.FAQPER"
									label="Pergunta"
									class="required"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="12">
								<v-textarea
									v-model="faq.FAQRES"
									label="Resposta"
									class="required"
									type="textarea"
								></v-textarea>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn
									color="primary"
									depressed
									tile
									class="mr-4"
									@click="save"
									:disabled="loading"
									>Salvar</v-btn
								>
								<v-btn
									color="primary"
									text
									class="mr-4"
									@click="$router.push({ name: 'FaqList' })"
									:disabled="loading"
									>Voltar</v-btn
								>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import FaqService from "../../app/Services/FaqService";

const faqService = FaqService.build();

export default {
	name: "FaqForm",
	components: {},
	data: () => ({
		id: null,
		faq: {},
		loading: false,
	}),
	computed: {},
	mounted() {
		const { id } = this.$route.params;

		if (id) {
			this.id = id;
			this.getFaq();
		}
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		getFaq() {
			this.loading = true;

			return faqService.read({ id: this.id }).then((response) => {
				this.faq = response;
				this.loading = false;
			});
		},
		save() {
			if (!this.faq.FAQPER) {
				this.toast(
					"warning",
					"Informe a pergunta para continuar"
				);
				return;
			}

			if (!this.faq.FAQRES) {
				this.toast(
					"warning",
					"Informe a resposta para continuar"
				);
				return;
			}

			this.loading = true;

			const rest = this.id
				? faqService.update({ id: this.id, ...this.faq })
				: faqService.create(this.faq);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast(
					"success",
					this.id
						? "Group de empresa atualizado com sucesso!"
						: "Group de empresa adicionado com sucesso!"
				);

				this.loading = false;

				this.$router.push({ name: "FaqList" });
			});
		},
	},
};
</script>
<style>
.alignColor {
	vertical-align: bottom;
}
</style>
