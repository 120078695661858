import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import Toasted from "vue-toasted";
import router from "./router/index";
import Utils from "./plugins/utils";
import numeral from "numeral";
import "numeral/locales";

numeral.locale("pt-br");

Vue.use(VueRouter);
Vue.use(Toasted, {
	iconPack: "mdi",
	theme: "bubble",
	position: "top-right",
	duration: 2000
});
Vue.use(Utils);

Vue.filter("toCurrency", function(value) {
	if (typeof value !== "number") {
		return value;
	}
	var formatter = new Intl.NumberFormat([], {
		style: "currency",
		currency: "BRL"
	});
	return formatter.format(value);
});

Vue.config.productionTip = false;

Vue.prototype.numeral = numeral;

export const bus = new Vue();

new Vue({
	router,
	vuetify,
	render: h => h(App)
}).$mount("#app");
