<template>
	<v-autocomplete
		label="Envolvidos"
		color="primary"
		:items="items"
		item-text="NOMUSU"
		item-value="CODUSU"
		:search-input.sync="searchInput"
		:loading="loading"
		:clearable="!readonly"
		multiple
		:readonly="readonly"
		no-data-text="Digite para pesquisar..."
		v-model="selected"
	>
		<template v-slot:selection="{ item }">
			<v-chip
				small
				close
				@click="removeSelection(item, index)"
				@click:close="removeSelection(item, index)"
			>
				{{ getInvolvedItemText(item) }}
			</v-chip>
		</template>
	</v-autocomplete>
</template>

<script>
import PlanActionService from "../../app/Services/PlanActionService";

export default {
	name: "InvolvedSelect",
	props: {
		value: Array,
		vempresa: {
			type: [Number, String],
			required: false
		},
		vunidade: {
			type: [Number, String],
			required: false
		},
		readonly: Boolean
	},
	data() {
		return {
			loading: false,
			searchInput: null,
			empresa: 0,
			unidade: 0,
			items: [],
			selected: this.value,
			timeoutVar: null,
			debounceSearch: this.$utils.registerDebounce(
				this.search,
				1000,
				this.timeoutVar
			)
		};
	},
	created() {
		if (this.empresa > 0 && this.unidade > 0) {
			this.search("");
		}
	},
	watch: {
		vempresa: function(newVal) {
			this.empresa = newVal;
		},
		vunidade: function(newVal) {
			this.unidade = newVal;

			if (this.empresa > 0 && this.unidade > 0) {
				this.items = [];

				this.search("");
			}
		},
		value: {
			handler() {
				this.selected = this.value;
			}
		},
		selected() {
			this.$emit("input", this.selected);
		},
		loading() {
			this.$emit("loading", this.loading);
		},
		items() {
			this.$emit("changeList", this.items);
		},
		searchInput() {
			this.loading = true;

			this.debounceSearch(this.searchInput);
		}
	},
	methods: {
		setCompanyUnit(vempresa, vunidade) {
			if ((this.vempresa != vempresa && this.vunidade != vunidade) || (this.vempresa != vempresa || this.vunidade != vunidade)) {
				this.items = [];
			}

			this.empresa = vempresa;
			this.unidade = vunidade;
		},
		removeSelection(item, index) {
			const toChange = [...this.selected];

			toChange.splice(index, 1);

			this.selected = toChange;
		},
		getInvolvedItemText(item) {
			return `${item.NOMUSU}`;
		},
		async search(value) {
			try {
				let filter = {
					name: value,
					company: this.empresa,
					unit: this.unidade
				};

				const response = await PlanActionService.build().loadUsers(
					filter
				);

				if (response.length < 1) {
					return;
				}

				this.items = [...this.items, ...response];
			} catch (error) {
				this.error = this.getExceptionResponseMessage(error);
				this.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style></style>