<template>
	<v-dialog v-model="process" persistent max-width="1100">
		<v-card>
			<v-card-title class="headline"
				>Visualizar processo</v-card-title
			>

			<v-divider></v-divider>

			<v-card-text>
				<v-row>
					<v-col cols="12" md="12" class="colDialog mt-5">
						<strong>Grupo: </strong>
						<span>{{ process.group ? process.group.DESGRP : '' }}</span>
					</v-col>
					<v-col cols="12" md="12" class="colDialog">
						<strong>Empresa: </strong>
						<span>{{ process.company ? process.company.DESEMP : '' }}</span>
					</v-col>
					<v-col cols="12" md="12" class="colDialog">
						<strong>Unidade: </strong>
						<span>{{ process.unit ? process.unit.DESUNI : '' }}</span>
					</v-col>
					<v-col cols="12" md="12" class="colDialog">
						<strong>Área: </strong>
						<span>{{ process.area ? process.area.DESARE : '' }}</span>
					</v-col>
					<v-col cols="12" md="12" class="colDialog">
						<strong>Título: </strong>
						<span>{{ process.TITPRO }}</span>
					</v-col>
					<v-col cols="12" md="12" class="colDialog" v-if="process.programas.length > 0">
						<strong>Programas: </strong>
						<br/>
						<span>{{ process.programas.map(u => u.programa.TITPROG).join(', ') }}</span>
					</v-col>
					<v-col cols="12" md="12" class="colDialog">
						<strong>Descrição: </strong>
						<v-textarea
							:counter="true"
							readonly
							v-model="process.DESPRO"
							label="Descrição"
						></v-textarea>
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="red darken-1"
					text
					@click="closeRegistration"
					>Fechar</v-btn
				>
				<v-btn
					color="primary darken-1"
					text
					:loading="loading"
					@click="download"
					>Imprimir</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from "../../config/service/standard";

export default {
	name: "ViewProcessModal",
	props: {
		process: {
			type: Object,
			required: true
		}
	},
	mounted() {
		// eslint-disable-next-line no-console
		// console.log(this.process.programas);
	},
	data: () => ({
		loading: false
	}),
	methods: {
		download() {
			this.$root.$Loading.show("Gerando PDF...");

			axios
				.get("/api/process/print-download", {
				responseType: "arraybuffer",
				params: {
					filter: {
						id: this.process.GERPRO
					}
				}
				})
				.then((response) => {
					this.$root.$Loading.hide();

					let blob = new Blob([response.data], {
						type: "application/pdf",
					});

					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = "processos.pdf";
					link.click();
				})
				.finally(() => {
					this.$root.$Loading.hide();
				});
		},
		closeRegistration() {
			this.$emit("close", true);
		}
	}
};
</script>

<style>
strong {
	font-size: 18px !important;
}
.colDialog {
	padding-top: 0px;
	padding-bottom: 0px;
}

.breakLine {
	word-break: break-word;
}
</style>