import Rest from '../../app/Base/Rest';

/**
 * @typedef {BaseDataService}
 */
export default class BaseDataService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/basedata';
}
