<template>
  <div style="background-color: white; height: 100%">
    <v-container grid-list-md>
      <v-row justify="center">
        <v-col cols="12" md="6" sm="12">
          <v-card v-if="!weSentEmail" class="pa-10" flat>
            <v-btn
              @click="$router.push('/login')"
              style="margin-top: -34px; margin-left: -36px"
              text
            >
              <v-icon class="mr-2">mdi-arrow-left</v-icon>Início
            </v-btn>
            <v-card-title class="justify-center mb-0 mt-0 pb-0 pt-0">
              <img class="logo" v-if="covers.length > 0" :src="covers.length > 0 && covers[0].CAPLOG_URL ? covers[0].CAPLOG_URL : '../assets/logo.jpg'" width="200" />
            </v-card-title>

            <v-subheader class="justify-center">
              <h4 class="mt-0">Crie sua conta agora</h4>
            </v-subheader>

            <v-col cols="12" md="12">
              <v-row>
                <v-text-field
                  ref="name"
                  v-model="register.NOMUSU"
                  label="Nome completo"
                  placeholder="Informe seu nome completo"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  type="email"
                  :rules="[validEmailRule]"
                  v-model="register.EMAUSU"
                  label="E-mail"
                  placeholder="Digite seu e-mail"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  :rules="[passwordLongEnoughRule]"
                  v-model="register.SENUSU"
                  label="Senha"
                  type="password"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  :rules="[passwordsMatchingRule]"
                  v-model="register.password_repeat"
                  label="Repetir a senha"
                  type="password"
                ></v-text-field>
              </v-row>
              <v-row v-if="false">
                <v-select
                  readonly
                  v-model="register.TIPUSU"
                  :items="user_types"
                  item-value="id"
                  item-text="text"
                  label="Perfil"
                ></v-select>
              </v-row>
              <v-row>
                <p style="font-weight: bold;">
                  Clique
                  <a @click="openTerm">aqui</a> para ler termos de utilização
                </p>
              </v-row>
              <v-row>
                <v-checkbox
                  color="primary"
                  class="display-3 font-weight-light"
                  v-model="acceptTerm"
                  label="Eu li e concordo com os termos de uso"
                ></v-checkbox>
              </v-row>
              <v-row class="mt-5">
                <v-btn @click="registerUser" block color="#3F48CC" dark tile depressed>
                  <div v-if="!signingUp">Cadastrar</div>
                  <v-progress-circular
                    v-if="signingUp"
                    size="20"
                    width="2"
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>
              </v-row>
            </v-col>
          </v-card>

          <v-card v-if="weSentEmail" class="pa-6" flat>
            <v-card-title
              style="word-break: break-word !important;"
              class="justify-center mb-0 mt-0 pb-0 pt-0"
            >
              <v-icon color="green" class="pr-2 pb-4" size="100">mdi-check-circle-outline</v-icon>
              <p>Enviamos um link de confirmação para o seu e-mail, ative sua conta para fazer o login</p>
            </v-card-title>
            <v-col cols="12" md="12">
              <v-row class="mt-5">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="$router.push('/login')"
                  block
                  depressed
                >Voltar ao login</v-btn>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-dialog v-model="termDialog" persistent width="900">
          <v-card>
            <v-card-title class="headline lighten-2" primary-title>Termos de uso</v-card-title>

            <v-card-text class="term">
              <v-textarea readonly v-model="term"></v-textarea>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                block
                depressed
                @click="closeDialog"
              >Li os termos e desejo continuar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import UserService from '../app/Services/UserService';
import TermService from '../app/Services/TermService';
import PublicService from "../app/Services/PublicService";

import moment from 'moment';

const userService = UserService.build();
const termService = TermService.build();

export default {
	name: 'SignUp',
	computed: {
		passwordsMatchingRule() {
			return () => this.register.SENUSU === this.register.password_repeat || 'Senhas não correspondem';
		},
		passwordLongEnoughRule() {
			return () => this.register.SENUSU.length >= 6 || 'A senha precisa ter no mínimo 6 caracteres';
		},
		validEmailRule() {
			return this.isEmailValid() || 'Informe um e-mail válido';
		},
	},
	async mounted() {
    this.$nextTick(() => this.$refs.name.$refs.input.focus());

		await this.getCovers();
		this.getTerm();
	},
	data: () => ({
		weSentEmail: false,
		signingUp: false,
		termDialog: false,
		openedTerm: false,
		termOpenedDate: '',
		register: {
			EMAUSU: '',
			SENUSU: '',
			password_repeat: '',
			TIPUSU: 'U',
			ACEVAG: true,
		},
		acceptTerm: false,
		term: null,
		user_types: [
			{
				id: 'U',
				text: 'Usuário',
			},
			{
				id: 'E',
				text: 'Empresa',
			},
		],
		covers: []
	}),
	methods: {
		closeDialog() {
			this.termDialog = false;
			this.termOpenedDate = moment().format('YYYY-MM-DD HH:mm:ss');
		},
		openTerm() {
			this.termDialog = true;
		},
		getTerm() {
			termService.getCurrent().then((term) => {
				this.term = term.DESTER;
			});
		},
		toast(type, message, icon = 'mdi-check') {
			setTimeout(() => {
				this.$toasted.show(message, {
					type: type,
					icon: icon,
				});
				this.stopLoading();
			}, 1000);
		},
		isEmailValid() {
			/*eslint no-useless-escape: 0 */
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(this.register.EMAUSU).toLowerCase());
		},
		startLoading() {
			this.signingUp = true;
		},
		stopLoading() {
			this.signingUp = false;
		},
		verifyEmail() {
			return userService.verifyEmail(this.register.EMAUSU);
		},
		createUser() {
			this.register.DATTER = this.termOpenedDate ? this.termOpenedDate : moment().format('YYYY-MM-DD HH:mm:ss');
			this.register.DATCRI = moment().format('YYYY-MM-DD HH:mm:ss');

			return userService.create(this.register).then(() => {
				// this.toast('success', 'Para a!');

				this.weSentEmail = true;

				// setTimeout(() => {
				// 	this.$router.push('/login');
				// }, 1000);
			});
		},
		registerUser() {
      this.startLoading();

      if (!this.register.NOMUSU) {
        this.toast('warning', 'Favor informar o nome completo para continuar');
        return;
      }

			if (!this.register.EMAUSU) {
				this.toast('warning', 'Favor informar o e-mail para continuar');
				return;
			}

			if (!this.isEmailValid()) {
				this.toast('warning', 'Informe um e-mail válido para continuar');
				return;
			}

			if (!(this.register.SENUSU.length >= 6)) {
				this.toast('warning', 'A senha precisa ter no mínimo 6 caracteres');
				return;
			}

			if (!(this.register.SENUSU === this.register.password_repeat)) {
				this.toast('warning', 'As senhas informadas não correspondem');
				return;
			}

			if (!this.register.SENUSU || !this.register.password_repeat) {
				this.toast('warning', 'Favor informar a senha corretamente para continuar');
				return;
			}

			if (!this.acceptTerm) {
				this.toast('warning', 'Você precisa aceitar os termos de uso para se cadastrar');
				return;
			}

			if (!this.termOpenedDate) {
				this.toast('warning', 'É necessário abrir e ler os termos de uso para continuar');
				return;
			}

			this.verifyEmail().then((response) => {
				if (response.exists) {
					this.toast('error', 'O e-mail informado já está cadastrado', 'mdi-account-remove');
					return;
				}

				this.createUser();
			});
		},
		async getCovers() {
			const response = await PublicService.build().coverImages();

			this.covers = response;
		}
	},
};
</script>

<style>
.term textarea {
	margin-top: 0px;
	margin-bottom: 0px;
	height: 479px;
}
</style>
