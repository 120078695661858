import Rest from '../../app/Base/Rest';

/**
 * @typedef {RoleService}
 */
export default class RoleService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/roles';
}