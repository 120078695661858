<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="mt-2 pb-8 mb-5" flat>
					<v-card-title>
						<v-subheader>
							<span class="title font-weight-normal">Filtrar usuários</span>
						</v-subheader>
						<v-spacer></v-spacer>
						<v-btn class="white--text mr-2" depressed color="#34bfa3" @click="$router.push('/user/new')">+ Novo</v-btn>
						<v-btn class="white--text" depressed color="info" @click="filter()">Buscar</v-btn>
					</v-card-title>
					<v-spacer></v-spacer>
					<v-divider></v-divider>
					<v-card-text>
						<v-row class="pl-6">
							<v-col cols="12" md="4" sm="12" xs="12" lg="3">
								<v-text-field label="Nome" v-model="filters.name"></v-text-field>
							</v-col>
							<v-col cols="12" md="2" sm="12" xs="12" lg="3">
								<v-text-field label="E-mail" v-model="filters.email"></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<v-card flat>
					<v-data-table
						:headers="headers"
						:items="items"
						:items-per-page="pagination.itemsPerPage"
						:loading="loadingKeys"
						:hide-default-footer="true"
						:page.sync="pagination.page"
						@update:pagination="pagination"
					>
						<template v-slot:body="{ items }">
							<tr v-for="item in items" :key="item.EMAUSU">
								<td>{{item.CODUSU}}</td>
								<td>{{item.NOMUSU}}</td>
								<td>{{item.EMAUSU ? item.EMAUSU : 'Não informado'}}</td>
								<td>{{item.role ? item.role.NOMPRF : 'Sem perfil'}}</td>
								<td>{{item.SITUSU == 'A' ? 'Ativo' : 'Inativo'}}</td>
								<td>
									<v-btn @click="edit(item)" icon>
										<v-icon>mdi-account-edit</v-icon>
									</v-btn>
									<v-btn v-if="item.CHVVRF" @click="reSendMail(item)" icon :loading="loadingMail">
										<v-icon>mdi-email</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>

					<pagination
						v-model="pagination"
						:loading="loading"
						@change="loadData"
					/>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import UserService from "../../app/Services/UserService";
import pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import SortableMixin from "@/components/pagination/sort/SortableMixin";

const userService = UserService.build();

export default {
	name: "UserList",
	components: {
		pagination
	},
	mixins: [PaginationMixin, SortableMixin],
	mounted() {
		this.fetch();
	},
	data: () => ({
		loading: false,
		loadingMail: false,
		filters: {},
		headers: [
			{
				text: "Código",
				align: "left",
				sortable: false,
				value: "status"
			},
			{
				text: "Nome",
				align: "left",
				sortable: false,
				value: "status"
			},
			{ text: "E-mail", value: "name" },
			{
				text: "Tipo",
				value: "company"
			},
			{
				text: "Status",
				value: "company"
			},
			{
				text: "Ações",
				value: "edit"
			}
		],
		items: []
	}),
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		edit(user) {
			this.$router.push({
				name: "UserEdit",
				params: {
					id: user.CODUSU
				}
			});
		},
		fetch() {
			this.loading = true;
			let filter = {};

			if (this.filters.name) {
				filter.name = this.filters.name
			}

			if (this.filters.email) {
				filter.email = this.filters.email
			}

			userService.search(filter).then(response => {
				this.items = response;

				this.paginate({
					total: response.length || 0,
					last_page: Math.round(response.length / this.pagination.itemsPerPage),
					from: 1,
					to: 1,
					data: {}
				});

				this.loading = false;
			});
		},
		loadData(page) {
			this.pagination.from = this.pagination.page;
			this.pagination.to = page;
		},
		filter() {
			this.loading = true;
			this.items = [];

			this.fetch();
		},
		reSendMail (item) {
			this.loadingMail = true;
			userService.reSendMail(item).then(response => {
				this.loadingMail = false;

				if (response.error) {
					this.toast(
						"warning",
						response.errorMessage
					);
					return;
				}

				this.toast(
					"success",
					"Email reenviado com sucesso!"
				);
			})
			.catch(() => {
				this.loadingMail = false;
				this.toast(
					"warning",
					"Ocorreu um erro ao reenviar o email!"
				);
			});
		}
	}
};
</script>
