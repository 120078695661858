<template>
	<v-dialog v-model="registerFormModal" persistent max-width="1100">
		<v-card>
			<v-card-title class="headline">Cadastrar plano de ação</v-card-title>

			<v-divider></v-divider>

			<PlanActionForm ref="PlanActionForm" v-on:onsave="loadingData" v-on:saved="closeRegistration" />

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="red darken-1" text v-show="!loadingFormModal" @click="closeRegistration">Fechar</v-btn>
				<v-btn color="primary darken-1" text :loading="loadingFormModal" @click="confirmRegistration">Salvar</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import PlanActionForm from './PlanActionForm.vue';

export default {
	name: "FormModal",
	components: {
		PlanActionForm: PlanActionForm
	},
	data: () => ({
		registerFormModal: false,
		loadingFormModal: false,
	}),
	mounted() {
		this.$root.$LoadingBar.hide();
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		openRegistration (data) {
			const vue = this;

			this.registerFormModal = true;

			setTimeout(function () {
				vue.$refs.PlanActionForm.setIsInModal(true);
				vue.$refs.PlanActionForm.setModalData(data);
			}, 100)
		},
		closeRegistration () {
			this.loadingFormModal = false;
			this.registerFormModal = false;
			this.clearRegistration();

			this.$emit('close', true);
		},
		clearRegistration () {
			this.$refs.PlanActionForm.setIsInModal(false);
			this.registerFormModal = false;
			this.loadingFormModal = false;
		},
		confirmRegistration () {
			this.$refs.PlanActionForm.save();
		},
		loadingData () {
			this.loadingFormModal = true;
		}
	}
};
</script>

<style>
	.colDialog {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.breakLine {
		word-break: break-word;
	}
</style>
