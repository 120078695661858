<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<v-card flat :loading="loading">
					<v-card-title> Impressão do planejamento estrategico </v-card-title>

					<v-card-text>
						Selecione o planejamento estratégico para continuar...

						<v-row class="mt-5">
							<v-col cols="4">
								<v-select
									v-model="filter.company"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									:loading="loadingCompany"
									@change="getUnits"
								></v-select>
							</v-col>
							<v-col cols="4">
								<v-select
									v-model="filter.unit"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									:loading="loadingUnit"
								></v-select>
							</v-col>
							<v-col cols="4">
								<v-select
									v-model="filter.strategicPlanning"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									label="Planejamento estrategico"
									:loading="loadingPlanning"
									hide-details
								></v-select>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="2">
								<v-btn
									class="white--text mt-3"
									depressed
									color="info"
									@click="print()"
									:loading="loading"
									block
									>Imprimir</v-btn
								>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "../../config/service/standard";

import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";

const companyService = CompanyService.build();
const unitService = UnitService.build();
const strategicPlanningService = StrategicPlanningService.build();

export default {
	name: "PlanActionRegister",
	data: () => ({
		loading: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingPlanning: false,
		registerPlanAction: false,
		editValues: false,
		loadingPlanningRegistration: false,
		strategicPlannings: [],
		companies: [],
		units: [],
		itens: [],
		registrationPlanning: [],
		status: [
			{ id: "A", description: "Aberto" },
			{ id: "F", description: "Fechado" },
		],
		filter: {
			company: "",
			unit: "",
			strategicPlanning: "",
		},
		registration: {
			id: null,
			companyName: "",
			unitName: "",
			planningName: "",
			areaName: "",
			message: "",
			percent: 0,
			status: "",
		},
		planningHeaders: [
			{ text: "Data", align: "center", sortable: false, width: "12%" },
			{ text: "Hora", align: "center", sortable: false, width: "12%" },
			{ text: "Usuário", align: "center", sortable: false, width: "15%" },
			{ text: "Descrição", align: "left", sortable: false },
		],
		defaultValues: {},
	}),
	mounted() {
		this.defaultValues = JSON.parse(localStorage.getItem("default_filters"));
		this.getCompanies();
		this.getStrategicPlannings();
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;

				if (this.companies.length === 1 || this.defaultValues.CODEMP) {
					this.filter.company =
						this.defaultValues.CODEMP ?? this.companies[0].CODEMP;
					this.getUnits();

					this.checkAndSearch();
				}

				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.units = [];
			this.loadingUnit = true;

			if (this.filter.company) {
				search.company = this.filter.company;
			}

			return unitService.search(search).then((response) => {
				this.units = response;

				if (this.units.length === 1 || this.defaultValues.CODUNI) {
					this.filter.unit = this.defaultValues.CODUNI ?? this.units[0].CODUNI;

					this.checkAndSearch();
				}

				this.loadingUnit = false;
			});
		},
		print() {
			if (!this.filter.company) {
				this.toast("warning", "Informe a empresa para continuar");
				return;
			}

			if (!this.filter.unit) {
				this.toast("warning", "Informe a unidade para continuar");
				return;
			}

			if (!this.filter.strategicPlanning) {
				this.toast(
					"warning",
					"Informe o planejamento estrategico para continuar"
				);
				return;
			}

			this.loading = true;

			axios
				.get("/api/strategicplanning/print", {
					responseType: "arraybuffer",
					params: this.filter,
				})
				.then((response) => {
					let blob = new Blob([response.data], {
						type: "application/pdf",
					});
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = "planejamento-estrategico" + ".pdf";
					link.click();
				})
				.finally(() => {
					this.loading = false;
				});
		},
		getStrategicPlannings() {
			this.loadingPlanning = true;
			const userData = JSON.parse(localStorage.getItem("user"));

			strategicPlanningService
				.getPlanning({ codusu: userData.user.CODUSU })
				.then((response) => {
					this.strategicPlannings = response;

					if (
						this.strategicPlannings.length === 1 ||
						this.defaultValues.CODPLE
					) {
						this.filter.strategicPlanning =
							this.defaultValues.CODPLE ?? this.strategicPlannings[0].CODPLE;

						this.checkAndSearch();
					}

					this.loadingPlanning = false;
				});
		},
		checkAndSearch() {
			if (
				this.filter.company &&
				this.filter.unit &&
				this.filter.strategicPlanning
			) {
				this.print();
			}
		},
	},
};
</script>

<style>
.aggroup {
	background-color: #eaeaea;
}

.aggroup > td {
	font-size: 0.805rem;
	height: 40px;
}

.aggroupLine > td {
	font-size: 0.755rem;
	height: 40px;
}

.borderRight {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}

.colDialog {
	padding-top: 0px;
	padding-bottom: 0px;
}

.divToClick {
	cursor: pointer;
	padding-top: 10px;
	padding-bottom: 10px;
}

.breakLine {
	word-break: break-word;
}
</style>
