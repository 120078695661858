<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="mt-2 pb-8 mb-5" flat>
					<v-card-title>
						<v-subheader>
							<span class="title font-weight-normal">Filtrar termos</span>
						</v-subheader>
						<v-spacer></v-spacer>
						<v-btn class="white--text mr-2" depressed color="#34bfa3" @click="$router.push('/term/new')">+ Novo</v-btn>
						<v-btn class="white--text" depressed color="info">Buscar</v-btn>
					</v-card-title>
					<v-spacer></v-spacer>
					<v-divider></v-divider>
					<v-card-text>
						<v-row class="pl-6">
							<v-col cols="12" md="4" sm="12" xs="12" lg="3">
								<v-text-field label="Descrição"></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<v-card flat>
					<v-data-table
						:headers="headers"
						:items="items"
						:items-per-page="10"
						:loading="loading"
						:footer-props="{'items-per-page-text': 'Itens por página', 'items-per-page-all-text': 'Todos'}"
					>
						<template v-slot:body="{ items }">
							<tr v-for="(item, index) in items" :key="item.CODTER">
								<td>{{item.CODTER}}</td>
								<td>{{item.STATUS == '1' ? 'Ativo' : 'Inativo'}}</td>
								<td>{{item.DESTER.substring(0, 100)}}</td>
								<td>
								<v-btn @click="edit(item)" icon>
									<v-icon>mdi-account-edit</v-icon>
								</v-btn>
								</td>
								<td>
								<v-btn @click="remove(item.CODTER, index)" icon>
									<v-icon style="color: red">mdi-close</v-icon>
								</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import TermService from '../../app/Services/TermService';

const termService = TermService.build();

export default {
	name: 'TermList',
	computed: {},
	mounted() {
		this.fetch();
	},
	data: () => ({
		headers: [
			{
				text: 'Código',
				align: 'left',
				sortable: false,
				value: 'status',
			},
			{
				text: 'Ativo',
				align: 'left',
				sortable: false,
				value: 'status',
			},
			{
				text: 'Descrição',
				align: 'left',
				sortable: false,
				value: 'status',
			},
			{
				text: '',
				align: 'left',
				sortable: false,
				value: 'actions',
			},
			{
				text: '',
				align: 'left',
				sortable: false,
				value: 'actions',
			},
		],
		loading: false,
		items: [],
	}),
	methods: {
		edit(term) {
			this.$router.push({
				name: 'TermEdit',
				params: {
					id: term.CODTER,
				},
			});
		},
		remove(id, index) {
			return termService
				.destroy({
					id,
				})
				.then(() => {
					this.items = this.items.filter((item, itemIndex) => {
						return itemIndex !== index;
					});

					this.$toasted.success('Termo removido com sucesso!', {
						icon: 'mdi-check',
					});
				});
		},
		fetch() {
			this.loading = true;

			return termService.search({}).then((response) => {
				this.items = response;

				this.loading = false;
			});
		},
	},
};
</script>
