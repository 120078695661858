<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ view ? 'Visualizar dados base' : (id ? 'Editar dados base' : 'Cadastrar dados base') }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="baseData.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									class="required"
									:loading="loadingCompany"
									@change="getUnits"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="baseData.CODUNI"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									class="required"
									:loading="loadingUnit"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="baseData.CODPLE"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									label="Planejamento estratégico"
									class="required"
									:loading="loadingPlanning"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="6">
								<v-textarea v-model="baseData.MISSAO" label="Missão" class="" :disabled="view"></v-textarea>
							</v-col>
							<v-col cols="12" md="6">
								<v-textarea v-model="baseData.VISAO" label="Visão" class="" :disabled="view"></v-textarea>
							</v-col>
							<v-col cols="12" md="6">
								<v-textarea v-model="baseData.VALORE" label="Valores" class="" :disabled="view"></v-textarea>
							</v-col>
							<v-col cols="12" md="6">
								<v-textarea v-model="baseData.PROPOS" label="Propósito" class="" :disabled="view"></v-textarea>
							</v-col>
							<v-col cols="12" md="6">
								<v-textarea v-model="baseData.OBJCORP" label="Objetivo Corporativo (Expectativa do Investidor)" class="" :disabled="view"></v-textarea>
							</v-col>
							<v-col cols="12" md="6">
								<v-textarea v-model="baseData.OBJMAC" label="Objetivo Macro (Expectativa da Gestão)" class="" :disabled="view"></v-textarea>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn color="primary" depressed tile class="mr-4" @click="save" :disabled="loading" v-show="!view">Salvar</v-btn>
								<v-btn color="primary" text class="mr-4" @click="$router.push('/base-data/list')" :disabled="loading">Voltar</v-btn>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import BaseDataService from "../../app/Services/BaseDataService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";

const baseDataService = BaseDataService.build();
const strategicPlanningService = StrategicPlanningService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();

export default {
	name: "BaseDataForm",
	data: () => ({
		id: null,
		view: false,
		menuDtPicker: false,
		menuDtPicker2: false,
		strategicPlannings: [],
		companies: [],
		units: [],
		baseData: {},
		loading: false,
		loadingPlanning: false,
		loadingCompany: false,
		loadingUnit: false
	}),
	mounted() {
		const { id, view } = this.$route.params;

		if (id) {
			this.id = id;
		}

		if (view) {
			this.view = view;
		}

		this.fetch();
	},
	computed: {
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		save() {
			if (!this.baseData.CODPLE) {
				this.toast(
					"warning",
					"Informe a planejamento estratégico para continuar"
				);
				return;
			}

			if (!this.baseData.CODEMP) {
				this.toast(
					"warning",
					"Informe a empresa para continuar"
				);
				return;
			}

			if (!this.baseData.CODUNI) {
				this.toast(
					"warning",
					"Informe a unidade para continuar"
				);
				return;
			}

			this.loading = true;

			const rest = this.id ? baseDataService.update({ id: this.id, ...this.baseData }) : baseDataService.create(this.baseData);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast('success', this.id ? 'Dados base atualizado com sucesso!' : 'Dados base adicionado com sucesso!');
				this.loading = false;

				this.$router.push('/base-data/list');
			});
		},
		getBaseData () {
			this.loading = true;

			return baseDataService.read({ id: this.id }).then((response) => {
				this.baseData = response;
				this.loading = false;
			});
		},
		getStrategicPlannings() {
			this.loadingPlanning = true;
			const userData = JSON.parse(localStorage.getItem("user"));

			return strategicPlanningService.getPlanning({ codusu: userData.user.CODUSU }).then(response => {			
				this.strategicPlannings = response;
				this.loadingPlanning = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.units = [];
			this.loadingUnit = true;

			if (this.baseData.CODEMP) {
				search.company = this.baseData.CODEMP;
			}

			return unitService.search(search).then((response) => {
				this.units = response;
				this.loadingUnit = false;
			});
		},
		fetch () {
			const funcs = [];

			if (this.id) {
				funcs.push(this.getBaseData());
			}

			funcs.push(this.getStrategicPlannings());
			funcs.push(this.getCompanies());

			Promise.all(funcs)
				.then(() => {
					this.getUnits();
				})
				.finally(() => {
				});
		}
	}
};
</script>
