<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{
							id
								? "Editar planejamento estratégico"
								: showCopy
								? "Copiar planejamento estratégico"
								: "Cadastrar planejamento estratégico"
						}}
					</v-card-title>
					<v-col
						v-if="showCopy"
						class="pl-10 pr-10 pb-5 mb-8"
						cols="12"
						md="12"
					>
						<v-card-text>
							Selecione o planejamento estratégico para continuar...

							<v-row class="mt-5">
								<v-col cols="12" md="3">
									<v-select
										class="required"
										v-model="strategicPlanning.CODGRP"
										:items="companyGroups"
										item-text="DESGRP"
										item-value="CODGRP"
										label="Grupo de empresa"
										:loading="loadingCompany"
										hide-details
										@change="getCompanies"
									></v-select>
								</v-col>
								<v-col cols="12" md="3">
									<v-select
										class="required"
										v-model="strategicPlanning.CODPLE"
										:items="strategicPlannings"
										item-text="DESPLE"
										item-value="CODPLE"
										label="Planejamento estratégico"
										:loading="loadingPlanning"
										hide-details
									></v-select>
								</v-col>
								<v-col cols="12" md="3">
									<v-select
										v-model="strategicPlanning.CODEMP"
										:items="companies"
										item-text="DESEMP"
										item-value="CODEMP"
										label="Empresa"
										:loading="loadingCompany"
										@change="getUnits"
										class="required"
									></v-select>
								</v-col>
								<v-col cols="12" md="3">
									<v-select
										v-model="strategicPlanning.CODUNI"
										:items="units"
										item-text="DESUNI"
										item-value="CODUNI"
										label="Unidade"
										:loading="loadingUnit"
										@change="handleChangeGetPermUser"
										class="required"
									></v-select>
								</v-col>

								<v-col cols="12" md="3">
									<v-btn
										class="white--text mt-3"
										depressed
										color="info"
										@click="getIndicators()"
										:loading="loading"
										>Buscar</v-btn
									>
								</v-col>
							</v-row>
						</v-card-text>
						<v-row>
							<v-col cols="12" md="3">
								<v-checkbox
									v-model="itensToCopy"
									v-for="(item, index) in itensForCopy"
									:key="index"
									:label="item.description"
									:value="item.id"
									hide-details
									:disabled="loading"
									@change="checkItenChecked"
								/>
							</v-col>
							<v-col cols="12" md="3" class="mt-10">
								<v-checkbox
									v-model="itensToCopyPerspective"
									v-for="(item, index) in perspectiveItens"
									:key="index"
									:label="item.description"
									:value="item.id"
									hide-details
									:disabled="!showPerspective"
									@change="checkItenPerspectiveChecked"
								/>
							</v-col>
							<v-col cols="12" md="3" class="mt-10">
								<v-checkbox
									v-model="itensToCopySubPerspective"
									v-for="(item, index) in perspectiveSubItens"
									:key="index"
									:label="item.description"
									:value="item.id"
									hide-details
									:disabled="!showSubPerspective"
									@change="checkItenIndicatorChecked"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="12">
								<v-select
									v-model="itensToCopyUsers"
									:items="users"
									label="Usuários"
									item-text="user.NOMUSU"
									item-value="user"
									hint="Selecione os usuários que terão permissão"
									:loading="loadingUsers"
									multiple
									persistent-hint
								>
									<template v-slot:prepend-item>
										<v-list-item ripple @mousedown.prevent @click="toggle">
											<v-list-item-action>
												<v-icon
													:color="
														itensToCopyUsers.length > 0 ? 'indigo darken-4' : ''
													"
												>
													{{ icon }}
												</v-icon>
											</v-list-item-action>
											<v-list-item-content>
												<v-list-item-title> Todos </v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-divider class="mt-2"></v-divider>
									</template>

									<template v-slot:selection="{ item, index }">
										<v-chip
											:close="true"
											@click:close="removeUser(index)"
											v-if="item.user"
										>
											<span>{{ item.user.NOMUSU }}</span>
										</v-chip>
									</template>
								</v-select>
							</v-col>
						</v-row>
					</v-col>

					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row v-if="showCopy">
							<v-col cols="12" md="12">
								<v-card-text>
									Selecione o planejamento estratégico de destino

									<v-select
										class="required"
										v-model="strategicPlanning.TARGETCODPLE"
										:items="strategicPlannings"
										item-text="DESPLE"
										item-value="CODPLE"
										label="Planejamento estratégico de destino"
										:loading="loadingPlanning"
										hide-details
									></v-select>
								</v-card-text>
							</v-col>
						</v-row>
						<v-row v-if="!showCopy">
							<v-col cols="12" md="3">
								<v-select
									v-model="strategicPlanning.CODGRP"
									:items="companyGroups"
									item-text="DESGRP"
									item-value="CODGRP"
									class="required"
									label="Grupo de empresa"
									:loading="loadingCompany"
									:disabled="disabled"
								></v-select>
							</v-col>
							<v-col cols="12" md="7">
								<v-text-field
									v-model="strategicPlanning.DESPLE"
									label="Nome"
									class="required"
									:disabled="disabled"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="2">
								<v-text-field
									v-model="strategicPlanning.DESABR"
									label="Abreviação"
									class="required"
									maxlength="10"
									:disabled="disabled"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="3">
								<v-select
									v-model="strategicPlanning.PERIOD"
									:items="periods"
									item-text="description"
									item-value="id"
									label="Periodicidade"
									:disabled="disabled"
								></v-select>
							</v-col>
							<v-col cols="12" md="2">
								<v-text-field
									type="number"
									v-model="strategicPlanning.QTDPER"
									label="Qtd. períodos"
									class="required"
									:disabled="disabled"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="2">
								<v-text-field
									v-model="strategicPlanning.DESPER"
									label="Desc. períodos"
									class="required"
									:disabled="disabled"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="2">
								<v-text-field
									type="number"
									v-model="strategicPlanning.PERATV"
									label="Período ativo"
									class="required"
									:disabled="disabled"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="2">
								<v-select
									:items="status"
									item-text="description"
									item-value="id"
									v-model="strategicPlanning.STATUS"
									label="Status"
									class="required"
									:disabled="disabled"
								></v-select>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn
									color="primary"
									depressed
									tile
									class="mr-4"
									@click="save"
									:disabled="loading"
									>{{ showCopy ? "Copiar" : "Salvar" }}</v-btn
								>
								<v-btn
									color="primary"
									text
									class="mr-4"
									@click="$router.push('/strategic-planning/list')"
									:disabled="loading"
									>Voltar</v-btn
								>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import CompanyGroupService from "../../app/Services/CompanyGroupService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";

const strategicPlanningService = StrategicPlanningService.build();
const companyGroupService = CompanyGroupService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();

export default {
	name: "StrategicPlanningForm",
	data: () => ({
		showMessagePlanAction: false,
		showMessageObjective: false,
		showMessageIndicator: false,
		showCopy: false,
		disabled: false,
		showPerspective: false,
		showSubPerspective: false,
		id: null,
		companyGroups: [],
		strategicPlannings: [],
		units: [],
		periods: [
			{ id: "M", description: "Mensalmente" },
			{ id: "T", description: "Trimestralmente" },
			{ id: "S", description: "Semestralmente" },
		],
		status: [
			{ id: 1, description: "Ativo" },
			{ id: 0, description: "Inativo" },
		],
		filter: {
			strategicPlanning: "",
		},
		itensToCopy: [],
		itensToCopyPerspective: [],
		itensToCopySubPerspective: [],
		itensForCopy: [
			{ id: 1, description: "Perspectivas" },
			{ id: 2, description: "Objetivos Estratégicos" },
			{ id: 3, description: "Dados Base" },
			{ id: 4, description: "Diagnóstico" },
			{ id: 5, description: "Faixa de Desempenho" },
		],
		perspectiveItens: [{ id: 1, description: "Indicadores" }],
		perspectiveSubItens: [{ id: 1, description: "Planos de Ação" }],
		strategicPlanning: {},
		loading: false,
		loadingCompany: false,
		companies: [],
		loadingUnit: false,
		users: [],
		itensToCopyUsers: [],
		loadingUsers: false,
	}),
	mounted() {
		const { id, type } = this.$route.params;

		if (id) {
			this.id = id;
			this.getCompanyGroups();
			this.getStrategicPlanning();
		}

		if (type && type === "copy") {
			this.showCopy = true;
			this.disabled = true;
			this.getStrategicPlannings();
		}

		this.fetch();
	},
	computed: {
		likesAllFruit() {
			return this.itensToCopyUsers.length === this.users.length;
		},
		likesSomeFruit() {
			return this.itensToCopyUsers.length > 0 && !this.likesAllFruit;
		},
		icon() {
			if (this.likesAllFruit) return "mdi-close-box";
			if (this.likesSomeFruit) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		save() {
			if (!this.strategicPlanning.CODGRP) {
				this.toast("warning", "Informe o grupo de empresa para continuar");
				return;
			}

			if (this.showCopy) {
				if (!this.strategicPlanning.CODPLE) {
					this.toast(
						"warning",
						"Informe o nome do planejamento estratégico para continuar"
					);
					return;
				}

				if (!this.strategicPlanning.CODEMP) {
					this.toast(
						"warning",
						"Informe a empresa do planejamento estratégico para continuar"
					);
					return;
				}

				if (!this.strategicPlanning.CODUNI) {
					this.toast(
						"warning",
						"Informe a unidade do planejamento estratégico para continuar"
					);
					return;
				}

				if (!this.strategicPlanning.TARGETCODPLE) {
					this.toast(
						"warning",
						"Informe o nome do planejamento estratégico de destino para continuar"
					);
					return;
				}

				if (this.itensToCopyUsers.length == 0) {
					this.toast(
						"warning",
						"Informe selecione ao menos um usuário para continuar"
					);
					return;
				}
			}

			if (this.showCopy) {
				this.copy();
				return;
			}

			this.loading = true;

			const rest = this.id
				? strategicPlanningService.update({
						id: this.id,
						...this.strategicPlanning,
				})
				: strategicPlanningService.create(this.strategicPlanning);

			rest.then((response) => {
				if (!response) {
					this.loading = false;
					return;
				}

				this.toast(
					"success",
					this.id
						? "Planejamento estratégico atualizada com sucesso!"
						: "Planejamento estratégico adicionada com sucesso!"
				);

				this.loading = false;

				this.$router.push("/strategic-planning/list");
			});
		},
		copy() {
			const data = {};
			this.loading = true;
			data.strategicPlanningId = this.strategicPlanning.strategicPlanning;
			data.strategicPlanning = this.strategicPlanning;
			data.copyPerspective = this.itensToCopy.includes(1);
			data.copyPlanAction = this.itensToCopySubPerspective.includes(1);
			data.copyDataBase = this.itensToCopy.includes(3);
			data.copyDiagnostic = this.itensToCopy.includes(4);
			data.copyRangeIndicator = this.itensToCopy.includes(5);
			data.copyObjectives = this.itensToCopy.includes(2);
			data.copyIndicators = this.itensToCopyPerspective.includes(1);
			data.copyUsers = this.itensToCopyUsers.map((item) => item.CODUSU);

			// eslint-disable-next-line no-unreachable
			strategicPlanningService.copy({ ...data }).then((response) => {
				if (!response) {
					this.loading = false;
					return;
				}

				this.toast("success", "Planejamento copiado com sucesso!");

				this.loading = false;

				this.$router.push("/strategic-planning/list");
			});
		},
		getStrategicPlanning() {
			this.loading = true;

			return strategicPlanningService.read({ id: this.id }).then((response) => {
				this.strategicPlanning = response;
				this.loading = false;

				if (this.showCopy) {
					this.disabled = false;
				}
			});
		},
		getCompanyGroups() {
			this.loadingCompany = true;

			return companyGroupService.search({}).then((response) => {
				this.companyGroups = response;
				this.loadingCompany = false;
			});
		},
		getStrategicPlannings() {
			this.loadingPlanning = true;
			strategicPlanningService.search({}).then((response) => {
				this.strategicPlannings = response;

				if (this.showCopy) {
					this.disabled = false;
				}

				if (this.strategicPlannings.length === 1) {
					this.strategicPlanning.strategicPlanning =
						this.strategicPlannings[0].CODPLE;
				}

				this.loadingPlanning = false;
			});
		},
		/*
		getStrategicPlannings () {
			this.loading = true;
			strategicPlanningService.search({}).then((response) => {
				this.strategicPlannings = response;
				this.loading = false;
			});
		},
        */
		/*
		selectPlanning () {
			this.id = this.strategicPlanning.strategicPlanning;
			this.getStrategicPlanning();
		},
        */
		fetch() {
			Promise.all([this.getCompanyGroups()])
				.then(() => {})
				.finally(() => {});
		},
		getCompanies() {
			const search = {};

			this.strategicPlanning.CODEMP = "";
			this.companies = [];
			this.strategicPlanning.CODUNI = "";
			this.units = [];
			this.loadingCompany = true;

			if (this.strategicPlanning.CODGRP) {
				search.companyGroup = this.strategicPlanning.CODGRP;
			}

			return companyService.search(search).then((response) => {
				this.companies = response;

				if (this.companies.length === 1) {
					this.strategicPlanning.CODEMP = this.companies[0].CODEMP;
					this.getUnits();
				}

				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.strategicPlanning.CODUNI = "";
			this.units = [];
			this.loadingUnit = true;

			if (this.strategicPlanning.CODEMP) {
				search.company = this.strategicPlanning.CODEMP;
			}

			return unitService.search(search).then((response) => {
				this.units = response;

				if (this.units.length === 1) {
					this.strategicPlanning.CODUNI = this.units[0].CODUNI;
					this.handleChangeGetPermUser();
				}

				this.loadingUnit = false;
			});
		},
		checkItenChecked(value) {
			this.showPerspective = false;
			this.showSubPerspective = false;
			this.showMessagePlanAction = false;
			this.showMessageObjective = false;
			this.showMessageIndicator = false;

			if (value.includes(2)) {
				this.showPerspective = true;
				this.showMessagePlanAction = true;

				if (this.itensToCopyPerspective.includes(1)) {
					this.showSubPerspective = true;
					this.showMessageObjective = true;
				}

				if (this.itensToCopySubPerspective.includes(1)) {
					this.showMessageIndicator = true;
					this.showSubPerspective = true;
				}
			} else {
				this.itensToCopyPerspective = [];
				this.itensToCopySubPerspective = [];
			}
		},
		checkItenPerspectiveChecked(value) {
			this.showSubPerspective = false;
			this.showMessageObjective = false;

			if (value.includes(1)) {
				this.showSubPerspective = true;
				this.showMessageObjective = true;
			} else {
				this.itensToCopySubPerspective = [];
			}
		},
		checkItenIndicatorChecked(value) {
			this.showMessageIndicator = false;

			if (value.includes(1)) {
				this.showMessageIndicator = true;
			}
		},
		async handleChangeGetPermUser() {
			this.loadingUsers = true;
			this.users = [];
			this.itensToCopyUsers = [];

			if (this.strategicPlanning.CODPLE) {
				const res = await strategicPlanningService.getUsers(
					this.strategicPlanning
				);

				this.users = res.gerusuplnest;
				this.itensToCopyUsers = res.gerusuplnest.map((ger) => ger.user);
			}

			this.loadingUsers = false;
		},
		toggle() {
			this.$nextTick(() => {
				if (this.likesAllFruit) {
					this.itensToCopyUsers = [];
				} else {
					this.itensToCopyUsers = this.users.slice();
				}
			});
		},
		removeUser(index) {
			this.$nextTick(() => {
				this.itensToCopyUsers.splice(index, 1);
			});
		},
	},
};
</script>
