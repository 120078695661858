import Rest from "../Base/Rest";

/**
 * @typedef {MeetingMinutesService}
 */
export default class MeetingMinutesService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = "/meeting-minutes";
}
