<template>
	<v-dialog v-model="registerPlanAction" persistent max-width="1100">
		<v-card>
			<v-card-title class="headline"
				>Registrar plano de ação</v-card-title
			>

			<v-divider></v-divider>

			<v-card-text>
				<v-row>
					<v-col cols="12" md="12" class="colDialog mt-6"
						><strong>Empresa:</strong>
						<span>{{ registration.companyName }}</span></v-col
					>
					<v-col cols="12" md="12" class="colDialog"
						><strong>Unidade:</strong>
						<span>{{ registration.unitName }}</span></v-col
					>
					<v-col cols="12" md="12" class="colDialog"
						><strong>Planejamento:</strong>
						<span>{{ registration.planningName }}</span></v-col
					>
					<v-col cols="12" md="12" class="colDialog"
						><strong>Área:</strong>
						<span>{{ registration.areaName }}</span></v-col
					>
				</v-row>

				<v-row>
					<v-col cols="12">
						<hr />
					</v-col>
					<v-col cols="6" class="colDialog">
						O que será feito:
						<p style="color: black">
							{{ registration.oquefaz }}
						</p>
					</v-col>

					<v-col cols="6" class="colDialog">
						Como será feito:
						<p
							style="color: black; white-space: pre-line"
							v-html="registration.comofaz"
						></p>
					</v-col>
				</v-row>

				<v-row class="mt-3">
					<v-col cols="12" md="6">
						<v-select
							v-model="registration.status"
							:items="status"
							item-text="description"
							item-value="id"
							label="Status"
							:disabled="!editValues"
						></v-select>
					</v-col>
					<v-col cols="12" md="6">
						<v-currency-field
							prefix=""
							v-model="registration.percent"
							label="% Executado"
							:disabled="!editValues || (listTasksAll && listTasksAll.length > 0)"
						></v-currency-field>
					</v-col>
					<v-col cols="12" md="6" v-if="editValues">
						<v-file-input
							ref="fileUpload"
							id="fileUploader"
							type="file"
							:rules="fileRules"
							accept="application/pdf"
							placeholder="Clique aqui para selecionar um arquivo"
							prepend-icon="mdi-paperclip"
							@change="onFileSelect"
							show-size
							label="Arquivo"
						></v-file-input>
						<!-- <v-btn v-if="registration.file" color="primary" @click="download()">Download</v-btn>
						<v-btn v-if="registration.file" style="float: right" color="secondary" @click="RemoveDownload()">Remover</v-btn> -->
					</v-col>
					<v-col cols="12" md="6" v-show="listTasks && listTasks.length > 0">
						<!-- <v-select
							v-model="registration.codtar"
							:items="listTasks"
							item-text="TARTIT"
							item-value="CODTAR"
							label="Tarefa"
							:disabled="!editValues"
							no-data-text="Nenhuma tarefa"
						></v-select> -->
						<!-- <v-select
							:items="[
								{
									value: 'A',
									text: 'Aberta',
								},
								{
									value: 'F',
									text: 'Fechada',
								},
							]"
							v-model="registration.tarsit"
							label="Situação"
						></v-select> -->
						<label>Fechar Tarefa</label>
						<v-radio-group
							v-model="registration.tarsit"
							:disabled="listTasks && listTasks.length == 0"
							row
						>
							<v-radio
								label="Sim"
								value="F"
							></v-radio>
							<v-radio
								label="Não"
								value="A"
							></v-radio>
						</v-radio-group>
					</v-col>
				</v-row>

				<v-row justify="center" v-show="listTasks && listTasks.length > 0">
					<v-expansion-panels accordion>
						<v-expansion-panel>
							<v-expansion-panel-header>
								Tarefas
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-data-table
									:headers="[
										{ text: 'Título', value: 'TARTIT', width: '30%' },
										{ text: 'Descrição', value: 'TARDES', width: '70%' },
									]"
									:items="listTasks"
									:items-per-page="5"
									:loading="loadTask"
									loading-text="Carregando..."
									show-select
									item-key="CODTAR"
									:single-select="true"
									v-model="registration.codtar"
									:footer-props="{ itemsPerPageText: 'Itens por página:' }"
									no-data-text="Nenhuma tarefa!"
								></v-data-table>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-row>

				<v-row>
					<v-col>
						<v-textarea
							v-model="registration.message"
							label="Descrição"
							:disabled="!editValues"
						></v-textarea>
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider></v-divider>

			<v-data-table
				:headers="planningHeaders"
				:items="registrationPlanning"
				:items-per-page="5"
				:loading="loadingPlanningRegistration"
				:footer-props="{
					'items-per-page-text': 'Itens por página',
					'items-per-page-all-text': 'Todos',
				}"
			>
				<template v-slot:body="{ items }">
					<tr v-for="item in items" :key="item.CODEXE">
						<td align="center">{{ formatDate(item.DATREG) }}</td>
						<td align="center">{{ item.HORREG }}</td>
						<td align="center">{{ item.user.NOMUSU }}</td>
						<td class="breakLine">{{ item.DESCRI }}</td>
						<td align="center">
							{{ item.task ? item.task.TARTIT : "" }}
						</td>
						<td>
							<v-btn
								v-if="item.FILE"
								@click="download(item)"
								icon
							>
								<v-icon>mdi-cloud-download-outline</v-icon>
							</v-btn>
							<v-btn
								v-if="item.FILE"
								color="secondary"
								icon
								@click="RemoveDownload(item)"
							>
								<v-icon>mdi-trash-can</v-icon>
							</v-btn>
						</td>
					</tr>
				</template>
			</v-data-table>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="red darken-1" text @click="closeRegistration"
					>Fechar</v-btn
				>
				<v-btn
					color="primary darken-1"
					text
					:loading="loadingPlanningRegistration"
					@click="confirmRegistration"
					v-if="editValues"
					>Registrar</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import moment from "moment";

import PlanActionRegistrationService from "../../app/Services/PlanActionRegistrationService";
import axios from "../../config/service/standard";

const planActionRegistration = PlanActionRegistrationService.build();

export default {
	name: "PlanActionRegister",
	props: {
		listTasks: Array,
		listTasksAll: Array,
		loadTask: Boolean
	},
	data: () => ({
		fileRules: [
			(value) =>
				!value ||
				value.size < 2000000 ||
				"O arquivo deve ter menos de 2 MB!",
		],
		loadingPlanning: false,
		registerPlanAction: false,
		editValues: false,
		loadingPlanningRegistration: false,
		company: [],
		unitate: [],
		strategicPlanning: [],
		strategicPlannings: [],
		companies: [],
		units: [],
		itens: [],
		registrationPlanning: [],
		status: [
			{ id: "A", description: "Aberto" },
			{ id: "F", description: "Fechado" },
		],
		filter: {
			company: "",
			unit: "",
			strategicPlanning: "",
		},
		registration: {
			id: null,
			companyName: "",
			unitName: "",
			planningName: "",
			areaName: "",
			message: "",
			percent: 0,
			status: "",
			file: "",
			filename: "",
		},
		planningHeaders: [
			{ text: "Data", align: "center", sortable: false, width: "12%" },
			{ text: "Hora", align: "center", sortable: false, width: "12%" },
			{ text: "Usuário", align: "center", sortable: false, width: "15%" },
			{ text: "Descrição", align: "left", sortable: false },
			{ text: "Tarefa", align: "center", sortable: false, width: "15%" },
			{ text: "#", align: "center", sortable: false, width: "10%" },
		],
		fileBase64: "",
		userData: {},
	}),
	mounted() {
		this.userData = JSON.parse(localStorage.getItem("user"));

		if (this.userData) {
			this.editValues =
				this.userData.user.TIPUSU === "E" ||
				this.userData.user.TIPUSU === "G";
		}
	},
	methods: {
		onFileSelect(fileTmp) {
			if (fileTmp == null) return;

			this.registration.filename = fileTmp.name;

			this.toBase64(fileTmp).then((base64) => {
				this.fileBase64 = base64;
			});
		},
		toBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
				reader.readAsDataURL(file);
			});
		},
		download(item) {
			this.$root.$Loading.show("Fazendo download...");

			axios
				.get("/api/planaction/download", {
					responseType: "arraybuffer",
					params: {
						id: item.CODEXE,
					},
				})
				.then((response) => {
					let blob = new Blob([response.data], {
						type: "application/pdf",
					});
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = item.FILENAME || item.DESCRI;
					link.click();
				})
				.finally(() => {
					this.$root.$Loading.hide();
				});
		},
		RemoveDownload(item) {
			this.$root.$Loading.show("Removendo arquivo...");

			axios
				.post("/api/planaction/removeDownload", {
					id: item.CODEXE,
				})
				.then(() => {
					item.FILE = null;
				})
				.finally(() => {
					this.$root.$Loading.hide();
				});
		},
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		formatDate(value) {
			return moment(value).format("DD/MM/YYYY");
		},
		openRegistration(item, isEdit = true) {
			this.editValues = isEdit;

			this.company = item.company;
			this.unitate = item.unit;
			this.strategicPlanning = item.strategicplanning;

			this.registration.companyName = item.company.DESEMP;
			this.registration.unitName = item.unit.DESUNI;
			this.registration.planning = item.CODPLA;
			this.registration.planningName = item.strategicplanning.DESPLE;
			this.registration.areaName = item.area.DESARE;
			this.registration.percent = parseFloat(item.PEREXE);
			this.registration.status = item.STATUS;
			this.registration.oquefaz = item.OQUFAZ;
			this.registration.comofaz = item.COMFEI;
			this.registration.file = "";
			this.registration.filename = "";
			this.registration.codtar = [];
			this.registration.tarsit = "A";

			this.registerPlanAction = true;

			this.getRegistration(item.CODPLA);
		},
		closeRegistration() {
			this.registerPlanAction = false;
			this.clearRegistration();

			this.$emit("close", true);
		},
		clearRegistration() {
			this.registration.id = null;
			this.registration.companyName = "";
			this.registration.unitName = "";
			this.registration.planning = "";
			this.registration.planningName = "";
			this.registration.areaName = "";
			this.registration.message = "";
			this.registration.percent = 0;
			this.registration.status = "";
			this.registration.file = "";
			this.registration.filename = "";
			this.registration.codtar = [];
			this.registration.tarsit = "A";
		},
		confirmRegistration() {
			if (!this.registration.message) {
				this.toast("warning", "Informe a descrição para continuar");
				return;
			}

			const data = {
				CODEMP: this.company.CODEMP,
				CODUNI: this.unitate.CODUNI,
				CODPLE: this.strategicPlanning.CODPLE,
				CODPLA: this.registration.planning,
				DESCRI: this.registration.message,
				STATUS: this.registration.status,
				PEREXE: this.registration.percent,
				CODTAR: this.registration.codtar && this.registration.codtar.length == 1 ? this.registration.codtar[0].CODTAR : null,
				TARSIT: this.registration.tarsit,
				FILE: this.fileBase64 || this.registration.file,
				FILENAME: this.registration.filename,
			};

			this.loadingPlanningRegistration = true;

			const rest = this.registration.id
				? planActionRegistration.update({
						id: this.registration.id,
						...data,
					})
				: planActionRegistration.create(data);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.$refs.fileUpload.reset();

				this.toast(
					"success",
					this.registration.id
						? "Movimentação atualizado com sucesso!"
						: "Movimentação adicionado com sucesso!"
				);
				this.registration.message = "";

				this.registerPlanAction = false;

				if (this.registration.status === "F") {
					this.closeRegistration();
					return;
				}

				this.getRegistration(this.registration.planning);
				this.$emit("close", true);
			}).catch(() => {
				this.loadingPlanningRegistration = false;
			});
		},
		getRegistration(planning) {
			const search = {};

			this.registrationPlanning = [];
			this.loadingPlanningRegistration = true;

			if (planning) {
				search.planning = planning;
			}

			return planActionRegistration.search(search).then((response) => {
				this.registrationPlanning = response;
				this.loadingPlanningRegistration = false;
			});
		},
	},
};
</script>

<style>
.colDialog {
	padding-top: 0px;
	padding-bottom: 0px;
}

.breakLine {
	word-break: break-word;
}
</style>
