import { DateTime } from "luxon";

const utils = {
	/**
	 * registerDebounce
	 * funcao de registerDebounce do javascript vanilla
	 *
	 * @param {closure} func
	 * @param {Number} wait
	 * @return {function}
	 */
	registerDebounce(func, wait = 1200) {
		let timeoutVar = null;

		return (...args) => {
			clearTimeout(timeoutVar);

			timeoutVar = setTimeout(() => {
				func.apply(this, args);
			}, wait);
		};
	}
};

/**
 * return the sessionLogged user
 * @return {Object}
 */
const getLoggedData = function() {
	const jsonUser = localStorage.user;

	const data = JSON.parse(jsonUser);

	return data;
};

const isBlob = function(imageUrl) {
	return imageUrl.indexOf("blob:") == 0;
};

const isBase64 = function(imageUrl) {
	if (imageUrl.indexOf("data:") == -1) {
		return false;
	}

	if (imageUrl.indexOf(";base64") == -1) {
		return false;
	}

	return true;
};

const financialCurrencyWithoutSymbol = function(item) {
	if (!item) {
		return " 0,00";
	}

	const value = toCurrency(item, {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2
	});

	const withoutSymbol = `${value}`.replace("BRL", "");

	return withoutSymbol;
};

const toCurrency = function(value, config = {}) {
	const toCurrency = Number(value);

	if (Number.isNaN(toCurrency)) {
		return value;
	}

	const defaultConfigs = {
		style: "currency",
		currency: "BRL",
		maximumFractionDigits: 2,
		minimumFractionDigits: 2
	};

	// eslint-disable-next-line
	var formatter = new Intl.NumberFormat([], {
		...defaultConfigs,
		...config
	});

	const formated = formatter.format(toCurrency);

	const withoutSymbol = `${formated}`.replace("R$", "BRL");

	return withoutSymbol;
};

const withoutSymbolCurrency = function(item, options = {}) {
	const value = toCurrency(item, options);

	const withoutSymbol = `${value}`.replace("BRL", "");

	return withoutSymbol;
};

export default {
	install(Vue) {
		Vue.filter("toCurrency", function(value) {
			return toCurrency(value);
		});

		Vue.prototype.getUrlWithToken = function(imageUrl) {
			if (!imageUrl || isBlob(imageUrl) || isBase64(imageUrl)) {
				return imageUrl;
			}

			const loggedData = getLoggedData();

			const token = loggedData.token;

			// eslint-disable-next-line no-undef
			const url = process.env.VUE_APP_API;

			return `${url}/api/files/${imageUrl}?token=${token}`;
		};

		Vue.filter("formatDateHour", function(value) {
			if (!value) {
				return "";
			}

			const date = DateTime.fromISO(value);

			const result = date.setLocale("pt-BR").toFormat("f");

			if (result == "Invalid DateTime") {
				return "-";
			}

			return result;
		});

		Vue.filter("formatDate", function(value) {
			if (!value) {
				return "";
			}

			let date = DateTime.fromISO(value);

			if (date.invalid) {
				date = DateTime.fromFormat(value, "yyyyLLdd");
			}

			const result = date.setLocale("pt-BR").toFormat("D");

			if (result == "Invalid Date") {
				return "-";
			}

			return result;
		});

		/**
		 * Conversao de bytes para tamanho
		 * https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
		 */
		Vue.filter("formatBytesSize", function(value) {
			const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

			if (value == 0) {
				return "0 Byte";
			}

			const i = parseInt(Math.floor(Math.log(value) / Math.log(1024)));

			return Math.round(value / Math.pow(1024, i), 2) + " " + sizes[i];
		});

		Vue.filter("withoutSymbolCurrency", withoutSymbolCurrency);
		Vue.filter(
			"financialCurrencyWithoutSymbol",
			financialCurrencyWithoutSymbol
		);

		Vue.prototype.withoutSymbolCurrency = withoutSymbolCurrency;

		Vue.prototype.$utils = utils;

		/**
		 * FILTROS DE SISTEMA
		 *
		 * utilizados para definir funcoes globais de facil uso no vue.
		 * os filtros ativam o sistema de pipes nos hooks de variaveis do vue
		 * exemplo de uso:
		 *
		 * {{ variavel | filtro }}
		 *
		 * num caso de aplicação
		 *
		 * {{ user.createdAt | formatMysqlDate }}
		 *
		 * formatMysqlDate
		 * transforma a data padrao do banco de dados para data padrao do brasil.
		 *
		 *
		 * @param {String} value
		 * @param {Object} opt - opcoes gerais, withHour define se a string ira ou nao retornar a hora
		 *
		 * @return {String}
		 */
		Vue.prototype.formatMysqlDate = function(
			value,
			opt = { withHour: false, toISO: false }
		) {
			const toFormatDate = DateTime.fromSQL(value);

			if (typeof opt.toISO != "undefined" && opt.toISO) {
				return toFormatDate.toISO();
			}

			const brDate = "dd/LL/yyyy";
			const brHour = "HH:mm";

			let brDateFormat = `${brDate}`;

			if (opt.withHour) {
				brDateFormat = `${brDateFormat} ${brHour}`;
			}

			const formatedDate = toFormatDate.toFormat(brDateFormat);

			if (!formatedDate || formatedDate == "Invalid DateTime") {
				return value;
			}

			return formatedDate;
		};

		Vue.prototype.formatISODate = function(
			value,
			opt = { withHour: false, toISO: false }
		) {
			const toFormatDate = DateTime.fromISO(value);

			if (typeof opt.toISO != "undefined" && opt.toISO) {
				return toFormatDate.toISO();
			}

			const brDate = "dd/LL/yyyy";
			const brHour = "HH:mm";

			let brDateFormat = `${brDate}`;

			if (opt.withHour) {
				brDateFormat = `${brDateFormat} ${brHour}`;
			}

			const formatedDate = toFormatDate.toFormat(brDateFormat);

			if (!formatedDate || formatedDate == "Invalid DateTime") {
				return value;
			}

			return formatedDate;
		};

		Vue.prototype.toSystemDate = function(
			value,
			opt = { withHour: false }
		) {
			const mysqlDate = "yyyy-LL-dd";
			const mysqlHour = "HH:mm:ss";

			let mysqlDateFormat = `${mysqlDate}`;

			if (opt.withHour) {
				mysqlDateFormat = `${mysqlDateFormat} ${mysqlHour}`;
			}

			const systemDate = "dd/LL/yyyy";
			const systemHour = "HH:mm";

			let systemDateFormat = `${systemDate}`;

			if (opt.withHour) {
				systemDateFormat = `${systemDateFormat} ${systemHour}`;
			}

			const luxonDate = DateTime.fromFormat(value, systemDateFormat);

			return luxonDate.toFormat(mysqlDateFormat);
		};

		Vue.prototype.toLuxonDate = function(value, opt = { withHour: false }) {
			const mysqlDate = "yyyy-LL-dd";
			const mysqlHour = "HH:mm:ss";

			let mysqlDateFormat = `${mysqlDate}`;

			if (opt.withHour) {
				mysqlDateFormat = `${mysqlDateFormat} ${mysqlHour}`;
			}

			const luxonDate = DateTime.fromFormat(value, mysqlDateFormat);

			return luxonDate;
		};

		Vue.prototype.showToast = function({ message, status }) {
			if (!status) {
				status = "success";
			}

			this.$swal({
				text: message,
				icon: status,
				toast: true,
				position: "top-end",
				showConfirmButton: true,
				confirmButtonText: "X",
				confirmButtonClass: "swal2-confirm__close",
				timer: 6000
			});
		};

		/**
		 * Transforma um valor numerico em um valor de String formatado para string
		 *
		 * @param {Number} value
		 * @param {Object} config - valores adicionais de configuracoes que podem ser usados com a funcao toCurrency
		 *
		 * @returns {String}
		 * @throws {Error}
		 */
		Vue.prototype.toCurrency = toCurrency;

		Vue.prototype.debounce = function(func, delay = 500) {
			return (...args) => {
				clearTimeout(this.$timer);

				this.timer = setTimeout(() => {
					this.timerId = null;
					func(...args);
				}, delay);
			};
		};

		/**
		 * Usado na construcao dos erros no sistema para todas as telas.
		 * trata os erros recebidos notificando-os com uma snackbar
		 * @param {Throwable} error
		 * @param {String} defaultMessage
		 *
		 * @return {void}
		 */
		Vue.prototype.defaultCatchError = function(
			error,
			defaultMessage = "Ocorreu um erro desconhecido"
		) {
			if (!error) {
				return "";
			}

			this.logErrorInDevelopmentMode(error);

			const message =
				this.getExceptionResponseMessage(error) || defaultMessage;

			this.showToast({ message, status: "error" });
		};

		/**
		 * @param {Throwable} error
		 * @return {String}
		 */
		Vue.prototype.getExceptionResponseMessage = function(error) {
			try {
				const data = error.response.data;

				if (error.response.status == "401") {
					return "Usuário sem permissão para essa operação, realize login e tente novamente.";
				}

				if (error.response.status == "413") {
					return "Tamanho dos arquivos/dados acima do permitido, favor entrar em contato com a equipe de TI para resolver o problema ou escolher arquivos menores!";
				}

				if (error.request.responseType == "arraybuffer") {
					const bufferToStringResponse = this.arrayBufferResponseToJson(
						data
					);

					return bufferToStringResponse.message;
				}

				if (!data) {
					return;
				}

				const decodedData = JSON.parse(data);

				return decodedData.message;
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);

				return;
			}
		};

		Vue.prototype.arrayBufferResponseToJson = function(data) {
			const bufferToStringResponse = decodeURIComponent(
				String.fromCharCode.apply(
					null,
					Array.prototype.slice.apply(new Uint8Array(data))
				)
			);

			return JSON.parse(bufferToStringResponse);
		};
		/**
		 * Verifica se o sistema está em modo de desenvolvimento.
		 * se estiver, notifica o erro
		 * @param {Throwable} error
		 * @return {void}
		 */
		Vue.prototype.logErrorInDevelopmentMode = function(error) {
			// eslint-disable-next-line no-undef
			if (process.env.NODE_ENV !== "production") {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		};

		Vue.prototype.$cnpjMask = "##.###.###/####-##";
		/**
		 * maskCNPJ
		 * aplica uma mascara de CNPJ
		 *
		 * @param {String} cnpj
		 * @return {String}
		 */
		Vue.prototype.maskCNPJ = function(cnpj) {
			const notLetterOrNumber = /([^\w\d])+/g;
			const text = `${cnpj}`.replace(notLetterOrNumber, "");
			const cnpjMask = this.$cnpjMask;

			const identifier = this.maskText(text, cnpjMask);

			return identifier;
		};

		Vue.prototype.unmaskText = function(cnpj) {
			const notLetterOrNumber = /([^\w\d])+/g;
			const text = `${cnpj}`.replace(notLetterOrNumber, "");

			return text;
		};

		/**
		 * MaskText
		 *
		 * Codigo legado de mascara de texto. pode ser usado com qualquer mascara nao substituindo os caracteres:
		 * ()/-. e espaco
		 *
		 * @param {String} text
		 * @param {String} mask - aceita apenas # para substituicao
		 *
		 * @return {String}
		 */
		Vue.prototype.maskText = function(text, mask) {
			if (text === undefined) {
				return text;
			}

			let aux;

			let pos = 0;
			let newValue = "";
			let maskLength = text.length;

			for (let i = 0; i <= maskLength; i += 1) {
				aux =
					mask.charAt(i) === "-" ||
					mask.charAt(i) === "." ||
					mask.charAt(i) === "/";
				aux =
					aux ||
					mask.charAt(i) === "(" ||
					mask.charAt(i) === ")" ||
					mask.charAt(i) === " ";

				if (aux) {
					newValue += mask.charAt(i);
					maskLength += 1;
				} else {
					newValue += text.charAt(pos);
					pos += 1;
				}
			}

			return newValue;
		};
	}
};

export function cnpjValidation(value) {
	if (!value) {
		return false;
	}

	// Aceita receber o valor como string, número ou array com todos os dígitos
	const isString = typeof value === "string";
	const validTypes =
		isString || Number.isInteger(value) || Array.isArray(value);

	// Elimina valor em formato inválido
	if (!validTypes) {
		return false;
	}

	// Filtro inicial para entradas do tipo string
	if (isString) {
		// Limita ao máximo de 18 caracteres, para CNPJ formatado
		if (value.length > 18) {
			return false;
		}

		// Teste Regex para veificar se é uma string apenas dígitos válida
		const digitsOnly = /^\d{14}$/.test(value);
		// Teste Regex para verificar se é uma string formatada válida
		const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

		// Se o formato é válido, usa um truque para seguir o fluxo da validação
		if (digitsOnly || validFormat) {
			true;
		}
		// Se não, retorna inválido
		else {
			return false;
		}
	}

	// Guarda um array com todos os dígitos do valor
	const match = value.toString().match(/\d/g);
	const numbers = Array.isArray(match) ? match.map(Number) : [];

	// Valida a quantidade de dígitos
	if (numbers.length !== 14) {
		return false;
	}

	// Elimina inválidos com todos os dígitos iguais
	const items = [...new Set(numbers)];

	if (items.length === 1) {
		return false;
	}

	// Cálculo validador
	const calc = x => {
		const slice = numbers.slice(0, x);
		let factor = x - 7;
		let sum = 0;

		for (let i = x; i >= 1; i--) {
			const n = slice[x - i];

			sum += n * factor--;

			if (factor < 2) {
				factor = 9;
			}
		}

		const result = 11 - (sum % 11);

		return result > 9 ? 0 : result;
	};

	// Separa os 2 últimos dígitos de verificadores
	const digits = numbers.slice(12);

	// Valida 1o. dígito verificador
	const digit0 = calc(12);

	if (digit0 !== digits[0]) {
		return false;
	}

	// Valida 2o. dígito verificador
	const digit1 = calc(13);

	return digit1 === digits[1];
}
