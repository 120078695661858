<template>
	<div
		class="profile-image-container"
		@click="openImageInput"
		style="cursor: pointer"
	>
		<v-img
			:src="imageUrl"
			:lazy-src="imageUrl"
			aspect-ratio="1"
			class="grey lighten-2 image profile-image"
			width="200"
			style="cursor: pointer"
		/>

		<v-file-input
			:id="`imageUploader_${uuid}`"
			style="display: none"
			:rules="imageRules"
			accept="image/png, image/jpeg, image/bmp"
			placeholder="Clique aqui para selecionar a imagem"
			prepend-icon="mdi-camera"
			@change="onImageSelect"
			show-size
			label="Imagem de perfil"
		></v-file-input>

		<!-- <div class="overlay">
			<a class="icon" title="Foto de perfil">
				<v-icon size="50">mdi-magnify</v-icon>
			</a>
		</div> -->
	</div>
</template>

<script>
import { bus } from "../../main";

export default {
	name: "PictureUploader",
	props: {
		value: String,
		save: {
			type: Function,
			default: () => {},
		},
		img: String,
		user: {
			type: Object,
			default: () => {
				return {};
			},
		},
		customEmit: {
			type: String,
			required: false
		}
	},
	data: () => ({
		uuid: null,
		imageUrl: "",
		imageRules: [
			(value) =>
				!value || value.size < 2000000 || "A imagem deve ter menos de 2 MB!",
		],
	}),
	mounted() {
		this.uuid = this._uid;

		if (this.img) {
			this.imageUrl = this.img;
		}
	},
	methods: {
		onImageSelect(image) {
			this.imageUrl = URL.createObjectURL(image);

			this.toBase64(image).then((base64) => {
				this.$emit("input", base64);

				if (this.customEmit) {
					bus.$emit(this.customEmit, base64);
				}
			});
		},
		openImageInput() {
			document.getElementById(`imageUploader_${this.uuid}`).click();
		},
		toBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
		},
	},
	watch: {
		value() {
			if (!this.value) return;

			this.imageUrl = this.value;
		},
	},
};
</script>

<style>
@media only screen and (max-width: 500px) {
	.overlay {
		border-radius: 100px;
	}
}

.overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: 0.3s ease;
	background-color: grey;
}

.profile-image-container:hover .overlay {
	opacity: 0.8;
}
</style>
