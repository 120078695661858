<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{
							view
								? "Visualizar indicador"
								: id
								? "Editar indicador"
								: "Cadastrar indicador"
						}}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="indicator.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									class="required"
									:loading="loadingCompany"
									@change="getUnits"
									:disabled="view || indicator.STATUS === 'I'"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="indicator.CODUNI"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									class="required"
									:loading="loadingUnit"
									@change="getObjectiveAndArea"
									:disabled="view || indicator.STATUS === 'I'"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="indicator.CODPLE"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									class="required"
									label="Planejamento estrategico"
									:loading="loadingPlanning"
									@change="getObjectives"
									:disabled="view || indicator.STATUS === 'I'"
								></v-select>
							</v-col>
							<v-col cols="12" md="8">
								<v-text-field
									v-model="indicator.DESIND"
									label="Nome"
									class="required"
									:disabled="view || indicator.STATUS === 'I'"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="2">
								<v-currency-field
									v-if="!view && indicator.STATUS !== 'I'"
									prefix=""
									v-model="indicator.META"
									label="Meta"
								></v-currency-field>
								<v-text-field
									v-else
									v-model="indicator.META"
									label="Meta"
									:disabled="view || indicator.STATUS === 'I'"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="2">
								<v-select
									v-model="indicator.UNMEDI"
									:items="metrics"
									item-text="DESCNAME"
									item-value="CODGER"
									label="Un. Medida"
									:loading="loadingMetrics"
									@change="convertValue"
									:disabled="view || indicator.STATUS === 'I'"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="indicator.CODOBJ"
									:items="objectives"
									item-text="DESOBJ"
									item-value="CODOBJ"
									class="required"
									label="Objetivo"
									:loading="loadingObjective"
									:disabled="view || indicator.STATUS === 'I'"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="indicator.CODARE"
									:items="areas"
									item-text="DESARE"
									item-value="CODARE"
									class="required"
									label="Area"
									:loading="loadingArea"
									:disabled="view || indicator.STATUS === 'I'"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="indicator.PERIOD"
									:items="periods"
									item-text="description"
									item-value="id"
									label="Periodicidade"
									:disabled="view || indicator.STATUS === 'I'"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="indicator.CRITER"
									:items="criterions"
									item-text="description"
									item-value="id"
									class="required"
									label="Critério"
									:disabled="view || indicator.STATUS === 'I'"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="indicator.NIVEL"
									:items="nivel"
									item-text="description"
									item-value="id"
									label="Nível / Peso"
									class="required"
									:disabled="view || indicator.STATUS === 'I'"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="indicator.RESPON"
									:items="users"
									item-text="NOMUSU"
									item-value="CODUSU"
									class="required"
									label="Responsável"
									:loading="loadingUser"
									:disabled="view || indicator.STATUS === 'I'"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field
									type="number"
									:counter="2"
									v-model="indicator.TOLERANCE"
									label="Tolerância"
									:disabled="view || indicator.STATUS === 'I'"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="indicator.STATUS"
									:items="status"
									item-text="description"
									item-value="id"
									label="Status"
									:disabled="view"
								></v-select>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn
									color="primary"
									depressed
									tile
									class="mr-4"
									@click="save"
									:disabled="loading"
									v-show="!view"
									>Salvar</v-btn
								>
								<v-btn
									color="primary"
									text
									class="mr-4"
									@click="$router.push('/indicator/list')"
									:disabled="loading"
									>Voltar</v-btn
								>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import IndicatorService from "../../app/Services/IndicatorService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";
import ObjectiveService from "../../app/Services/ObjectiveService";
import AreaService from "../../app/Services/AreaService";
import MetricsService from "../../app/Services/MetricsService";
import UserService from "../../app/Services/UserService";

const indicatorService = IndicatorService.build();
const strategicPlanningService = StrategicPlanningService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();
const objectiveService = ObjectiveService.build();
const areaService = AreaService.build();
const metricsService = MetricsService.build();
const userService = UserService.build();

export default {
	name: "IndicatorForm",
	data: () => ({
		id: null,
		view: false,
		strategicPlannings: [],
		companies: [],
		units: [],
		objectives: [],
		areas: [],
		users: [],
		indicator: {
			STATUS: "A"
		},
		status: [
			{ id: "A", description: "Ativo" },
			{ id: "I", description: "Inativo" }
		],
		periods: [{ id: "M", description: "Mensalmente" }],
		metrics: [],
		criterions: [
			{ id: "1", description: "Positivo é maior" },
			{ id: "2", description: "Positivo é menor" }
		],
		nivel: [
			{ id: 0, description: "Peso 0" },
			{ id: 1, description: "Peso 1" },
			{ id: 2, description: "Peso 2" },
			{ id: 3, description: "Peso 3" }
		],
		loading: false,
		loadingPlanning: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingObjective: false,
		loadingArea: false,
		loadingMetrics: false,
		loadingUser: false
	}),
	mounted() {
		const { id, view } = this.$route.params;

		if (id) {
			this.id = id;
		}

		if (view) {
			this.view = view;
		}

		this.fetch();
	},
	computed: {},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		convertValue() {
			// if (this.indicator.UNMEDI === '4' || this.indicator.UNMEDI === '5') {
			// 	this.indicator.META = parseInt(this.indicator.META);
			// } else {
			this.indicator.META = parseFloat(this.indicator.META);
			// }
		},
		save() {
			if (!this.indicator.DESIND) {
				this.toast(
					"warning",
					"Informe o nome do indicador para continuar"
				);
				return;
			}

			if (!this.indicator.CODEMP) {
				this.toast(
					"warning",
					"Informe a empresa do indicador para continuar"
				);
				return;
			}

			if (!this.indicator.CODUNI) {
				this.toast(
					"warning",
					"Informe a unidade do indicador para continuar"
				);
				return;
			}

			if (!this.indicator.CODPLE) {
				this.toast(
					"warning",
					"Informe o planejamento estrategico do indicador para continuar"
				);
				return;
			}

			if (!this.indicator.CODOBJ) {
				this.toast(
					"warning",
					"Informe o objetivo do indicador para continuar"
				);
				return;
			}

			if (!this.indicator.CODARE) {
				this.toast(
					"warning",
					"Informe a area do indicador para continuar"
				);
				return;
			}

			if (!this.indicator.CRITER) {
				this.toast(
					"warning",
					"Informe o critério do indicador para continuar"
				);
				return;
			}

			if (!this.indicator.RESPON) {
				this.toast(
					"warning",
					"Informe o responsavel do indicador para continuar"
				);
				return;
			}

			this.loading = true;

			const rest = this.id
				? indicatorService.update({ id: this.id, ...this.indicator })
				: indicatorService.create(this.indicator);

			rest.then(response => {
				if (!response) {
					return;
				}

				this.toast(
					"success",
					this.id
						? "Indicador atualizado com sucesso!"
						: "Indicador adicionado com sucesso!"
				);

				this.loading = false;

				this.$router.push("/indicator/list");
			});
		},
		getIndicator() {
			this.loading = true;

			return indicatorService.read({ id: this.id }).then(response => {
				this.indicator = response;

				// if (this.indicator.UNMEDI === '4' || this.indicator.UNMEDI === '5') {
				// 	this.indicator.META = parseInt(response.META);
				// } else {
				this.indicator.META = parseFloat(response.META);
				// }

				if (this.view) {
					this.indicator.META = this.numeral(
						parseFloat(response.META)
					).format("0,0.00");
				}

				this.loading = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then(response => {
				this.companies = response;
				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.units = [];
			this.loadingUnit = true;

			if (this.indicator.CODEMP) {
				search.company = this.indicator.CODEMP;
			}

			this.getMetrics();

			return unitService.search(search).then(response => {
				this.units = response;
				this.loadingUnit = false;
			});
		},
		getStrategicPlannings() {
			this.strategicPlannings = [];
			this.loadingPlanning = true;

			const userData = JSON.parse(localStorage.getItem("user"));

			strategicPlanningService
				.getPlanning({ codusu: userData.user.CODUSU })
				.then(response => {
					this.strategicPlannings = response;
					this.loadingPlanning = false;
				});
		},
		getObjectives() {
			const search = {};

			this.objectives = [];
			this.loadingObjective = true;

			if (this.indicator.CODEMP) {
				search.company = this.indicator.CODEMP;
			}

			if (this.indicator.CODUNI) {
				search.unit = this.indicator.CODUNI;
			}

			if (this.indicator.CODPLE) {
				search.strategicPlanning = this.indicator.CODPLE;
			}

			objectiveService.search(search).then(response => {
				this.objectives = response;
				this.loadingObjective = false;
			});
		},
		getAreas() {
			const search = {};

			this.areas = [];
			this.loadingArea = true;

			if (this.indicator.CODEMP) {
				search.company = this.indicator.CODEMP;
			}

			if (this.indicator.CODUNI) {
				search.unit = this.indicator.CODUNI;
			}

			areaService.search(search).then(response => {
				this.areas = response;
				this.loadingArea = false;
			});
		},
		getMetrics() {
			const search = {};

			this.loadingMetrics = true;

			if (this.indicator.CODEMP) {
				search.company = this.indicator.CODEMP;
			}

			return metricsService.search(search).then(response => {
				this.metrics = response;
				this.loadingMetrics = false;
			});
		},
		getUsers() {
			this.loadingUser = true;
			return userService.usersByKey().then(response => {
				this.users = response;
				this.loadingUser = false;
			});
		},
		getObjectiveAndArea() {
			this.getObjectives();
			this.getAreas();
		},
		fetch() {
			const funcs = [];

			if (this.id) {
				funcs.push(this.getIndicator());
			}

			funcs.push(this.getCompanies());
			funcs.push(this.getStrategicPlannings());
			funcs.push(this.getUsers());

			Promise.all(funcs)
				.then(() => {
					if (this.id) {
						this.getUnits();
						this.getObjectives();
						this.getAreas();
						this.getMetrics();
					}
				})
				.finally(() => {});
		}
	}
};
</script>
