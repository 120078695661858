<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ id ? 'Editar grupo de empresa' : 'Cadastrar grupo de empresa' }}
					</v-card-title>
					<v-col class="pl-10 pb-5" cols="10" md="10">
						<v-row>
							<v-col cols="12" md="2">
								<PictureUploader v-model="companyGroup.FOTGRPEMP" :img="companyGroup.FOTGRPEMP" @input="save" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="3">
								<label>Cor Predominante: </label>
								<input type="color" v-model="companyGroup.FIRSTCOLOR" class="required alignColor" />
							</v-col>
							<v-col cols="12" md="12">
								<v-text-field v-model="companyGroup.DESGRP" label="Nome" class="required"></v-text-field>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn color="primary" depressed tile class="mr-4" @click="save" :disabled="loading">Salvar</v-btn>
								<v-btn color="primary" text class="mr-4" @click="$router.push('/company-group/list')" :disabled="loading">Voltar</v-btn>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import CompanyGroupService from "../../app/Services/CompanyGroupService";
import PictureUploader from '../picture-uploader/PictureUploader';

const companyGroupService = CompanyGroupService.build();

export default {
	name: "CompanyGroupForm",
	components: {
		PictureUploader: PictureUploader,
	},
	data: () => ({
		id: null,
		companyGroup: {},
		loading: false
	}),
	computed: {},
	mounted () {
		const { id } = this.$route.params;

		if (id) {
			this.id = id;
			this.getCompanyGroup();
		}
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		getCompanyGroup() {
			this.loading = true;

			return companyGroupService.read({ id: this.id }).then((response) => {
				this.companyGroup = response;
				this.loading = false;
			});
		},
		save() {
			if (!this.companyGroup.DESGRP) {
				this.toast(
					"warning",
					"Informe o nome do grupo da empresa para continuar"
				);
				return;
			}

			this.loading = true;

			const rest = this.id ? companyGroupService.update({ id: this.id, ...this.companyGroup }) : companyGroupService.create(this.companyGroup);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast('success', this.id ? 'Group de empresa atualizado com sucesso!' : 'Group de empresa adicionado com sucesso!');

				this.loading = false;

				this.$router.push('/company-group/list');
			});
		}
	}
};
</script>
<style>
.alignColor {
	vertical-align: bottom;
}
</style>
