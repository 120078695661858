<template>
  <v-content style="background-color: rgb(60, 41, 121);">
    <v-container class="fill-height" grid-list-md fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="4">
          <v-card class="pa-4">
            <v-toolbar flat>
              <v-toolbar-title>
                <v-btn icon>
                  <v-icon :color="color">{{icon}}</v-icon>
                </v-btn>
                {{ message }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$router.push('/login')" block depressed>Voltar ao login</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import UserService from '../app/Services/UserService';

const userService = UserService.build();

export default {
	name: 'Activate',
	computed: {},
	data: () => ({
		color: 'green',
		icon: 'mdi-check',
		message: 'Sua conta foi ativada com sucesso!',
	}),
	mounted() {
		const { token } = this.$route.query;

		if (!token) {
			this.$toasted.error('Dados inválidos', { icon: 'mdi-information' });
			this.$router.push('/login');
			return;
		}

		this.activate(token);
	},
	methods: {
		activate(token) {
			userService.activate(token).then((response) => {
				if (response.status == 'invalid_token') {
					this.color = 'red';
					this.icon = 'mdi-close';
					this.message = 'Dados inválidos';
					return;
				}
			});
		},
	},
};
</script>
