import Rest from '../../app/Base/Rest';

/**
 * @typedef {TermService}
 */
export default class TermService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/terms';

	getCurrent() {
		return this.get('current');
	}
}