import Rest from "../Base/Rest";

/**
 * @typedef {UserFilterPreferencesService}
 */
export default class UserFilterPreferencesService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = "/user-filter-preferences";
}
