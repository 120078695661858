<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{ view ? 'Visualizar perspectiva' : (id ? 'Editar perspectiva' : 'Cadastrar perspectiva') }}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="perspective.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									class="required"
									:loading="loadingCompany"
									@change="getUnits"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="perspective.CODUNI"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									class="required"
									:loading="loadingUnit"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="perspective.CODPLE"
									:items="strategicPlannings"
									item-text="DESPLE"
									item-value="CODPLE"
									class="required"
									label="Planejamento estrategico"
									:loading="loadingPlanning"
									:disabled="view"
								></v-select>
							</v-col>
							<v-col cols="12" md="8">
								<v-text-field v-model="perspective.DESPER" label="Descrição" class="required" :disabled="view"></v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field v-model="perspective.ORDAPR" type="number" label="Ordem" :disabled="view"></v-text-field>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn color="primary" depressed tile class="mr-4" @click="save" :disabled="loading" v-show="!view">Salvar</v-btn>
								<v-btn color="primary" text class="mr-4" @click="$router.push('/perspective/list')" :disabled="loading">Voltar</v-btn>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import PerspectiveService from "../../app/Services/PerspectiveService";
import StrategicPlanningService from "../../app/Services/StrategicPlanningService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";

const perspectiveService = PerspectiveService.build();
const strategicPlanningService = StrategicPlanningService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();

export default {
	name: "PerspectiveForm",
	data: () => ({
		id: null,
		view: false,
		strategicPlannings: [],
		perspective: {},
		companies: [],
		units: [],
		loading: false,
		loadingPlanning: false,
		loadingCompany: false,
		loadingUnit: false
	}),
	mounted() {
		const { id, view } = this.$route.params;

		if (id) {
			this.id = id;
		}

		if (view) {
			this.view = view;
		}

		this.fetch();
	},
	computed: {},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		save() {
			if (!this.perspective.DESPER) {
				this.toast(
					"warning",
					"Informe o nome da perspectiva para continuar"
				);
				return;
			}

			if (!this.perspective.CODEMP) {
				this.toast(
					"warning",
					"Informe a empresa da perspectiva para continuar"
				);
				return;
			}

			if (!this.perspective.CODUNI) {
				this.toast(
					"warning",
					"Informe a unidade da perspectiva para continuar"
				);
				return;
			}

			if (!this.perspective.CODPLE) {
				this.toast(
					"warning",
					"Informe o planejamento estrategico da perspectiva para continuar"
				);
				return;
			}

			this.loading = true;

			const rest = this.id ? perspectiveService.update({ id: this.id, ...this.perspective }) : perspectiveService.create(this.perspective);

			rest.then((response) => {
				if (!response) {
					return;
				}

				this.toast('success', this.id ? 'Perspectiva atualizada com sucesso!' : 'Perspectiva adicionada com sucesso!');

				this.loading = false;

				this.$router.push('/perspective/list');
			});
		},
		getPerspective () {
			this.loading = true;

			return perspectiveService.read({ id: this.id }).then((response) => {
				this.perspective = response;
				this.loading = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.units = [];
			this.loadingUnit = true;

			if (this.perspective.CODEMP) {
				search.company = this.perspective.CODEMP;
			}

			return unitService.search(search).then((response) => {
				this.units = response;
				this.loadingUnit = false;
			});
		},
		getStrategicPlannings() {
			this.loadingPlanning = true;
			const userData = JSON.parse(localStorage.getItem("user"));

			strategicPlanningService.getPlanning({ codusu: userData.user.CODUSU }).then(response => {
				this.strategicPlannings = response;
				this.loadingPlanning = false;
			});
		},
		fetch () {
			const funcs = [];

			if (this.id) {
				funcs.push(this.getPerspective());
			}

			funcs.push(this.getStrategicPlannings());
			funcs.push(this.getCompanies());

			Promise.all(funcs)
				.then(() => {
					if (this.id) {
						this.getUnits();
					}
				})
				.finally(() => {
				});
		}
	}
};
</script>
