var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.view ? "Visualizar Nível" : _vm.id ? "Editar Nível" : "Cadastrar Nível")+" ")]),_c('v-col',{staticClass:"pl-10 pr-10 pb-5",attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.groups,"item-text":"DESGRP","item-value":"CODGRP","label":"Grupo","clearable":"","loading":_vm.loadingGroup},on:{"change":function($event){return _vm.getOrganizationChart(true)}},model:{value:(_vm.chartlevel.CODGRP),callback:function ($$v) {_vm.$set(_vm.chartlevel, "CODGRP", $$v)},expression:"chartlevel.CODGRP"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.corporateAreas,"item-text":"DESAREA","item-value":"GERCOPARE","label":"Área Corporativa","loading":_vm.loadingCorporateArea,"clearable":""},on:{"change":function($event){_vm.updateCorporateArea();
									_vm.getOrganizationChart(true);}},model:{value:(_vm.chartlevel.GERCOPARE),callback:function ($$v) {_vm.$set(_vm.chartlevel, "GERCOPARE", $$v)},expression:"chartlevel.GERCOPARE"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.companies,"item-text":"DESEMP","item-value":"CODEMP","label":"Empresa","disabled":_vm.disableCompany,"loading":_vm.loadingCompany,"clearable":""},on:{"change":function($event){return _vm.getUnits(true)}},model:{value:(_vm.chartlevel.CODEMP),callback:function ($$v) {_vm.$set(_vm.chartlevel, "CODEMP", $$v)},expression:"chartlevel.CODEMP"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.units,"item-text":"DESUNI","item-value":"CODUNI","label":"Unidade","disabled":_vm.disableUnit,"loading":_vm.loadingUnit,"clearable":""},on:{"change":function($event){_vm.getAreas(true);
									_vm.getOrganizationChart(true);}},model:{value:(_vm.chartlevel.CODUNI),callback:function ($$v) {_vm.$set(_vm.chartlevel, "CODUNI", $$v)},expression:"chartlevel.CODUNI"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.areas,"item-text":"DESARE","item-value":"CODARE","label":"Área","disabled":_vm.disableArea,"clearable":"","loading":_vm.loadingArea},model:{value:(_vm.chartlevel.CODARE),callback:function ($$v) {_vm.$set(_vm.chartlevel, "CODARE", $$v)},expression:"chartlevel.CODARE"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.organizationCharts,"item-text":"DESORG","item-value":"CODORG","label":"Organograma","no-data-text":"Nenhum Organograma","clearable":"","loading":_vm.loadingOrganizationChart},model:{value:(_vm.chartlevel.CODORG),callback:function ($$v) {_vm.$set(_vm.chartlevel, "CODORG", $$v)},expression:"chartlevel.CODORG"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"label":"Descrição"},model:{value:(_vm.chartlevel.DESNVL),callback:function ($$v) {_vm.$set(_vm.chartlevel, "DESNVL", $$v)},expression:"chartlevel.DESNVL"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-select',{staticClass:"required",attrs:{"items":[
									{ value: 'LEVEL', text: 'Nível' },
									{
										value: 'ACCESSORY',
										text: 'Assessoria',
									},
								],"item-text":"text","item-value":"value","label":"Tipo","clearable":""},on:{"change":_vm.handleChangeLevelType},model:{value:(_vm.chartlevel.NVLTIP),callback:function ($$v) {_vm.$set(_vm.chartlevel, "NVLTIP", $$v)},expression:"chartlevel.NVLTIP"}})],1)],1),_c('v-row',[(_vm.chartlevel.NVLTIP == 'LEVEL')?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"label":"Nível"},model:{value:(_vm.chartlevel.NIVEL),callback:function ($$v) {_vm.$set(_vm.chartlevel, "NIVEL", $$v)},expression:"chartlevel.NIVEL"}})],1):_vm._e(),(_vm.chartlevel.NVLTIP == 'ACCESSORY')?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.linkedlevel,"item-text":"DESNVL","item-value":"CODNVL","label":"Nível Vinculado","clearable":"","loading":_vm.loadingLinkedlevel},model:{value:(_vm.chartlevel.NVLVIC),callback:function ($$v) {_vm.$set(_vm.chartlevel, "NVLVIC", $$v)},expression:"chartlevel.NVLVIC"}})],1):_vm._e()],1),_c('v-row',{staticClass:"mt-5"},[_c('v-container',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.view),expression:"!view"}],staticClass:"mr-4",attrs:{"color":"primary","depressed":"","tile":"","disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v("Salvar")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","text":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$router.push(
										'/organization-chart/level/list'
									)}}},[_vm._v("Voltar")])],1)],1)],1)],1)],1)],1),[_c('Dialog',{ref:"confirm"})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }