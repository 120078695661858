<template>
	<!-- v-bind:class="!isMobile ? 'background' : ''" -->
	<v-row v-resize="checkIsMobile" class="mg">
		<v-col cols="12" class="mg">
			<v-row class="mg">
				<v-col
					md="12"
					sm="12"
					xs="12"
					class="topoLogin d-md-flex justify-center"
				>
					<v-col md="5" sm="12" xs="12">
						<a class="clsLink" href="/#/login" tabindex="-1">
							<img
								class="logo"
								v-if="covers.length > 0"
								:src="
									covers.length > 0 && covers[0].CAPLOG_URL
										? covers[0].CAPLOG_URL
										: '../assets/logo.jpg'
								"
								width="50px"
							/>
							<strong>Gestão Estratégica</strong>
						</a>
					</v-col>
					<v-col md="3" sm="12" xs="12" class="align-right">
						<v-text-field
							v-model="credentials.email"
							@click="handle"
							type="email"
							placeholder="Usuário"
							name="email"
							id="email"
							outlined
							dense
							ref="login"
							hide-details
							:error="false"
							tabindex="1"
							v-on:keyup.enter="$refs.password.$refs.input.focus()"
							autocomplete="off"
						/>
						<v-btn
							@click="$router.push('/sign-up')"
							style="font-size: 11px"
							text
							depressed
							tabindex="-1"
							color="#3F48CC"
							>Cadastre-se</v-btn
						>
					</v-col>
					<v-col md="3" sm="12" xs="12" class="align-right">
						<v-text-field
							v-model="credentials.password"
							@click="handle"
							type="password"
							placeholder="Senha"
							name="password"
							ref="password"
							:error="false"
							hide-details
							outlined
							dense
							tabindex="2"
							v-on:keyup.enter="executeLogin()"
							id="password"
						/>
						<v-btn
							@click="$router.push('/password-reset')"
							style="font-size: 11px"
							text
							depressed
							tabindex="-1"
							color="#3F48CC"
							>Recuperar senha</v-btn
						>
					</v-col>
					<v-col>
						<v-btn
							@click="login"
							:loading="loading"
							style="font-size: 11px"
							depressed
							tabindex="3"
							class="white--text"
							id="m_login_signin_submit"
							color="#3F48CC"
							>Entrar</v-btn
						>
					</v-col>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="12">
					<v-carousel hide-delimiters :show-arrows="false">
						<v-carousel-item
							v-for="cover in covers"
							:key="cover.CODCAP"
							:src="cover.CAPIMG_URL"
						>
							<v-jumbotron dark>
								<v-container fill-height>
									<v-layout align-center>
										<v-row class="fill-height" align="center" justify="center">
											<div
												class="text-h2 text-center"
												style="
													color: white;
													background-color: rgba(158, 158, 158, 0.85);
												"
											>
												{{ cover.CAPDES }}
											</div>
										</v-row>
									</v-layout>
								</v-container>
							</v-jumbotron>
						</v-carousel-item>
					</v-carousel>
				</v-col>
			</v-row>
			<v-row v-show="logos.length > 0">
				<v-col cols="12" md="12">
					<v-sheet class="mx-auto text-center" max-width="50%">
						<h2 class="mb-3 display-1">Nossos Clientes</h2>
						<v-divider />
					</v-sheet>
				</v-col>
				<v-col cols="12" md="12">
					<v-sheet class="mb-2 mx-auto" max-width="90%">
						<v-slide-group mobile-break-point="1000" show-arrows center-active>
							<template v-slot:next>
								<v-icon color="secondary" large>mdi-pan-right</v-icon>
							</template>
							<template v-slot:prev>
								<v-icon color="secondary" large>mdi-pan-left</v-icon>
							</template>
							<v-row justify="center" align="center" class="my-1">
								<v-slide-item v-for="(logo, i) in logos" :key="i">
									<v-card width="200" class="ma-4">
										<v-card-title>
											<v-img height="150px" contain :src="logo.FOTEMP"></v-img>
										</v-card-title>
									</v-card>
								</v-slide-item>
							</v-row>
						</v-slide-group>
					</v-sheet>
				</v-col>
			</v-row>
			<v-row v-show="faqs.length > 0">
				<v-col cols="12" md="12">
					<v-sheet class="mx-auto" max-width="84%">
						<h3 class="mb-1">
							<v-icon color="secondary">mdi-frequently-asked-questions</v-icon>
							Perguntas Frequentes
						</h3>
						<v-divider />
					</v-sheet>
				</v-col>
				<v-col cols="12" md="12">
					<v-sheet class="mb-5 mx-auto" max-width="84%">
						<v-expansion-panels
							:value="Object.keys(faqs).map((item) => parseInt(item))"
							multiple
						>
							<v-expansion-panel v-for="(faq, i) in faqs" :key="i">
								<v-expansion-panel-header
									><b>{{ faq.FAQPER }}</b></v-expansion-panel-header
								>
								<v-expansion-panel-content style="white-space: pre-line">
									{{ faq.FAQRES }}
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-sheet>
				</v-col>
			</v-row>
			<v-footer>
				<v-col class="text-center" cols="12">
					<h5>
						Homologado para os navegadores <br />
						Google Chrome e Mozilla Firefox <br />
						© Todos os direitos reservados.
					</h5>
				</v-col>
			</v-footer>
		</v-col>
	</v-row>
</template>

<script>
import LoginService from "../app/Services/LoginService";
import PublicService from "../app/Services/PublicService";
// import VueJwtDecode from "vue-jwt-decode";

const loginService = LoginService.build();

export default {
	name: "Login",
	data: () => ({
		credentials: {
			email: "",
			password: "",
			hash: "",
		},
		loading: false,
		isMobile: false,
		colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
		covers: [],
		logos: [],
		faqs: [],
	}),
	async created() {
		this.$nextTick(() => this.$refs.login.$refs.input.focus());

		this.load();

		await Promise.all([this.getCovers(), this.getCompanyLogo(), this.getFaq()]);
	},
	methods: {
		checkIsMobile() {
			this.isMobile = false;

			// MOBILE/IPAD
			if (window.innerWidth < 1025) {
				this.isMobile = true;
			}
		},
		load() {
			if (
				this.$route.params.hash != "" &&
				this.$route.params.hash != undefined
			) {
				this.credentials.hash = this.$route.params.hash;
				this.login();
			}
		},
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		handle(event) {
			let el = document.getElementById(event.target.id);
			el.scrollIntoView();
		},
		login() {
			this.loading = true;

			loginService
				.send(this.credentials)
				.then(
					(response) => {
						if ("error" in response && response.error == "not_verified") {
							this.$toasted.info("Conta não ativada, verifique seu e-mail", {
								icon: "mdi-information",
							});
							return;
						}

						const user = response;
						// const payload = VueJwtDecode.decode(user.access_token);

						localStorage.setItem("user", JSON.stringify(user));

						// const menus = payload.menus;

						// const modules = payload.modules;
						// const module = modules.length > 0 ? modules[0] : [];

						// let routeToGo = menus.filter((menu) => menu.CODMOD == module.CODMOD || menu)[0].URL;

						// this.$router.push(routeToGo);

						// this.$router.push('user/home', () => {
						// 	this.$router.go(0);
						// });
						if (user.user.TIPUSU == "E") {
							this.$router.push("company/home");
							this.$router.go(0);
						} else {
							this.$router.push("user/home");
							this.$router.go(0);
						}
					},
					() => {
						this.toast(
							"error",
							"Usuário ou senha incorretos!",
							"mdi-account-remove"
						);
						this.$router.push("/login");
					}
				)
				.finally(() => {
					this.loading = false;
				});
		},
		executeLogin() {
			this.login();
		},
		async getCompanyLogo() {
			const response = await PublicService.build().companysLogo();

			this.logos = response;
		},
		async getFaq() {
			const response = await PublicService.build().faqList();

			this.faqs = response;
		},
		async getCovers() {
			const response = await PublicService.build().coverImages();

			this.covers = response;
		},
	},
};
</script>

<style>
html {
	margin: 0 !important;
}

.logo {
	vertical-align: middle;
	margin: 0 13px;
}

.topoLogin {
	width: 100%;
	/* position: fixed !important; */
	top: 0 !important;
	background: white;
	padding-bottom: 0 !important;
}

.topoLogin > .col {
	padding-bottom: 0 !important;
}

.mg {
	margin: 0 !important;
	padding: 0 !important;
}
.clsLink {
	text-decoration: none;
}
.clsLink strong {
	color: black;
}

.background {
	background-image: url(../assets/splash.jpeg);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	margin: 0 !important;
}

.login-form {
	color: "#aba5b6";
	display: block;
	text-align: center;
}

.login-title {
	color: #9e97aa;
	text-align: center;
	font-size: 20px;
	font-weight: 400;
}

.align-right {
	text-align: right;
}

footer {
	width: 100%;
	/* position: fixed !important; */
	bottom: 0 !important;
}

footer .col {
	padding-top: 0 !important;
}
</style>
