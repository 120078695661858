import Rest from '../../app/Base/Rest';

/**
 * @typedef {ObjectiveService}
 */
export default class ObjectiveService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/objective';
}
