<template>
  <div class="text-center">
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ text }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
	name: 'Loading',
	data: () => ({
		dialog: false,
		text: '',
	}),
	methods: {
		show(text = 'Carregando...') {
			this.text = text;
			this.dialog = true;
		},
		hide() {
			this.dialog = false;
		},
	},
};
</script>