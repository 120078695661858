<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="mt-2 pb-8 mb-5" flat>
					<v-card-title>
						<v-subheader>
							<span class="title font-weight-normal"
								>Filtrar Colaboradores</span
							>
						</v-subheader>
						<v-spacer></v-spacer>
						<v-btn
							v-show="!view"
							class="white--text mr-2"
							depressed
							color="#34bfa3"
							@click="$router.push('/organization-chart/employee/new')"
							>+ Nova</v-btn
						>
						<v-btn
							class="white--text"
							depressed
							color="info"
							@click="filter()"
							>Buscar</v-btn
						>
						<search-filter-default
							v-model="filters"
						/>
					</v-card-title>
				</v-card>
				<v-card flat>
					<v-data-table
						:headers="headers"
						:items="items"
						:items-per-page="pagination.itemsPerPage"
						v-bind:loading="loading"
						loading-text="Aguarde...carregando"
						:hide-default-footer="true"
					>
						<template v-slot:body="{ items }">
							<tr v-for="item in items" :key="item.CODCOL">
								<td v-if="!view">
									<v-btn @click="edit(item.CODCOL)" icon>
										<v-icon style="color: orange"
											>mdi-pencil</v-icon
										>
									</v-btn>
								</td>
								<td v-else>&nbsp;</td>

								<td>
									<v-icon v-if="item.FILE" @click="downloadItem(item)">mdi-paperclip</v-icon>
								</td>

								<td>{{ item.CODCOL }}</td>
								<td>{{ item.group ? item.group.DESGRP : '' }}</td>
								<td>{{ item.corporate_area ? item.corporate_area.DESAREA : '' }}</td>
								<td>{{ item.company ? item.company.DESABR : '' }}</td>
								<td>{{ item.unit ? item.unit.DESABR : '' }}</td>
								<td>{{ item.area ? item.area.DESARE : '' }}</td>
								<td>{{ item.DESCOL }}</td>
								<td v-if="!view">
									<v-btn @click="remove(item.CODCOL, item.DESCOL)" icon>
										<v-icon style="color: red"
											>mdi-delete</v-icon
										>
									</v-btn>
								</td>
								<td v-else>
									<v-btn @click="edit(item.CODCOL)" icon>
										<v-icon>mdi-eye</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>

					<pagination
						v-model="pagination"
						@change="loadData"
						:loading="loading"
					/>
				</v-card>
			</v-col>
		</v-row>
		<template>
			<Dialog ref="confirm" />
		</template>
	</v-container>
</template>

<script>
import axios from "../../config/service/standard";
import Dialog from '../misc/Dialog';
import OrganizationChartEmployeeService from "../../app/Services/OrganizationChartEmployeeService";
import pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import SearchFilterDefault from "@/components/process/SearchFilterDefault.vue";

export default {
	name: "OrganizationChartEmployeeList",
	components: { Dialog, SearchFilterDefault, pagination },
	mixins: [PaginationMixin],
	mounted() {
		this.loadData();
	},
	data: () => ({
		loading: false,
		view: false,
		filters: {},
		headers: [
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%"
			},
			{
				text: "",
				align: "left",
				sortable: false,
				value: "anexo",
				width: "3%"
			},
			{
				text: "Código",
				align: "left",
				sortable: true,
				value: "CODCOL",
				width: "10%"
			},
			{
				text: "Grupo",
				align: "left",
				sortable: true,
				value: "group.DESGRP",
				width: "10%"
			},
			{
				text: "Área Corporativa",
				align: "left",
				sortable: true,
				value: "corporateArea.DESABR",
				width: "10%"
			},
			{
				text: "Empresa",
				align: "left",
				sortable: true,
				value: "company.DESABR",
				width: "10%"
			},
			{
				text: "Unidade",
				align: "left",
				sortable: true,
				value: "unit.DESABR",
				width: "10%"
			},
			{
				text: "Area",
				align: "left",
				sortable: true,
				value: "area.DESARE",
				width: "10%"
			},
			{
				text: "Descrição",
				align: "left",
				sortable: true,
				value: "DESCOL",
				width: "40%"
			},
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%"
			}
		],
		items: [],
	}),
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon
			});
		},
		async loadData() {
			try {
				this.loading = true;

				const filter = this.filters;

				const query = {
					params: {
						filter,
						...this.pagination
					}
				};

				const response = await OrganizationChartEmployeeService.build().index({
					query
				});

				this.items = response.data;
				this.loading = false;

				this.paginate(response);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e);
				this.defaultCatchError(e);
			} finally {
				this.loading = false;
			}
		},
		filter() {
			this.searching = true;
			this.items = [];
			this.pagination.page = 1;

			this.loadData();
		},
		edit(id) {
			this.$router.push({
				name: "OrganizationChartEmployeeEdit",
				params: {
					id: id,
					view: this.view
				}
			});
		},
		async remove(id, title) {
			await this.$refs.confirm.open('Atenção', `Deseja excluir o organograma ${title}?`).then(() => {
				this.$root.$Loading.show("Removendo o processo...");

				OrganizationChartEmployeeService.build()
					.destroy({
						id: id
					})
					.then(() => {
						this.toast(
							"success",
							"Processo removido com sucesso!"
						);

						this.$root.$Loading.hide();

						this.loadData();
					})
			});
		},
		downloadItem(item) {
			this.$root.$Loading.show("Fazendo download...");

			axios
				.get("/api/process/download", {
					responseType: "arraybuffer",
					params: {
						id: item.CODCOL
					}
				})
				.then(async response => {
					let blob = new Blob([response.data], {
						type: "application/pdf",
					});

					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = item.TITPRO + ".pdf";
					link.click();
				})
				.finally(() => {
					this.$root.$Loading.hide();
				});
		},
	}
};
</script>
