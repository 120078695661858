import Rest from "../../app/Base/Rest";

/**
 * @typedef {StrategicPlanningService}
 */
export default class StrategicPlanningService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = "/strategicplanning";

	print(data) {
		return this.post("print", { data });
	}

	copy(data) {
		return this.post("copy", { data });
	}

	getPlanning(params) {
		const queryString = this.queryString(params);

		return this.get(`getPlanning?${queryString}`);
	}

	getUsers(strategicPlanning) {
		return this.post("users", {
			CODEMP: strategicPlanning.CODEMP,
			CODPLE: strategicPlanning.CODPLE,
			CODUNI: strategicPlanning.CODUNI,
		});
	}
}
