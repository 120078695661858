<template>
	<div>
		<Drawer
			v-if="!hide"
			:items="items"
			v-model="drawer"
			:mini="!isMobile"
			:permanent="!isMobile"
			:isMobile="isMobile"
		></Drawer>

		<v-app-bar
			v-if="!hide"
			class="noPrint elevation-1"
			dense
			fixed
			clipped-left
			color="#ffffff"
			app
		>
			<v-app-bar-nav-icon
				v-if="isMobile"
				@click.stop="drawer = !drawer"
			></v-app-bar-nav-icon>

			<v-toolbar-title>
				<v-img
					v-if="covers.length > 0"
					:src="
						covers.length > 0 && covers[0].CAPLOG_URL
							? covers[0].CAPLOG_URL
							: '../assets/logo.jpg'
					"
					width="35"
				></v-img>
			</v-toolbar-title>

			<v-toolbar-title style="margin-left: 15px; font-weight: bold">
				Gestão Estratégica
			</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-avatar class="mr-3" size="30">
				<img v-if="user.FOTUSU" :src="user.FOTUSU" alt="avatar" />
				<img
					v-if="!user.FOTUSU"
					src="../../assets/placeholder-user.png"
					alt="avatar"
				/>
			</v-avatar>

			<span
				v-if="user"
				style="font-size: 14px"
				class="mr-5 font-weight-light"
				>{{
					(user.NOMUSU ? user.NOMUSU + " - " : "") +
					user.EMAUSU +
					" - " +
					userTypes[user.TIPUSU]
				}}</span
			>
			<v-btn color="primary" text @click="logoff()">Sair</v-btn>

			<template v-slot:extension>
				<span
					color="primary"
					v-for="item in modules"
					:key="item.CODMOD"
					dark
					class="menuActivator"
					:class="item.module.CODMOD == module ? 'active' : ''"
					@click="changeModule(item.module.CODMOD)"
				>
					{{ item.module.NAME }}
				</span>

				<v-spacer></v-spacer>

				<v-btn color="primary" text @click="openDialogFilters">
					Seleção do PE
				</v-btn>
			</template>
		</v-app-bar>

		<v-main id="content" class="mobile-content">
			<router-view />
		</v-main>

		<Company
			v-if="companyVisible"
			:visible="companyVisible"
			v-on:setDialogVisible="actionCompany"
		/>

		<ModalSelectPE v-model="dialogFilters" :close="closeDialogFilters" />
	</div>
</template>

<script>
import Drawer from "./Drawer";
import { bus } from "../../main";
import MeService from "@/app/Services/MeService";
import PublicService from "@/app/Services/PublicService";
import ModalSelectPE from "./ModalSelectPE.vue";

const meService = MeService.build();

export default {
	name: "AppNavigation",
	components: {
		Drawer: Drawer,
		ModalSelectPE: ModalSelectPE,
	},
	computed: {
		hide() {
			return (
				this.$route.path === "/login" ||
				this.$route.path === "/sign-up" ||
				this.$route.path === "/register" ||
				this.$route.path === "/password-reset" ||
				this.$route.path === "/new-password" ||
				this.$route.path === "/activate"
			);
		},
		isMobile() {
			// Detecta se a tela é mobile
			return this.$vuetify.breakpoint.smAndDown;
		},
	},
	created() {
		bus.$on("profilePictureUpdated", (data) => {
			this.user.FOTUSU = data;
			this.updateProfileOnLocalStorage(data);
		});
	},
	async mounted() {
		const userData = JSON.parse(localStorage.getItem("user"));

		if (userData && "user" in userData) {
			this.user = userData["user"];

			await this.getPermission();
		}

		this.drawer = !this.$vuetify.breakpoint.smAndDown;
	},
	data: () => ({
		drawer: false,
		module: 0,
		modules: [],
		items: [],
		menu: [],
		source: "",
		user: {},
		avatar: {
			size: 32,
			tile: false,
			link: "",
		},
		userTypes: {
			U: "Usuário",
			E: "Empresa",
			A: "Administrador",
			P: "Planejador",
			G: "Gestor",
		},
		message: true,
		companyVisible: false,
		covers: [],
		dialogFilters: false,
	}),
	methods: {
		updateProfileOnLocalStorage(base64 = "") {
			let data = JSON.parse(localStorage.getItem("user"));
			data.user.FOTUSU = base64;
			localStorage.setItem("user", JSON.stringify(data));
		},
		changeModule(CODMOD) {
			this.module = CODMOD;
			this.items = this.menu.filter((menu) => menu.CODMOD == CODMOD);
		},
		async loadRoute() {
			this.items = this.menu.filter((menu) => menu.URL == this.$route.path);

			if (this.items.length > 0) {
				const module = this.items[0].CODMOD;

				this.changeModule(module);
			}
		},
		myUser() {
			this.$router.push({ path: "/my-user" });
		},
		logoff() {
			this.$router.push({ path: "/logoff" });
		},
		actionChangeDrawer(payload) {
			this.drawer = payload;
		},
		actionCompany(value) {
			this.companyVisible = value;
		},
		async getCovers() {
			const response = await PublicService.build().coverImages();

			this.covers = response;
		},
		async getPermission() {
			if (this.user) {
				const permissions = localStorage.getItem("permissions");

				if (permissions === null) {
					try {
						const response = await meService.me();
						localStorage.setItem(
							"permissions",
							JSON.stringify(response.permissions)
						);
						this.menu = response.permissions.menus;
						this.modules = response.permissions.modules;
						this.module = this.modules.length > 0 ? this.modules[0].CODMOD : 0;
					} catch (error) {
						// eslint-disable-next-line no-console
						console.error("Erro ao buscar dados do usuário:", error);
					}
				} else {
					const parse = JSON.parse(permissions);

					this.menu = parse.menus;
					this.modules = parse.modules;
					this.module = this.modules.length > 0 ? this.modules[0].CODMOD : 0;
				}

				await this.getCovers();
				await this.loadRoute();
			}
		},
		closeDialogFilters() {
			bus.$emit("open-modal-select-pe", false);
		},
		openDialogFilters() {
			bus.$emit("open-modal-select-pe", true);
		},
	},
};
</script>

<style>
.menuActivator {
	font-size: 16px;
	text-decoration: none;
	padding: 5px;
	color: black !important;
	font-weight: bold;
	cursor: pointer;
	margin-right: 30px;
	font-family: "Roboto", sans-serif;
}
.active {
	background: #eee !important;
	border-radius: 6px;
	padding: 4px 22px;
}
</style>
