import Rest from '../Base/Rest';

/**
 * @typedef {FaqService}
 */
export default class FaqService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/faq';
}
