import Rest from '../../app/Base/Rest';

/**
 * @typedef {AnalyzeRegistrationService}
 */
export default class AnalyzeRegistrationService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/analyzeregistration';
}
