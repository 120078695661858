import Rest from '../../app/Base/Rest';

/**
 * @typedef {AreaService}
 */
export default class AreaService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/area';
}
