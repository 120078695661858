import Rest from "../Base/Rest";

/**
 * @typedef {ProcessService}
 */
export default class ProcessService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = "/process";
}
