import Rest from '../Base/Rest';

/**
 * @typedef {PublicService}
 */
export default class PublicService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/public';

	coverImages() {
		return this.get('cover/images')
	}

	companysLogo() {
		return this.get('company/logos');
	}

	faqList() {
		return this.get('faq');
	}
}
