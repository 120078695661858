import Rest from "../Base/Rest";

/**
 * @typedef {OrganizationChartEmployeeService}
 */
export default class OrganizationChartEmployeeService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = "/organization-chart-employee";
}
