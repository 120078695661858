import Rest from '../Base/Rest';

/**
 * @typedef {CorporateAreaService}
 */
export default class CorporateAreaService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/corporatearea';
}
