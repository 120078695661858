import Rest from "../../app/Base/Rest";

/**
 * @typedef {PlanActionService}
 */
export default class PlanActionService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = "/planaction";

	loadUsers(params) {
		const queryString = this.queryString(params);

		return this.get(`users?${queryString}`);		
	}
}
