<template>
	<v-container>
		<v-row>
			<v-col cols="12" md="12">
				<v-card flat :loading="loading">
					<v-card-title class="card-title">
						<v-icon class="card-title-icon" color="black">mdi-home</v-icon>
						<label class="pl-4 card-title-label">Início Empresa</label>
					</v-card-title>

					<v-divider></v-divider>

					<v-card-text>
						<v-col class="pb-5" cols="12" md="12">
							<v-row>
								<v-tabs v-model="tab" grow>
									<v-tabs-slider color="red"></v-tabs-slider>

									<v-tab
										><v-icon left>mdi-information</v-icon> Informações</v-tab
									>
									<v-tab><v-icon left>mdi-key</v-icon> Gestão das chaves</v-tab>
									<v-tab
										><v-icon left>mdi-account-settings</v-icon>
										Configurações</v-tab
									>
								</v-tabs>

								<v-tabs-items v-model="tab" style="width: 100%">
									<v-tab-item>
										<v-container>
											<v-row>
												<v-col cols="12" md="12">
													<v-row>
														<v-col cols="12" md="2">
															<PictureUploader
																v-model="user.FOTUSU"
																@input="save"
																customEmit="profilePictureUpdated"
															/>
														</v-col>
														<v-col cols="12" md="10">
															<v-row class="pa-4">
																<v-col cols="12" md="8">
																	<v-text-field
																		label="Nome"
																		v-model="user.NOMUSU"
																	></v-text-field>
																</v-col>
																<v-col cols="12" md="8">
																	<v-text-field
																		label="E-mail"
																		v-model="user.EMAUSU"
																	></v-text-field>
																</v-col>
																<v-col
																	cols="12"
																	md="8"
																	v-if="user.TIPUSU === 'E'"
																>
																	<v-text-field
																		label="Chave de usuário"
																		readonly
																		v-model="user.USRKEY"
																	></v-text-field>
																</v-col>
																<v-col cols="12" md="8">
																	<v-text-field
																		label="Senha"
																		v-model="user.newPassword"
																		type="password"
																	></v-text-field>
																</v-col>
															</v-row>
														</v-col>
													</v-row>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="12" md="12" style="text-align: center">
													<v-btn
														class="white--text mr-4"
														color="primary"
														:loading="isLoading"
														:disabled="loading"
														depressed
														@click="save"
														>Atualizar dados</v-btn
													>
												</v-col>
											</v-row>
										</v-container>
									</v-tab-item>

									<v-tab-item>
										<v-data-table
											:headers="headers"
											:items="items"
											:items-per-page="pagination.itemsPerPage"
											:loading="loadingKeys"
											:hide-default-footer="true"
											:page.sync="pagination.page"
										>
											<template v-slot:body="{ items }">
												<tr v-for="item in items" :key="item.CODKEY">
													<td>
														{{ item.CODUSU }}
													</td>
													<td>
														<v-btn
															v-if="item.STATUS === 'A'"
															@click="editPermission(item.requesteruser.CODUSU)"
															:disabled="loading"
															icon
														>
															<v-icon>mdi-pencil</v-icon>
														</v-btn>
														<v-btn
															v-if="item.STATUS === 'P'"
															@click="aproveKey(item.CODKEY)"
															:disabled="loading"
															icon
														>
															<v-icon style="color: green"
																>mdi-check-circle</v-icon
															>
														</v-btn>
													</td>
													<td>
														<v-chip
															v-if="item.STATUS === 'P'"
															class="ma-2"
															small
															color="warning"
															>Pendente</v-chip
														>
														<v-chip
															v-if="item.STATUS === 'A'"
															class="ma-2"
															small
															color="success"
															>Aprovada</v-chip
														>
														<v-chip
															v-if="item.STATUS === 'R'"
															class="ma-2"
															small
															color="danger"
															>Reprovada</v-chip
														>
													</td>
													<td>{{ item.USRKEY }}</td>
													<td>{{ item.requesteruser.EMAUSU }}</td>
													<td>{{ item.requesteruser.NOMUSU }}</td>

													<td>{{ getGroupByUser(item) }}</td>
													<td>{{ getCompanyByUser(item) }}</td>
													<td>{{ getUnitByUser(item) }}</td>

													<td class="text-center">
														<v-btn
															@click="removeKey(item.CODKEY)"
															:disabled="loading"
															icon
														>
															<v-icon style="color: red">mdi-delete</v-icon>
														</v-btn>
													</td>
												</tr>
											</template>
										</v-data-table>

										<pagination
											v-model="pagination"
											:loading="loadingKeys"
											@change="loadData"
										/>
									</v-tab-item>

									<v-tab-item>
										<v-container>
											<v-row>
												<v-col cols="12" md="12" style="text-align: center">
													<v-divider></v-divider>
													<v-col cols="12" md="4">
														<v-select
															v-model="user.DAY_NOTICE_TO_INDICATOR"
															:items="getDaysMonth()"
															item-text="Dia do mês por notificação em atraso"
															label="Dia do mês por notificação em atraso"
															class=""
														></v-select>
													</v-col>
													<v-dialog
														v-model="deleteDialog"
														persistent
														max-width="600"
													>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																color="red"
																outlined
																dark
																v-bind="attrs"
																v-on="on"
																:disabled="loading"
																>Excluir cadastro</v-btn
															>
														</template>
														<v-card>
															<v-card-title class="headline"
																>Excluir cadastro</v-card-title
															>
															<v-divider></v-divider>
															<v-card-text>
																<v-row>
																	<v-col cols="12" md="12"
																		>Deseja realmente excluir seu cadastro? Seus
																		dados serão excluidos definitivamente</v-col
																	>
																</v-row>
															</v-card-text>
															<v-card-actions>
																<v-spacer></v-spacer>
																<v-btn
																	style="color: white"
																	color="primary"
																	@click="deleteRegistration"
																	>Sim, concordo em excluir meu cadastro</v-btn
																>
																<v-btn
																	color="red darken-1"
																	text
																	@click="deleteDialog = false"
																	>Cancelar</v-btn
																>
															</v-card-actions>
														</v-card>
													</v-dialog>
												</v-col>
											</v-row>
										</v-container>
									</v-tab-item>
								</v-tabs-items>
							</v-row>
						</v-col>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import UserService from "../../app/Services/UserService";
import UserKeyService from "../../app/Services/UserKeyService";
import PictureUploader from "../picture-uploader/PictureUploader";
import pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import SortableMixin from "@/components/pagination/sort/SortableMixin";

const userService = UserService.build();
const userKeyService = UserKeyService.build();

export default {
	name: "CompanyHome",
	components: {
		PictureUploader: PictureUploader,
		pagination,
	},
	mixins: [PaginationMixin, SortableMixin],
	mounted() {
		const localStorageData = JSON.parse(localStorage.getItem("user"));
		this.id = localStorageData.user.CODUSU;

		this.fetchData();
	},
	data: () => ({
		tab: null,
		id: null,
		deleteDialog: false,
		isLoading: false,
		isLoadingKey: false,
		loading: false,
		loadingKeys: false,
		user: {},
		userKey: {
			USRKEY: "",
			STATUS: "P",
		},
		headers: [
			{
				text: "Id",
				align: "center",
				sortable: false,
				value: "id",
				width: "5%",
			},
			{
				text: "Status",
				align: "left",
				sortable: false,
				value: "status",
				width: "5%",
			},
			{
				text: "Status",
				align: "left",
				sortable: false,
				value: "status",
				width: "10%",
			},
			{
				text: "Chave",
				align: "left",
				sortable: false,
				value: "status",
				width: "15%",
			},
			{
				text: "Email",
				align: "left",
				sortable: false,
				value: "status",
				width: "30%",
			},
			{
				text: "Solicitante",
				align: "left",
				sortable: false,
				value: "status",
				width: "30%",
			},
			{
				text: "Grupo",
				align: "center",
				sortable: false,
				value: "status",
			},
			{
				text: "Empresa",
				align: "center",
				sortable: false,
				value: "status",
			},
			{
				text: "Unidade",
				align: "center",
				sortable: false,
				value: "status",
			},
			{
				text: "",
				align: "center",
				sortable: false,
				value: "status",
				width: "10%",
			},
		],
		allItems: [],
		items: [],
		pagination: {
			page: 1,
			itemsPerPage: 100,
			pageCount: 0,
		},
	}),
	computed: {
		paginatedItems() {
			const start = (this.pagination.page - 1) * this.pagination.itemsPerPage;
			return this.items.slice(start, start + this.pagination.itemsPerPage);
		},
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		deleteRegistration() {
			return userService.deleteRegistration().then(() => {
				this.$toasted.success("Cadastro excluído com sucesso!", {
					icon: "mdi-check",
				});

				this.$router.push({ path: "/logoff" });
			});
		},
		save() {
			this.isLoading = true;

			userService
				.updateData(this.user)
				.then(() => {
					this.isLoading = false;

					this.$toasted.success("Usuário atualizado com sucesso!", {
						icon: "mdi-check",
					});

					if (this.user.newPassword) {
						this.$router.push({ path: "/logoff" });
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		getUserData() {
			return userService
				.read({
					id: this.id,
				})
				.then((response) => {
					this.user = response;

					this.user.newPassword = "";
				});
		},
		getMyKeys() {
			this.loadingKeys = true;
			let filter = {};

			filter.admkey = this.user.USRKEY;

			return userKeyService.search(filter).then((response) => {
				this.allItems = response;
				this.paginateItems();
				this.loadingKeys = false;
			});
		},
		loadData(page) {
			this.pagination.page = page;
			this.paginateItems();
		},
		paginateItems() {
			const start = (this.pagination.page - 1) * this.pagination.itemsPerPage;
			const end = start + this.pagination.itemsPerPage;
			this.items = this.allItems.slice(start, end); // Filtra os itens a serem exibidos

			// Atualiza o total de páginas
			this.pagination.pageCount = Math.ceil(
				this.allItems.length / this.pagination.itemsPerPage
			);
		},
		fetchData() {
			this.loading = true;

			Promise.all([this.getUserData()])
				.then(() => {})
				.finally(() => {
					this.getMyKeys();
					this.loading = false;
				});
		},
		updatePagination(pagination) {
			this.pagination.page = pagination.page;
		},
		aproveKey(id) {
			this.loading = true;
			const status = {
				STATUS: "A",
			};

			userKeyService
				.update({
					id: id,
					...status,
				})
				.then(() => {
					this.toast("success", "Chave aprovada com sucesso!");

					this.loading = false;
					this.getMyKeys();
				});
		},
		removeKey(id) {
			this.loading = true;

			userKeyService
				.destroy({
					id: id,
				})
				.then(() => {
					this.toast("success", "Chave removida com sucesso!");

					this.loading = false;
					this.getMyKeys();
				});
		},
		editPermission(id) {
			this.$router.push({
				name: "UserPermission",
				params: {
					id: id,
				},
			});
		},
		getDaysMonth() {
			let vdays = [];
			for (let vday = 1; vday <= 31; vday++) {
				vdays.push(vday);
			}
			return vdays;
		},
		getGroupByUser(item) {
			let data = [];

			if (item.arrgergrpemp) {
				for (const gergrpemp of item.arrgergrpemp) {
					data.push(gergrpemp.CODGRP);
				}

				let unique = [...new Set(data)];

				return unique.toString();
			}

			return "";
		},
		getCompanyByUser(item) {
			let data = [];

			if (item.arrgerusucom) {
				for (const gerusucom of item.arrgerusucom) {
					data.push(gerusucom.CODEMP);
				}

				let unique = [...new Set(data)];

				return unique.toString();
			}

			return "";
		},
		getUnitByUser(item) {
			let data = [];

			if (item.arrgerusuuni) {
				for (const unit of item.arrgerusuuni) {
					data.push(unit.CODUNI);
				}

				let unique = [...new Set(data)];

				return unique.reverse().toString();
			}

			return "";
		},
	},
};
</script>
